import { FontSizes, FontWeights, IDetailsListStyles, ILabelStyles, ISearchBoxStyles, getTheme, mergeStyles } from "@fluentui/react"

const theme = getTheme();

export const headingStyle: Partial<ILabelStyles> = {
    root: {
        fontWeight: FontWeights.semibold,
        fontSize: FontSizes.size28,
    }
}

export const tableContainerStyle = mergeStyles({
    height: "calc(100vh - 50px - 52px - 42px - 30px)",
    position: "relative",
})

export const commandBar = mergeStyles({
    marginLeft: 27,
    marginRight: 0,
})

export const viewListStyle: Partial<IDetailsListStyles> = {
    root: {
        margin: "0 0 0 0",
        padding: "0px",
        MsOverflowStyle: "-ms-autohiding-scrollbar",
        height: "calc(100vh - 275px)"
    },
    headerWrapper: {
        paddingTop: 0
    }
};

export const viewListScrollStyle = mergeStyles({
    position: "relative",
    height: "calc(100vh - 275px)",
})

export const datasourceGridStyle = mergeStyles({
    position: "relative",
    height: "calc(100vh - 200px)",
})

export const datasourceGridStyleCreate = (items: number) => {
    const baseCreate = 395;
    const itemHeight = Math.max((76 * (items)), 0); //itme height + padding
    const height = baseCreate + itemHeight;
    return mergeStyles({
        position: "relative",
        height: `calc(100vh - ${height}px)`,
    })
}

export const documentLibraryGridScrollStyle = (hasError: boolean) => {
    return mergeStyles({
    position: "relative",
    height: `calc(100vh - ${hasError ?"435" : "400"}px)`, //-400px without error
})}

export const addListStyle: Partial<IDetailsListStyles> = {
    root: {
        margin: "0 0 0 0",
        padding: "0px",
        MsOverflowStyle: "-ms-autohiding-scrollbar",
        height: "calc(100vh - 200px)"
    },
    headerWrapper: {
        paddingTop: 0
    },
};

export const addListScrollStyle = mergeStyles({
    position: "relative",
    height: "calc(100vh - 200px)",
})

export const searchBoxStyles: Partial<ISearchBoxStyles> = {
    root: {
        width: "100%",
        borderColor: theme.palette.themeDarkAlt,
        marginTop: 10,
        marginLeft: 0,
        marginBottom: 15
    }
};

export const pageContainer = mergeStyles({
    height: "calc(100vh - 50px)",
    width: "calc(100vw - 208px - 1px - 80px)"
})