
import React from 'react';
import {
    DocumentCard,
    mergeStyles,
    mergeStyleSets,
    Label,
    FontSizes,
    FontWeights,
    Stack,
    getTheme,
    PrimaryButton,
    DefaultButton,
} from "@fluentui/react"
import { IStyledRequest } from '../data-structures/interfaces';
import { LanguageContext } from '../utilities/LocalizationModule';
import { RootState } from '../redux';
import { connect, ConnectedProps } from 'react-redux';

const theme = getTheme();

// const mapDispatchToProps = {
// }

const mapStateToProps = (state: RootState) => {
    return {
        tenants: state.user.tenants,
        partners: state.user.users.filter(u => u.isPartner),
    };
};

const connector = connect(mapStateToProps, null);
type PropsFromRedux = ConnectedProps<typeof connector>;
interface RequestCardProps extends PropsFromRedux {
    request: IStyledRequest,
    openDialog: () => void,
    acceptCallback: () => void,
};

const RequestCard: React.FC<RequestCardProps> = ({
    request,
    partners,
    openDialog,
    acceptCallback
}: RequestCardProps) => {
    const languageStrings = React.useContext(LanguageContext);
    //const [requestingPartner, _] = React.useState(partners.find(p => p.id == request.partner.id))
    const styles = mergeStyleSets({
        headerContainerStyle: {
            backgroundColor: request.color,
            height: 60,
            cursor: "pointer",
        },
        headerLabel: {
            fontSize: FontSizes.xLargePlus,
            fontWeight: FontWeights.semibold,
            color: request.textColor,
            marginLeft: 16,
            cursor: "pointer",
        },
        secondaryLabel: {
            fontSize: FontSizes.mediumPlus,
            fontWeight: FontWeights.regular,
            color: theme.palette.neutralDark,
            marginLeft: 16,
            cursor: "pointer",
        },
        infoLabel: {
            fontSize: FontSizes.medium,
            fontWeight: FontWeights.semilight,
            color: theme.palette.neutralPrimary,
            marginLeft: 16,
            cursor: "pointer",
        },
        guid: {
            fontSize: 14,
            fontWeight: FontWeights.light,
            color: theme.palette.neutralTertiary,
            marginLeft: 16,
            cursor: "pointer",
        },
        cardContainer: {
            display: 'inline-block',
            width: 320,
            cursor: "pointer",
        },
    });
    const conversationTileClass = mergeStyles({ height: 190 });

    return (
        <DocumentCard
            className={styles.cardContainer}
        >
            <Stack className={styles.headerContainerStyle} verticalAlign="center">
                <Label className={styles.headerLabel}>{request.tenant.friendlyName}</Label>
            </Stack>
            <Stack className={conversationTileClass}>
                <Label className={styles.secondaryLabel}>Tenant Details</Label>
                <Label className={styles.guid}>{`ID: ${request.tenant.tenantId}`}</Label>
                <Label className={styles.infoLabel}>{languageStrings.RequestingPartner}: {request.partner.name}</Label>
                <Label className={styles.infoLabel}>{languageStrings.ApprovalStatus}: {request.isApproved == null ? languageStrings.Pending : request.isApproved == false ? languageStrings.Rejected : languageStrings.Approved}</Label>
                <Label className={styles.infoLabel}>{languageStrings.RequestDate}: {new Date(request.requestDate).toLocaleDateString()}</Label>
            </Stack>
            <Stack horizontal horizontalAlign="end" styles={{ root: { marginBottom: 15, marginRight: 15 } }} tokens={{ childrenGap: 15 }}>
                <PrimaryButton
                    iconProps={{ iconName: "CheckMark", styles: { root: { marginLeft: 0 } } }}
                    styles={{ root: { width: 90 } }}
                    onClick={() => { acceptCallback() }}
                >
                    Accept
                </PrimaryButton>
                <DefaultButton
                    iconProps={{ iconName: "Cancel", styles: { root: { marginLeft: 0 } } }}
                    styles={{ root: { width: 90 } }}
                    onClick={() => { openDialog() }}
                >
                    Reject
                </DefaultButton>
            </Stack>
        </DocumentCard>
    )
}

export default connector(RequestCard);