import React, { useRef } from 'react';
import { LanguageContext } from '../../utilities/LocalizationModule';
import { Panel, Stack, DefaultButton, Label, PanelType, TextField, PrimaryButton, Spinner, SpinnerSize, Dropdown, IDropdownOption, Icon, StackItem, Callout, FocusTrapZone, Calendar, DayOfWeek, DirectionalHint, Checkbox } from "@fluentui/react"
import { connect, ConnectedProps } from 'react-redux';
import { setLoading } from "../../redux/modules/user"
import { RootState } from '../../redux';
import { createSubscription, getSubscriptionPriceTiers } from '../../utilities/helpers/ApiHelper';
import { CreateSubscriptionRequest, ITenant, SubscriptionPriceTierResponse } from '../../data-structures/interfaces';
import MollieCard from '../MollieCard';
import { SubscriptionIntervalEnum, SubscriptionTierEnum } from '../../data-structures/enums';
import { labelHeadingStyle, labelInfoStyle } from '../../styles/PanelStyle';
import { DayPickerStrings } from '../../utilities/DayPickerStrings';

const mapStateToProps = (state: RootState) => {
    return {
        account: state.user.account,
        isGlobalAdmin: state.user.isGlobalAdmin
    }
}

const mapDispatchToProps = {
    setLoading
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface AddSubscriptionPanelProps extends PropsFromRedux {
    isOpen: boolean,
    paymentMethods: any[];
    closePanel: () => void;
    tenant: ITenant;
    onSuccess: () => void;
};

const AddSubscriptionPanel: React.FC<AddSubscriptionPanelProps> = ({
    isOpen,
    closePanel,
    account,
    paymentMethods,
    tenant,
    onSuccess,
    isGlobalAdmin
}: AddSubscriptionPanelProps) => {
    const languageStrings = React.useContext(LanguageContext);

    const minLicensesInvoice = "25";

    const [paymentMethodOptions, setPaymentMethodOptions] = React.useState<IDropdownOption[]>([]);
    const [selectedMethod, setSelectedMethod] = React.useState<IDropdownOption>();
    const [selectedSubscription, setSelectedSubscription] = React.useState<IDropdownOption>();

    const [subscriptionOptions, setSubscriptionOptions] = React.useState<IDropdownOption[]>([]);
    const [subscriptionTierOptions, setSubscriptionTierOptions] = React.useState<IDropdownOption[]>([
        { key: SubscriptionTierEnum.Paid, text: SubscriptionTierEnum.Paid }, { key: SubscriptionTierEnum.Trial, text: SubscriptionTierEnum.Trial }
    ]);
    const [selectedSubscriptionTier, setSelectedSubscriptionTier] = React.useState<IDropdownOption>();

    const [showCalendar, setShowCalendar] = React.useState<boolean>(false);
    const [selectedDate, setSelectedDate] = React.useState<Date>(null);

    const [isCreatingPayment, setIsCreatingPayment] = React.useState(false);

    const [subscriptionPriceTiers, setSubscriptionPriceTiers] = React.useState<SubscriptionPriceTierResponse[]>([]);

    const [licenceCount, setLicenceCount] = React.useState<number>(1);
    const [invoiceTermsAccepted, setInvoiceTermsAccepted] = React.useState<boolean>(false);

    const calendarButtonElement = useRef(null);

    const [priceToPay, setPriceToPay] = React.useState("");

    React.useEffect(() => {
        (async () => {
            const tiers = await getSubscriptionPriceTiers();
            if (tiers?.length > 0) {
                setSubscriptionPriceTiers(tiers);
                setSubscriptionOptions([
                    { key: 2, text: languageStrings.PricePerLicenseYear.replace("{price}", tiers[0].licensePrice.toFixed(2)), },
                    { key: 1, text: languageStrings.PricePerLicenseMonth.replace("{price}", tiers[0].licensePrice.toFixed(2)) }
                ])
            }
        })()
    }, []);

    React.useEffect(() => {
        if (subscriptionOptions?.length > 0) {
            let tier = subscriptionPriceTiers.find(x => x.minUsers <= licenceCount && x.maxUsers >= licenceCount);
            if (!tier) {
                tier = subscriptionPriceTiers[0]
            }

            const monthlyMultiplier = 1.1;
            const monthlyPrice = licenceCount * tier.licensePrice * monthlyMultiplier;
            const annualPrice = licenceCount * tier.licensePrice * 12;

            // Determine the selected subscription interval.
            const selectedInterval = selectedSubscription ? selectedSubscription.key : undefined;

            // Set the total price to be displayed on the button.
            const price = selectedInterval === SubscriptionIntervalEnum.Monthly ? monthlyPrice : annualPrice;
            setPriceToPay(price.toFixed(2));

            // Update the dropdown options with the updated prices.
            setSubscriptionOptions([
                { key: 2, text: languageStrings.PricePerLicenseYear.replace("{price}", tier.licensePrice.toFixed(2)), },
                { key: 1, text: languageStrings.PricePerLicenseMonth.replace("{price}", (tier.licensePrice * monthlyMultiplier).toFixed(2)) }
            ]);
        }
    }, [licenceCount, subscriptionPriceTiers, selectedSubscription]);

    React.useEffect(() => {
        (async () => {
            const methods: IDropdownOption[] = paymentMethods.map(method => ({
                key: method.id,
                text: method.description,
                data: method,
                image: { src: method.image.size1x },
            }))

            methods.push({
                key: "invoice_static",
                text: languageStrings.InvoiceStatic
            });

            methods.push({
                key: "invoice_dynamic",
                text: languageStrings.InvoiceDynamic
            });

            setPaymentMethodOptions(methods);
        })();
    }, [paymentMethods]);

    React.useEffect(() => {
        if (subscriptionTierOptions?.length > 0) {
            setSelectedSubscriptionTier(subscriptionTierOptions[0])
        }
    }, [subscriptionTierOptions]);

    const onMethodChange = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
        setSelectedMethod(item);
    };

    const onSubscriptionChange = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
        setSelectedSubscription(item);
    };

    const onSubscriptionTierChange = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
        setSelectedSubscriptionTier(item);
    };

    const isPayButtonDisabled = () => {
        if (isCreatingPayment) {
            return true;
        }

        if (selectedSubscriptionTier?.key === SubscriptionTierEnum.Trial && selectedDate) {
            return false;
        }

        if (selectedMethod?.key === "invoice_dynamic" && !invoiceTermsAccepted) {
            return true;
        }

        if (selectedMethod && selectedSubscription) {
            return false;
        }

        return true;
    }

    const onSelectDate = (date: Date): void => {
        setShowCalendar(!showCalendar);
        setSelectedDate(date);
    };

    const getButtonText = () => {
        if (selectedMethod?.key === "invoice_dynamic") {
            return languageStrings.AddSubscription;
        }

        if (selectedSubscriptionTier?.key === SubscriptionTierEnum.Trial) {
            return languageStrings.AddSubscription;
        }

        if (priceToPay) {
            return `${languageStrings.AddSubscription} (€${priceToPay})`;
        }

        return languageStrings.AddSubscription;
    }

    return (
        <Panel
            headerText={languageStrings.AddSubscription}
            isOpen={isOpen}
            isLightDismiss
            onDismiss={() => {
                closePanel();
            }}
            closeButtonAriaLabel="Close"
            type={PanelType.custom}
            customWidth={"500px"}
        >
            <Stack tokens={{ childrenGap: 20 }} style={{ marginTop: 20 }}>
                <Stack.Item>
                    <Label styles={labelHeadingStyle}>{languageStrings.Tenant}</Label>
                    <Label styles={labelInfoStyle} disabled>
                        {tenant?.friendlyName}
                    </Label>
                    <Label styles={labelInfoStyle} disabled>
                        {tenant?.tenantId}
                    </Label>
                </Stack.Item>
                {isGlobalAdmin && <>
                    <Dropdown
                        label={languageStrings.SubscriptionTier}
                        options={subscriptionTierOptions}
                        onChange={onSubscriptionTierChange}
                        defaultSelectedKey={SubscriptionTierEnum.Paid}
                    />
                    {selectedSubscriptionTier?.key === SubscriptionTierEnum.Trial && <Stack.Item style={{ paddingTop: 5 }}>
                        <Label styles={labelHeadingStyle}>{languageStrings.LicenseExpiryDate}</Label>
                        <div ref={calendarButtonElement}>
                            <DefaultButton
                                onClick={() => {
                                    setShowCalendar(true)
                                    setSelectedDate(new Date());
                                }}
                                text={!selectedDate ? languageStrings.ClickForCalendar : new Date(selectedDate).toDateString()}
                            />
                        </div>
                        {showCalendar && (
                            <Callout
                                isBeakVisible={false}
                                className="ms-DatePicker-callout"
                                gapSpace={0}
                                doNotLayer={false}
                                target={calendarButtonElement}
                                directionalHint={DirectionalHint.rightCenter}
                                onDismiss={() => setShowCalendar(false)}
                                setInitialFocus
                            >
                                <FocusTrapZone firstFocusableSelector="ms-DatePicker-day--today" isClickableOutsideFocusTrap>
                                    <Calendar
                                        // eslint-disable-next-line react/jsx-no-bind
                                        onSelectDate={onSelectDate}
                                        onDismiss={() => setShowCalendar(false)}
                                        isMonthPickerVisible={true}
                                        value={new Date(selectedDate)!}
                                        firstDayOfWeek={DayOfWeek.Sunday}
                                        strings={DayPickerStrings}
                                        isDayPickerVisible={true}
                                        highlightCurrentMonth={true}
                                        highlightSelectedMonth={true}
                                        showMonthPickerAsOverlay={true}
                                        showGoToToday={true}
                                    />
                                </FocusTrapZone>
                            </Callout>
                        )}
                    </Stack.Item >}
                </>}

                {selectedSubscriptionTier?.key === SubscriptionTierEnum.Paid && <>
                    <Dropdown
                        placeholder={languageStrings.SelectSubscription}
                        label={languageStrings.SubscriptionType}
                        options={subscriptionOptions}
                        onChange={onSubscriptionChange}
                    />
                    {selectedSubscription && <Dropdown
                        placeholder={languageStrings.SelectPaymentMethod}
                        options={paymentMethodOptions}
                        onChange={onMethodChange}
                        label={languageStrings.PaymentMethod}
                        onRenderOption={(option) => (
                            <>
                                {option.data ?
                                    <Stack horizontal tokens={{ childrenGap: 10 }} verticalAlign='center'>
                                        <img src={option.data.image.size1x} alt={option.data.description} style={{ width: '20px', marginRight: '10px' }} />
                                        {option.data.description}
                                    </Stack> :
                                    <Stack horizontal tokens={{ childrenGap: 12 }} verticalAlign='center'>
                                        <StackItem style={{ paddingLeft: 4 }}>
                                            <Icon iconName='M365InvoicingLogo' />
                                        </StackItem>
                                        <StackItem>
                                            {option.text}
                                        </StackItem>
                                    </Stack>}

                            </>

                        )}
                        onRenderTitle={(selectedOptions) => {
                            const selectedOption = selectedOptions[0];
                            setSelectedMethod(selectedOption);
                            return (
                                <>
                                    {!(selectedOption.key as string).includes("invoice") ?
                                        <Stack horizontal tokens={{ childrenGap: 10 }} verticalAlign="center">
                                            <img src={selectedOption.data.image.size1x} alt={selectedOption.data.description} style={{ width: '20px', marginRight: '10px' }} />
                                            {selectedOption.data.description}
                                        </Stack> :
                                        <Stack horizontal tokens={{ childrenGap: 12 }} verticalAlign='center'>
                                            <StackItem style={{ paddingLeft: 4 }}>
                                                <Icon iconName='M365InvoicingLogo' />
                                            </StackItem>
                                            <StackItem>
                                                {selectedOption.text}
                                            </StackItem>
                                        </Stack>
                                    }
                                </>

                            );
                        }}
                    />}
                </>}


                {selectedSubscription && selectedMethod && selectedMethod.key !== "invoice_dynamic" && selectedSubscriptionTier?.key === SubscriptionTierEnum.Paid && <TextField
                    label={languageStrings.LicenseCount}
                    type='number'
                    value={licenceCount.toString()}
                    onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                        let newLicenceCount = +newValue;
                        if (newLicenceCount <= 0) {
                            newLicenceCount = 1
                        }
                        setLicenceCount(newLicenceCount);
                    }}
                />}
                {selectedMethod?.key === "creditcard" && selectedSubscriptionTier?.key === SubscriptionTierEnum.Paid && <MollieCard priceToPay={priceToPay} lisenceCount={licenceCount} interval={selectedSubscription?.key as number} tenantId={tenant.id} />}

                {selectedMethod?.key === "invoice_dynamic" && selectedSubscriptionTier?.key === SubscriptionTierEnum.Paid && <Checkbox label={languageStrings.MinimumLicenseCharge.replace("{amount}", minLicensesInvoice)} checked={invoiceTermsAccepted} onChange={() => setInvoiceTermsAccepted(prev => !prev)} />}

                {(selectedMethod?.key !== "creditcard" || selectedSubscriptionTier?.key === SubscriptionTierEnum.Trial) &&
                    <PrimaryButton
                        disabled={isPayButtonDisabled()}
                        text={getButtonText()}
                        onClick={async () => {
                            setIsCreatingPayment(true);

                            const request: CreateSubscriptionRequest = {
                                tenantId: tenant.id,
                                accountEmail: account.username,
                                method: selectedMethod?.key as string,
                                interval: selectedSubscriptionTier.key === SubscriptionTierEnum.Trial ? SubscriptionIntervalEnum.OnceOff : selectedSubscription.key as number,
                                lisenceCount: licenceCount,
                                expiryDate: selectedDate,
                                tier: selectedSubscriptionTier.key as string
                            }

                            const paymentResult = await createSubscription(request);
                            setIsCreatingPayment(false);

                            if (paymentResult) {
                                window.location.href = paymentResult;
                            } else {
                                onSuccess();
                                closePanel();
                            }
                        }} >
                        {isCreatingPayment && <Spinner size={SpinnerSize.small} style={{ paddingRight: 15 }} />}
                    </PrimaryButton>}
            </Stack>

        </Panel>
    )
}

export default connector(AddSubscriptionPanel);