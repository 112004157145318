import React from 'react';
import {
    Callout,
    Icon,
    FontWeights,
    mergeStyleSets,
    Text,
    CommandBarButton,
    Tooltip,
    TooltipHost,
    ITooltipProps,
    DirectionalHint,
    TooltipDelay,
    ITooltipHostStyles,
} from "@fluentui/react"
import { LanguageContext } from '../utilities/LocalizationModule';

interface InfoBoxProps {
    title: string,
    content: string,
};

const InfoBox: React.FC<InfoBoxProps> = ({
    title,
    content,
}: InfoBoxProps) => {
    const languageStrings = React.useContext(LanguageContext);

    const hostStyles: Partial<ITooltipHostStyles> = { root: { display: 'inline-block' } };

    const tooltipProps: ITooltipProps = React.useMemo(() => {

        const strings = content.split("\n");
        return {
            onRenderContent: () => (
                <><Text block variant="xLarge" className={infoboxStyles.title}>
                    {title}
                </Text>
                    {strings.map((str, index) => {
                        return (
                            <Text block variant="small" key={`infobox-${index}`}>
                                {str}
                            </Text>
                        )
                    })}
                </>
            ),
        };
    }, []);
    return (
        <TooltipHost
            tooltipProps={tooltipProps}
            delay={TooltipDelay.zero}
            id={`tooltipInfoBox-${title}`}
            directionalHint={DirectionalHint.bottomCenter}
            styles={hostStyles}
        >
            <CommandBarButton
                iconProps={{ iconName: "info", styles: { root: { fontSize: 20 } } }}
                id={`infoIcon-${title}`}
                className={infoboxStyles.icon}
            />
        </TooltipHost>

    )
}

const infoboxStyles = mergeStyleSets({
    icon: {
        color: 'rgb(50,49,48)',
        paddingTop: 5,
        paddingBottom: 5,
    },
    callout: {
        width: 500,
        padding: '24px 28px',
    },
    title: {
        marginBottom: 12,
        fontWeight: FontWeights.semilight,
    },
})

export default InfoBox;