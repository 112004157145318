import { LicenseType, SubscriptionStatusEnum, SubscriptionTierEnum } from "../../data-structures/enums";
import { ITenantUser, MollieSubscription } from "../../data-structures/interfaces";

export const getUserLicenseType = (subscription: MollieSubscription) => {
    if (!subscription) {
        return LicenseType.Unassigned
    }

    if (subscription.status === SubscriptionStatusEnum.Canceled || subscription.status === SubscriptionStatusEnum.Suspended || subscription.status === SubscriptionStatusEnum.Completed) {
        return LicenseType.Inactive
    }

    if (subscription.expiryDate && new Date(subscription.expiryDate) < new Date()) {
        return LicenseType.Expired
    }

    return subscription.tier === SubscriptionTierEnum.Trial ? LicenseType.Eval : LicenseType.Paid
}