import { FontSizes, FontWeights, IButtonStyles, IDropdownStyles, IIconStyles, ILabelStyles, mergeStyles } from "@fluentui/react";

export const dropdownStyles: Partial<IDropdownStyles> = {
    label: {
        fontWeight: FontWeights.semibold,
        fontSize: FontSizes.mediumPlus,
    }
};

export const labelHeadingStyle: Partial<ILabelStyles> = {
    root: {
        fontWeight: FontWeights.semibold,
        fontSize: FontSizes.mediumPlus,
    }
}

export const tooltipHeadingStyle: Partial<ILabelStyles> = {
    root: {
        fontWeight: FontWeights.semibold,
        fontSize: FontSizes.medium,
    }
}

export const tooltipTextStyle: Partial<ILabelStyles> = {
    root: {
        fontWeight: FontWeights.regular,
        fontSize: FontSizes.small,
    }
}

export const labelInfoStyle: Partial<ILabelStyles> = {
    root: {
        fontWeight: FontWeights.regular,
        fontSize: FontSizes.medium,
        marginTop: 0
    }
}

export const profileTitle: Partial<ILabelStyles> = {
    root: {
        fontWeight: FontWeights.semibold,
        fontSize: FontSizes.large,
        marginLeft: 12
    }
}

export const panelCommandButtonStyle : Partial<IIconStyles> = {
    root: { 
        color: 'rgb(50,49,48)',
        paddingTop: 5,
        paddingBottom: 5,
    }
}

export const panelActionButtonStyle : Partial<IButtonStyles> = {
    root: {
        marginTop: 20,
    }
}

export const errorLabelStyle = mergeStyles({
    fontSize: FontSizes.medium,
    fontWeight: FontWeights.regular,
    color: "#a80000",
    backgroundColor: "#fde7e9", //https://developer.microsoft.com/en-us/fluentui#/styles/web/colors/messaging
    marginTop: 5,
    paddingLeft: 15,
    paddingTop: 10,
    paddingBottom: 10,
})