import { typedAction } from "../helpers";
import { SET_DS_TYPES } from "../actionTypes";
import { IDatasourceType } from "../../data-structures/interfaces";
import { IDatasourceReducer } from "../models";
import { ThunkAction } from "redux-thunk";
import { RootState } from "..";
import { Action } from "redux";
import { getDatasourceTypes as getDatasourceTypesAPI } from "../../utilities/helpers/ApiHelper"

const initialState: IDatasourceReducer = {
    types: []
};

export const setType = (types: IDatasourceType[]) => {
    try {
        return typedAction(SET_DS_TYPES, types);
    }
    catch (e) {
        console.error(e);
        return null;
    }
};

export const getDatasourceTypes = (): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
    try {
        const types: IDatasourceType[] = (await getDatasourceTypesAPI())?.sort((a, b) => (a.name > b.name) ? 1 : -1);
        if (Array.isArray(types)) {
            dispatch(setType(types));
        }
    } catch (e) {
        console.error(e);
    }
}

type RequestAction = ReturnType<typeof setType>;

export function datasourceReducer(state = initialState, action: RequestAction): IDatasourceReducer {
    switch (action.type) {
        case SET_DS_TYPES:
            return { ...state, types: action.payload };
        default:
            return state;
    }
}