import { FontSizes, FontWeights, IColumn, ILabelStyles, Label, Selection, ShimmeredDetailsList, Stack } from '@fluentui/react'
import React from 'react'
import { SubscriptionIntervalEnum, SubscriptionTierEnum } from '../data-structures/enums';
import { LanguageContext } from '../utilities/LocalizationModule';

export const subheadingStyle: Partial<ILabelStyles> = {
    root: {
        fontWeight: FontWeights.regular,
        fontSize: FontSizes.xLarge,
    }
}

type Props = {
    items: any[];
    isLoading: boolean;
    itemSelected: (items: any[]) => void;
    deselect: boolean;
    isMultiTenant: boolean;
    filterText?: string;
}

const SubscriptionsList = ({ items, isLoading, itemSelected, deselect, isMultiTenant, filterText }: Props) => {
    const languageStrings = React.useContext(LanguageContext);

    const [cols, setCols] = React.useState<IColumn[]>([]);
    const columnClickedRef = React.useRef<{ key: string, isSorted: boolean, isSortedDescending: boolean }>();

    const [filteredItems, setFilteredItems] = React.useState<any[]>(items)

    React.useEffect(() => {
        filterItems(filterText?.trim());
    }, [filterText, items]);

    const filterItems = (filter: string) => {
        if (!filter) {
            setFilteredItems(items);
        } else {
            const resultProperties = ["tenantName", "createdDate", "status", "interval", "description", "availableLicenses", "assignedLicenses", "expiryDate", "amountString"];

            const newFilteredItems = items.filter(item => 
                resultProperties.some(prop => 
                    item[prop] && item[prop].toString().toLowerCase().includes(filter.toLowerCase())
                )
            );

            setFilteredItems(newFilteredItems);
        }
    }

    React.useEffect(() => {
        if (isMultiTenant) {
            const tenantNameCol: IColumn = {
                key: 'tenantName',
                name: languageStrings.Tenant,
                fieldName: 'tenantName',
                data: "string",
                minWidth: 50,
                maxWidth: 200,
                isResizable: true,
                isSorted: columnClickedRef.current && columnClickedRef.current.key === "tenantName" ? columnClickedRef.current.isSorted : false,
                isSortedDescending: columnClickedRef.current && columnClickedRef.current.key === "tenantName" ? columnClickedRef.current.isSortedDescending : false,
                onColumnClick: _onColumnClick
            }

            const newCols = [...tenantSubscriptionCols];
            newCols.splice(1, 0, tenantNameCol);
            setCols(newCols);
        } else {
            setCols(tenantSubscriptionCols);
        }

    }, [isMultiTenant])

    function copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
        try {
            const key = columnKey as keyof T;
            return items.slice(0).sort((a: T, b: T) => {
                return ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1)
            });
        } catch (error) {
            console.error("copyAndSort: ", error);
            return items;
        }
    };

    React.useEffect(() => {
        _selection.current.setAllSelected(false);
    }, [deselect])

    const _getSelectionDetails = () => {
        itemSelected(_selection.current.getSelection());
    };

    const _selection = React.useRef(new Selection({
        onSelectionChanged: _getSelectionDetails
    }));

    const _onColumnClick = React.useCallback((_ev: React.MouseEvent<HTMLElement>, column: IColumn) => {
        try {
            setCols(prevCols => {
                const newColumns = prevCols.slice();
                const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    
                newColumns.forEach((newCol: IColumn) => {
                    if (newCol === currColumn) {
                        currColumn.isSortedDescending = !currColumn.isSortedDescending;
                        currColumn.isSorted = true;
    
                        columnClickedRef.current = {
                            key: currColumn.key,
                            isSorted: currColumn.isSorted,
                            isSortedDescending: currColumn.isSortedDescending
                        };
                    } else {
                        newCol.isSorted = false;
                        newCol.isSortedDescending = true;
                    }
                });

                setFilteredItems(prevFiltered => {
                    return copyAndSort(prevFiltered, currColumn.fieldName!, currColumn.isSortedDescending);
                });

                return newColumns;
            });


        } catch (error) {
            console.error("onColumnClick: ", error);
        }
    }, [filteredItems, cols, filterText]);

    const tenantSubscriptionCols: IColumn[] = [
        {
            key: 'createdDate',
            name: languageStrings.Created,
            fieldName: 'createdDate',
            data: "string",
            minWidth: 50,
            maxWidth: 200,
            isResizable: true,
            onRender(item, index, column) {
                return `${new Date(item.createdDate)?.toLocaleDateString()} ${new Date(item.createdDate)?.toLocaleTimeString()}`;
            },
            isSorted: columnClickedRef.current && columnClickedRef.current.key === "createdDate" ? columnClickedRef.current.isSorted : false,
            isSortedDescending: columnClickedRef.current && columnClickedRef.current.key === "createdDate" ? columnClickedRef.current.isSortedDescending : false,
            onColumnClick: _onColumnClick
        },
        {
            key: 'status',
            name: languageStrings.Status,
            fieldName: 'status',
            data: "string",
            minWidth: 70,
            maxWidth: 70,
            isResizable: true,
            isSorted: columnClickedRef.current && columnClickedRef.current.key === "status" ? columnClickedRef.current.isSorted : false,
            isSortedDescending: columnClickedRef.current && columnClickedRef.current.key === "status" ? columnClickedRef.current.isSortedDescending : false,
            onColumnClick: _onColumnClick
        },
        {
            key: 'interval',
            name: languageStrings.Frequency,
            fieldName: 'interval',
            data: "string",
            minWidth: 100,
            maxWidth: 100,
            isResizable: true,
            isSorted: columnClickedRef.current && columnClickedRef.current.key === "interval" ? columnClickedRef.current.isSorted : false,
            isSortedDescending: columnClickedRef.current && columnClickedRef.current.key === "interval" ? columnClickedRef.current.isSortedDescending : false,
            onColumnClick: _onColumnClick
        },
        {
            key: 'description',
            name: languageStrings.Description,
            fieldName: 'description',
            data: "string",
            minWidth: 50,
            maxWidth: 350,
            isResizable: true,
            isSorted: columnClickedRef.current && columnClickedRef.current.key === "description" ? columnClickedRef.current.isSorted : false,
            isSortedDescending: columnClickedRef.current && columnClickedRef.current.key === "description" ? columnClickedRef.current.isSortedDescending : false,
            onColumnClick: _onColumnClick
        },
        {
            key: 'availableLicences',
            name: languageStrings.AvailableLicences,
            fieldName: 'availableLicenses',
            data: "string",
            minWidth: 130,
            maxWidth: 130,
            isResizable: true,
            isSorted: columnClickedRef.current && columnClickedRef.current.key === "availableLicences" ? columnClickedRef.current.isSorted : false,
            isSortedDescending: columnClickedRef.current && columnClickedRef.current.key === "availableLicences" ? columnClickedRef.current.isSortedDescending : false,
            onColumnClick: _onColumnClick
        },
        {
            key: 'assignedLicenses',
            name: languageStrings.AssignedLicences,
            fieldName: 'assignedLicenses',
            data: "string",
            minWidth: 130,
            maxWidth: 130,
            isResizable: true,
            isSorted: columnClickedRef.current && columnClickedRef.current.key === "assignedLicenses" ? columnClickedRef.current.isSorted : false,
            isSortedDescending: columnClickedRef.current && columnClickedRef.current.key === "assignedLicenses" ? columnClickedRef.current.isSortedDescending : false,
            onColumnClick: _onColumnClick
        },
        {
            key: 'expiry',
            name: languageStrings.ExpiryDate,
            fieldName: 'expiryDateRaw',
            data: "string",
            minWidth: 100,
            maxWidth: 150,
            isResizable: true,
            onRender(item, index, column) {
                return item.expiryDate;
            },
            isSorted: columnClickedRef.current && columnClickedRef.current.key === "expiry" ? columnClickedRef.current.isSorted : false,
            isSortedDescending: columnClickedRef.current && columnClickedRef.current.key === "expiry" ? columnClickedRef.current.isSortedDescending : false,
            onColumnClick: _onColumnClick
        },
        {
            key: 'amount',
            name: languageStrings.Amount,
            fieldName: 'amountString',
            data: "string",
            minWidth: 50,
            isResizable: true,
            isSorted: columnClickedRef.current && columnClickedRef.current.key === "amount" ? columnClickedRef.current.isSorted : false,
            isSortedDescending: columnClickedRef.current && columnClickedRef.current.key === "amount" ? columnClickedRef.current.isSortedDescending : false,
            onColumnClick: _onColumnClick
        },
    ]

    return (
        <Stack>
            <ShimmeredDetailsList
                setKey="items"
                items={filteredItems || []}
                columns={cols}
                selection={_selection.current}
                enableShimmer={isLoading}
            />
        </Stack>
    )
}

export default SubscriptionsList