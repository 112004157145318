import React, { useContext, useState, useEffect, } from "react"
import { LanguageContext } from '../../utilities/LocalizationModule';
import { Panel, Label, Stack, PrimaryButton, CommandBarButton, PanelType, SearchBox, } from "@fluentui/react"
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../redux';
import ManageLibrariesPanel from "./ManageLibrariesPanel"
import { errorLabelStyle, panelCommandButtonStyle } from "../../styles/PanelStyle";
import * as ListStyles from "../../styles/ListStyles"
import { deleteSites, getTenantSites, addSites, deleteSiteLibraries, setSiteLibrary, setTenantSites } from "../../redux/modules/user"
import { ISite, ITenant } from "../../data-structures/interfaces";
import SitesGrid from "../SitesGrid"
import DialogMessage from "../DialogMessage";
import { MessageType } from "../../data-structures/enums";
import AddMode from "../SiteAndLibAdd"

const mapStateToProps = (state: RootState) => {
    return {
        isPartner: state.user.isPartner,
        isGlobalAdmin: state.user.isGlobalAdmin,
    };
};

const mapDispatchToProps = {
    getTenantSites,
    deleteSites,
    addSites,
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface ManageSitesPanelProps extends PropsFromRedux {
    isOpen: boolean,
    dismissPanel: () => void,
    tenant: ITenant
}

const ManageSitesPanel: React.FunctionComponent<ManageSitesPanelProps> = ({
    getTenantSites,
    deleteSites,
    addSites,
    dismissPanel,
    isOpen,
    tenant,
}) => {
    const languageStrings = useContext(LanguageContext);
    const [isLibraryPanelOpen, setIsLibraryPanelOpen] = useState<boolean>(false);
    const [filterValue, setFilterValue] = useState<string>("");
    const [selectedSites, setSelectedSites] = useState<number[]>([]);
    const [isAddMode, setIsAddMode] = useState<boolean>(false);
    const [hideDeleteDialog, setHideDeleteDialog] = useState<boolean>(true);
    const [addSiteName, setAddSiteName] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [shouldClearSelection, setShouldClearSelection] = useState(false);
    useEffect(() => {
        //do some logic to see if we have sites already
        if (isOpen && !tenant.sites && tenant.id !== 0) {
            getTenantSites(tenant.id);
        }
    }, [isOpen]);

    useEffect(() => {
        if (isLibraryPanelOpen === false && shouldClearSelection === true) {
            setShouldClearSelection(false);
            onSelect([])
        }
    }, [isLibraryPanelOpen, shouldClearSelection])

    const onChangeFilter = (filterText: string) => {
        setFilterValue(filterText ?? "");
    };

    const onClearFilter = () => {
        setFilterValue("");
    };
    const onEscapeFilter = () => {
        setFilterValue("");
    };

    const onSelect = (ids: number[]) => {
        setSelectedSites(ids);
    };

    const isDuplicate = (newField: string): boolean => {
        let duplicate = false;
        let errorMsg = "";
        if (tenant.sites.map(s => s.displayName).includes(newField)) {
            duplicate = true;
            errorMsg = languageStrings.DuplicatesFound + ' ' + newField;
        }
        setErrorMessage(errorMsg);
        return duplicate;
    }

    const onAddConfirm = () => {
        if (addSiteName && !isDuplicate(addSiteName)) {
            const newSite: ISite = {
                displayName: addSiteName,
                documentLibraries: null,
                tenantId: tenant.id,
            };
            addSites([newSite], tenant.id);
            setAddSiteName("");
        }
    }

    const onPanelExit = () => {
        setFilterValue("");
        setSelectedSites([]);
        setErrorMessage("");
        setAddSiteName("");
        dismissPanel();
    }

    return (
        <Panel
            isOpen={isOpen}
            type={PanelType.custom}
            customWidth={"500px"}
            onDismiss={() => onPanelExit()}
            headerText={languageStrings.AdvancedSiteSettings}
        >
            <Label style={{ marginBottom: 10, borderBottomColor: 'rgb(237, 235, 233)', borderBottomWidth: 0.5, borderBottomStyle: 'solid' }}>{languageStrings.ManageSitesPanelInfo}</Label>
            <Stack verticalAlign="space-between">
                <Stack style={{ paddingTop: 5, paddingBottom: 5 }} horizontal>
                    <CommandBarButton
                        iconProps={{ iconName: "add", styles: panelCommandButtonStyle }}
                        text={languageStrings.Add}
                        onClick={() => { setIsAddMode(true) }}
                    />
                    <CommandBarButton
                        disabled={selectedSites.length > 0 ? false : true}
                        iconProps={{ iconName: "delete", styles: panelCommandButtonStyle }}
                        text={languageStrings.Delete + (selectedSites.length > 0 ? ` (${selectedSites.length})` : "")}
                        onClick={() => { setHideDeleteDialog(false) }}
                    />
                    <CommandBarButton
                        disabled={selectedSites?.length === 1 ? false : true}
                        iconProps={{ iconName: "edit", styles: panelCommandButtonStyle }}
                        text={languageStrings.Edit}
                        onClick={() => {
                            setIsLibraryPanelOpen(true);
                        }}
                    />
                </Stack>
                <Stack horizontalAlign="start">
                    {isAddMode
                        ?
                        <Stack style={{ width: "100%" }}>
                            <AddMode
                                onCancel={() => setIsAddMode(false)}
                                onAccept={() => {
                                    onAddConfirm();
                                    setIsAddMode(false)
                                }}
                                siteName={addSiteName}
                                onChangeSiteName={(newValue) => setAddSiteName(newValue)}
                                onEnter={() => onAddConfirm()}
                                placeHolderText={languageStrings.EnterNewSiteName}
                            />
                            {errorMessage.length > 0 ? <Label className={errorLabelStyle}>
                                {errorMessage}
                            </Label> : null}
                        </Stack>
                        :
                        <SearchBox
                            styles={ListStyles.searchBoxStyles}
                            placeholder={languageStrings.Search}
                            onChange={(_, nv) => onChangeFilter(nv)}
                            onClear={onClearFilter}
                            onEscape={onEscapeFilter}
                        />
                    }
                </Stack>
                <SitesGrid
                    items={tenant.sites ?? []}
                    setSelectedSites={onSelect}
                    filterText={filterValue}
                    setShouldClearSelection={() => setShouldClearSelection(true)}
                />
                <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 15 }} style={{ marginTop: 20 }}>
                    <PrimaryButton
                        iconProps={{
                            iconName: "CheckMark"
                        }}
                        onClick={() => onPanelExit()}
                    >
                        {languageStrings.Done}
                    </PrimaryButton>
                </Stack>
            </Stack>

            {isLibraryPanelOpen && <ManageLibrariesPanel
                isOpen={isLibraryPanelOpen}
                dismissPanel={() => setIsLibraryPanelOpen(false)}
                siteId={selectedSites[0]}
                tenantId={tenant.id}
            />}
            <DialogMessage
                hideDialog={() => setHideDeleteDialog(true)}
                isHidden={hideDeleteDialog}
                message={"This action cannot be undone."}
                title={"Are you sure you want to delete these sites?"}
                type={MessageType.Confirmation}
                confirmAction={() => {
                    deleteSites(selectedSites, tenant?.id)
                    setHideDeleteDialog(true);
                }}
            />
        </Panel>
    )
}

export default connector(ManageSitesPanel);