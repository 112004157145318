
import React from 'react';
import { LanguageContext } from '../../utilities/LocalizationModule';
import { Panel, Stack, PrimaryButton, DefaultButton, Label, CommandBarButton, DetailsList, DetailsListLayoutMode, Sticky, ConstrainMode, IRenderFunction, IDetailsHeaderProps, IDetailsColumnRenderTooltipProps, TooltipHost, StickyPositionType, SelectionMode, IColumn, SearchBox, Selection, mergeStyles, PanelType, Spinner, ScrollablePane, ScrollbarVisibility } from "@fluentui/react"
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../redux';
import * as ListStyles from "../../styles/ListStyles"
import { ITenant } from '../../data-structures/interfaces';
import { panelActionButtonStyle, panelCommandButtonStyle } from "../../styles/PanelStyle"

const onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (props, defaultRender) => {
    if (!props) {
        return null;
    }
    const onRenderColumnHeaderTooltip: IRenderFunction<IDetailsColumnRenderTooltipProps> = tooltipHostProps => (
        <TooltipHost {...tooltipHostProps} />
    );
    return (
        <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
            {defaultRender!({
                ...props,
                onRenderColumnHeaderTooltip,
                styles: {
                    root: {
                        paddingTop: 0
                    }
                }
            })}
        </Sticky>
    );
};


const mapStateToProps = (state: RootState) => {
    return {
        tenants: state.user.tenants,
        isLoading: state.user.isUserLoading
    };
};

const connector = connect(mapStateToProps, null);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface ViewListPanelProps extends PropsFromRedux {
    isOpen: boolean,
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
    text: string,
    title: string,
    addTitle: string,
    addCallback: () => void,
    removeCallback: () => void,
    listSetter: (list: any[]) => void,
    shortList: any[],
    fullList: any[],
    columns: IColumn[],
    renderItemColumn: (item: any, index: number, column: IColumn) => JSX.Element,
    addText: string,
    removeText: string,
    multiSelect: boolean
    indicateNumbers: boolean,
    escapeRemovePanelAfterAction?: boolean,
    escapeAddPanelAfterAction?: boolean,
};


const ViewListPanel: React.FC<ViewListPanelProps> = ({
    isOpen,
    setIsOpen,
    title,
    addTitle,
    text,
    removeCallback,
    addCallback,
    listSetter,
    shortList,
    fullList,
    columns,
    renderItemColumn,
    addText,
    removeText,
    multiSelect,
    indicateNumbers,
    isLoading,
    escapeAddPanelAfterAction = false,
    escapeRemovePanelAfterAction = false,
}: ViewListPanelProps) => {
    const languageStrings = React.useContext(LanguageContext);
    const [filteredItems, setFilteredItems] = React.useState([]);
    const [stateShortList, setShortList] = React.useState(shortList);
    const [stateFullList, setFullList] = React.useState(fullList);
    const [filterValue, setFilterValue] = React.useState<string>("");
    const [isEditDisabled, setIsEditDisabled] = React.useState<boolean>(true);
    const [editItems, setEditItems] = React.useState(null);
    const [isAddMode, setIsAddMode] = React.useState(false);
    const [canEscape, setCanEscape] = React.useState<boolean>(false);

    React.useEffect(() => {
        setFilteredItems(shortList);
        setShortList(shortList);
        setFullList(fullList);
    }, [shortList, fullList])

    React.useEffect(() => {
        if (escapeRemovePanelAfterAction && canEscape && !isLoading) {
            setCanEscape(false);
            setIsOpen(false);
        }
    }, [canEscape, isLoading])

    const doFilterItems = (filterText: string) => {
        //Add filtering capabilities for enum
        const resultProperties = stateShortList?.length > 0 && stateShortList[0] ? Object.keys(stateShortList[0]) : null;
        const filterResult = filterText?.length ?
            stateShortList.filter(item => {
                return resultProperties?.some(property => {
                    let toSearch = null;
                    property == "licenseType" //handle enum searching
                        ? toSearch = item[property] == 1
                            ? languageStrings.Expired
                            : item[property] == 2
                                ? languageStrings.Paid
                                : languageStrings.Evaluation
                        : toSearch = item[property];
                    return (
                        toSearch
                            ?.toString()
                            .toLowerCase()
                            .includes(filterText.toLowerCase()))
                });
            }) :
            stateShortList;
        setFilteredItems(filterResult);
    }

    const onSearchFilter = async (filterText: string) => {
        setFilterValue(filterText);
    };
    const onChangeFilter = (filterText: string) => {
        setFilterValue(filterText);
        doFilterItems(filterText);
    };
    const resetFilterValue = () => {
        setFilterValue("");
        doFilterItems("");
        setFilteredItems(stateShortList);
    }
    const onClearFilter = () => {
        resetFilterValue();
    };
    const onEscapeFilter = () => {
        resetFilterValue();
    };

    const _selection = new Selection({
        onSelectionChanged: () => getSelectionDetails()
    });

    const getSelectionDetails = () => {
        setIsEditDisabled(!(_selection.getSelectedCount() > 0));
        const _selectedItems = _selection.getSelectedCount() > 0 ? _selection.getSelection() as ITenant[] : []
        setEditItems(_selectedItems);
    }

    return (
        <Panel
            headerText={title}
            isOpen={isOpen}
            isLightDismiss
            onDismiss={() => {
                setIsOpen(false);
            }}
            closeButtonAriaLabel="Close"
            type={PanelType.custom}
            customWidth={"500px"}
        >
            <Label style={{ marginBottom: 10, borderBottomColor: 'rgb(237, 235, 233)', borderBottomWidth: 0.5, borderBottomStyle: 'solid' }}>{text}</Label>
            <Stack horizontal horizontalAlign="start" tokens={{ childrenGap: 10 }}>
                <CommandBarButton
                    iconProps={{ iconName: "add", styles: panelCommandButtonStyle }}
                    style={{ paddingTop: 5, paddingBottom: 5, }}
                    text={addText}
                    onClick={() => {
                        setIsAddMode(true);
                    }}
                />
                <CommandBarButton
                    disabled={isEditDisabled}
                    iconProps={{ iconName: "delete", styles: panelCommandButtonStyle }}
                    style={{ paddingTop: 5, paddingBottom: 5, }}
                    text={removeText + (indicateNumbers ? (editItems?.length > 0 ? ` (${editItems?.length})` : '') : '')}
                    onClick={() => {
                        listSetter(editItems);
                        removeCallback();
                        setCanEscape(true)
                    }}
                />
                {
                    isLoading ? <Spinner /> : null
                }
            </Stack>
            <Stack horizontalAlign="start">
                <SearchBox
                    styles={ListStyles.searchBoxStyles}
                    disabled={stateShortList?.length == 0}
                    placeholder={languageStrings.Search}
                    onSearch={onSearchFilter}
                    onChange={(_, nv) => onChangeFilter(nv)}
                    onClear={onClearFilter}
                    onEscape={onEscapeFilter} />
            </Stack>
            <div className={ListStyles.viewListScrollStyle}>
                <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                    <DetailsList
                        items={filteredItems}
                        columns={columns}
                        styles={ListStyles.viewListStyle}
                        //groups={groups}
                        selection={_selection}
                        selectionMode={multiSelect ? SelectionMode.multiple : SelectionMode.single}
                        layoutMode={DetailsListLayoutMode.justified}
                        constrainMode={ConstrainMode.unconstrained}
                        onRenderItemColumn={(item, index, column) => renderItemColumn(item, index, column)}
                        onRenderDetailsHeader={onRenderDetailsHeader}
                        ariaLabelForSelectionColumn="Toggle selection"
                        ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                        checkButtonAriaLabel="select row"
                        checkboxCellClassName={mergeStyles({
                            display: "flex",
                            alignItems: "center"
                        })}
                    />
                </ScrollablePane>
            </div>


            <Stack horizontal horizontalAlign="end">
                <PrimaryButton styles={panelActionButtonStyle}
                    iconProps={{ iconName: "Checkmark" }}
                    onClick={async () => {
                        setIsOpen(false); //close panel
                    }}>
                    {languageStrings.Ok}
                </PrimaryButton>
            </Stack>

            <AddListPanel isOpen={isAddMode} setIsOpen={setIsAddMode} columns={columns} fullList={stateFullList} renderItemColumn={renderItemColumn} addCallback={() => {setIsAddMode(false); addCallback();}} text="" title={addTitle} multiSelect={multiSelect} indicateNumbers={indicateNumbers} addButtonText={addText} listSetter={listSetter} isLoading={isLoading} escapeAddPanelAfterAction={escapeAddPanelAfterAction} />

        </Panel>
    )
}

export default connector(ViewListPanel);

interface AddListPanelProps {
    isOpen: boolean,
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
    text: string,
    title: string,
    addCallback: () => void,
    listSetter: (list: any[]) => void,
    fullList: any[],
    columns: IColumn[],
    renderItemColumn: (item: any, index: number, column: IColumn) => JSX.Element,
    multiSelect: boolean,
    indicateNumbers: boolean,
    addButtonText: string,
    isLoading: boolean,
    escapeAddPanelAfterAction?: boolean,
};

const AddListPanel: React.FC<AddListPanelProps> = ({
    isOpen,
    setIsOpen,
    title,
    text,
    addCallback,
    listSetter,
    fullList,
    columns,
    renderItemColumn,
    multiSelect,
    indicateNumbers,
    addButtonText,
    isLoading,
    escapeAddPanelAfterAction = false,
}: AddListPanelProps) => {
    const languageStrings = React.useContext(LanguageContext);
    const [filteredItems, setFilteredItems] = React.useState([]);
    const [filterValue, setFilterValue] = React.useState<string>("");
    const [isEditDisabled, setIsEditDisabled] = React.useState<boolean>(true);
    const [editItems, setEditItems] = React.useState<any[]>(null);
    const [canEscape, setCanEscape] = React.useState<boolean>(false);
    React.useEffect(() => {
        setFilteredItems(fullList);
    }, [fullList])

    React.useEffect(() => {
        if (escapeAddPanelAfterAction && canEscape && !isLoading) {
            setCanEscape(false);
            setIsOpen(false);
        }
    }, [canEscape, isLoading])
    const doFilterItems = (filterText: string) => {
        //Add filtering capabilities for enum
        const list = fullList
        const resultProperties = list.length ? Object.keys(list[0]) : null;
        const filterResult = filterText?.length ?
            list.filter(item => {
                return resultProperties.some(property => {
                    let toSearch = null;
                    property == "licenseType" //handle enum searching
                        ? toSearch = item[property] == 1
                            ? languageStrings.Expired
                            : item[property] == 2
                                ? languageStrings.Paid
                                : languageStrings.Evaluation
                        : toSearch = item[property];
                    return (
                        toSearch
                            ?.toString()
                            .toLowerCase()
                            .includes(filterText.toLowerCase()))
                });
            }) :
            list;
        setFilteredItems(filterResult);
    }

    const onSearchFilter = async (filterText: string) => {
        setFilterValue(filterText);
    };
    const onChangeFilter = (filterText: string) => {
        setFilterValue(filterText);
        doFilterItems(filterText);
    };
    const resetFilterValue = () => {
        setFilterValue("");
        doFilterItems("");
        setFilteredItems(fullList);
    }
    const onClearFilter = () => {
        resetFilterValue();
    };
    const onEscapeFilter = () => {
        resetFilterValue();
    };

    const _selection = new Selection({
        onSelectionChanged: () => getSelectionDetails()
    });

    const getSelectionDetails = () => {
        setIsEditDisabled(!(_selection.getSelectedCount() > 0));
        const _selectedItems = _selection.getSelectedCount() > 0 ? _selection.getSelection() as ITenant[] : []
        setEditItems(_selectedItems);
    }

    return (
        <Panel
            headerText={title}
            isOpen={isOpen}
            isLightDismiss
            onDismiss={() => {
                setIsOpen(false);
            }}
            closeButtonAriaLabel="Close"
            type={PanelType.custom}
            customWidth={"500px"}
        >
            <Label style={{ marginBottom: 10, borderBottomColor: 'rgb(237, 235, 233)', borderBottomWidth: 0.5, borderBottomStyle: 'solid' }}>{text}</Label>
            <Stack horizontalAlign="start">
                <SearchBox
                    styles={ListStyles.searchBoxStyles}
                    disabled={fullList?.length == 0}
                    placeholder={languageStrings.Search}
                    onSearch={onSearchFilter}
                    onChange={(_, nv) => onChangeFilter(nv)}
                    onClear={onClearFilter}
                    onEscape={onEscapeFilter} />
            </Stack>
            <div className={ListStyles.addListScrollStyle}>
                <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                    <DetailsList
                        items={filteredItems}
                        columns={columns}
                        styles={ListStyles.addListStyle}
                        //groups={groups}
                        selection={_selection}
                        selectionMode={multiSelect ? SelectionMode.multiple : SelectionMode.single}
                        layoutMode={DetailsListLayoutMode.justified}
                        constrainMode={ConstrainMode.unconstrained}
                        onRenderItemColumn={(item, index, column) => renderItemColumn(item, index, column)}
                        onRenderDetailsHeader={onRenderDetailsHeader}
                        ariaLabelForSelectionColumn="Toggle selection"
                        ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                        checkButtonAriaLabel="select row"
                        checkboxCellClassName={mergeStyles({
                            display: "flex",
                            alignItems: "center"
                        })}
                    />
                </ScrollablePane>
            </div>

            <Stack tokens={{ childrenGap: 8 }}>
                <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 15 }}>
                    {
                        isLoading ? <Spinner /> : null
                    }
                    <PrimaryButton styles={panelActionButtonStyle}
                        disabled={isEditDisabled}
                        iconProps={{ iconName: "Add" }}
                        onClick={async () => {
                            listSetter(editItems);
                            addCallback();
                            setCanEscape(true);
                        }}>
                        {addButtonText + (indicateNumbers ? (editItems?.length > 0 ? ` (${editItems?.length})` : '') : '')}
                    </PrimaryButton>
                    <DefaultButton styles={panelActionButtonStyle}
                        iconProps={{ iconName: "Cancel" }}
                        onClick={async () => {
                            setIsOpen(false); //close panel
                        }}>
                        {languageStrings.Cancel}
                    </DefaultButton>
                </Stack>

            </Stack>


        </Panel>
    )
}