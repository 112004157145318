import React from 'react'
import Loading from '../Loading'
import { getAdminConsentUrl } from '../../utilities/helpers/ApiHelper';
import { LanguageContext } from '../../utilities/LocalizationModule';
import { FontSizes, FontWeights, IIconStyles, Icon, Label, Stack } from '@fluentui/react';

const successIconStyles: IIconStyles = {
    root: {
        color: "#107c10",
        fontSize: FontSizes.size18,
        fontWeight: FontWeights.bold,
        paddingRight: 5
    }
}

const Consent: React.FC = () => {
    const languageStrings = React.useContext(LanguageContext);

    const [isSuccess, setIsSuccess] = React.useState<boolean>(false);
    const [error, setError] = React.useState<string>();

    const urlParams = new URLSearchParams(window.location.search);

    React.useEffect(() => {
        if (urlParams.has("error")) {
            setError(`${languageStrings.AuthFailed}: ${urlParams.get("error_description")}`);
        }
        else if (urlParams.has("admin_consent") && urlParams.has("tenant")) {
            // success
            setIsSuccess(true);
            if (window.opener) {
                setTimeout(() => {
                    window.close();
                }, 2000);
            }
        }
        else if (urlParams.has("tid") && urlParams.has("sct")) {
            (async () => {
                try {
                    const consentUrl: string = await getAdminConsentUrl(urlParams.get("tid"), urlParams.get("sct"));
                    if (consentUrl) {
                        window.location.replace(consentUrl);
                    }
                    else {
                        setError(languageStrings.AuthFailed);
                    }
                } catch (error) {
                    setError(`${languageStrings.AuthFailed}: ${error}`);
                }
            })();
        }
    }, [urlParams]);

    return isSuccess === true
        ? <Stack horizontal verticalAlign="center" horizontalAlign="center" style={{ height: "100%" }}>
            <Icon iconName="CheckMark" styles={successIconStyles} />
            <Label>
                {languageStrings.ConsentSuccessful}
            </Label>
        </Stack>
        : !error
            ? <Loading label={languageStrings.AuthProcessing} />
            : <div className="xb-loader-container"><Label>{error}</Label></div>;
}

export default Consent;