import React, { useContext } from "react";
import { LanguageContext } from '../utilities/LocalizationModule';
import { Stack, CommandBarButton, TextField, getTheme } from "@fluentui/react"

interface AddModeProps {
    onCancel: () => void,
    onAccept: () => void,
    siteName: string,
    onChangeSiteName: (newValue: string) => void,
    onEnter: () => void,
    placeHolderText: string,
}

const SiteAndLibAdd: React.FunctionComponent<AddModeProps> = ({
    onCancel,
    onAccept,
    siteName,
    onChangeSiteName,
    onEnter,
    placeHolderText
}) => {
    const languageStrings = useContext(LanguageContext);
    return (
        <Stack horizontal horizontalAlign="stretch" style={{ width: "100%", marginBottom: 15, marginTop: 10 }}>
            <TextField
                placeholder={placeHolderText}
                styles={{
                    root: {
                        width: "100%",
                        borderColor: getTheme().palette.themeDarkAlt,
                        marginLeft: 0,
                    }
                }}
                value={siteName}
                onChange={(_, nv) => { onChangeSiteName(nv) }}
                onKeyPress={(ev) => {
                    if (ev.key === "Enter" && siteName)
                        onEnter();
                }}
            />
            <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 5 }} style={{ marginLeft: 10 }}>
                <CommandBarButton
                    iconProps={{ iconName: "cancel" }}
                    onClick={() => onCancel()}
                />
                <CommandBarButton
                    iconProps={{ iconName: "CheckMark" }}
                    onClick={() => onAccept()} //this needs updating.
                />
            </Stack>
        </Stack>
    )
}

export default SiteAndLibAdd