
import React from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import { BarChart, Bar, } from 'recharts';
import { Dropdown, IDropdownOption, Label, Stack, Icon } from '@fluentui/react';
import * as DashboardStyles from "../styles/DashboardStyles"
import { GraphType } from "../data-structures/enums"
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../redux';
import { dropdownStyles } from "../styles/PanelStyle"
import { getGraphData, dropDownOptions } from "../utilities/helpers/ChartHelper"

const mapDispatchToProps = {

}

const mapStateToProps = (state: RootState) => {
    return {
        users: state.user.users,
        activities: state.user.userActivities,
    };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface ChartProps extends PropsFromRedux {
    title: string,
    width: number,
    height: number,
    type: GraphType,
};


const onRenderTitle = (options: IDropdownOption[]): JSX.Element => {
    const option = options[0];

    return (
        <Stack horizontal horizontalAlign="center">
            <Icon style={{ marginRight: 8, fontSize: 18 }} iconName="Calendar" />
            <span>{option.text}</span>
        </Stack>
    );
};

const Chart: React.FC<ChartProps> = ({ title, width, height, type, users, activities }: ChartProps) => {
    const [renderCount, forceUpdate] = React.useReducer(x => x + 1, 0);
    const [dataInterval, setDataInterval] = React.useState(31)
    const [data, setData] = React.useState(null);
    React.useEffect(() => {
        const newData = getGraphData(users, activities, dataInterval, type);
        setData(newData)
    }, [dataInterval, users])

    React.useLayoutEffect(() => {
        forceUpdate();
    }, [data, forceUpdate, users])
    return (
        <div>
            <Stack horizontal horizontalAlign="space-between" verticalAlign="center">
                <Label styles={DashboardStyles.chartTitle}>
                    {title}
                </Label>
                <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 10 }}>
                    {/* <FontIcon iconName="Calendar" style={{ fontSize: 22 }} /> */}
                    <Dropdown
                        label=""
                        onRenderTitle={onRenderTitle}
                        styles={dropdownStyles}
                        selectedKey={dataInterval}
                        onChange={(e, o) => {
                            setDataInterval(Number(o.key))
                        }}
                        options={dropDownOptions}
                    />
                </Stack>

            </Stack>

            {type === GraphType.Registrations ?

                <AreaChart
                    key={renderCount + 5}
                    data={data ?? []}
                    width={width}
                    height={height}
                    margin={{
                        top: 10,
                        left: 0,
                        bottom: 0,
                    }}
                >
                    <defs>
                        <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="10%" stopColor="#106ebe" stopOpacity={0.8} />
                            <stop offset="100%" stopColor="#49a2ec" stopOpacity={0.1} />
                        </linearGradient>
                    </defs>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                        dataKey="date"
                        fontWeight={400}
                        fontSize={11}
                        type="number"
                        tickFormatter={(v: number) => {
                            const d = new Date(v);
                            return `${d.getMonth() + 1}/${d.getDate()}`
                            // return new Date(v).toDateString()
                        }}
                        domain={['auto', 'auto']}
                        scale={"linear"}
                        interval={Math.trunc(data?.length / 5)}
                        key={renderCount + 1}

                    />

                    <YAxis fontWeight={400} fontSize={11} key={renderCount + 2} />
                    <Tooltip animationDuration={0} labelFormatter={(l: number, p) => {
                        return new Date(l).toDateString()
                    }} />
                    <Area type="monotone" dataKey="Registrations" stroke="#01447d" fill="url(#colorPv)" />
                </AreaChart>
                : null}
            {
                type === GraphType.UserActivity ?
                    <BarChart
                        key={renderCount + 3}
                        width={width}
                        height={height}
                        data={data ?? []}
                        margin={{
                            top: 10,
                            left: 0,
                            bottom: 0,
                        }}
                    >
                        <defs>
                            <linearGradient id="colorBar" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="10%" stopColor="#106ebe" stopOpacity={0.9} />
                                <stop offset="100%" stopColor="#49a2ec" stopOpacity={0.6} />
                            </linearGradient>
                        </defs>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                            dataKey="date"
                            fontWeight={400}
                            fontSize={11}
                            tickFormatter={(v: number) => {
                                const d = new Date(v);
                                return `${d.getMonth() + 1}/${d.getDate()}`
                                // return new Date(v).toDateString()
                            }}
                            domain={['auto', 'auto']}
                            interval={Math.trunc(data?.length / 5)}
                            key={renderCount + 4} />
                        <YAxis fontWeight={400} fontSize={11} />
                        <Tooltip animationDuration={0} formatter={(value, name, props) => {
                            return [`${value} User${value > 1 || value === 0 ? 's' : ''}`, "Active users on this day"]
                        }}
                            labelFormatter={(l: number, p) => {
                                return new Date(l).toDateString()
                            }} />
                        <Bar dataKey="numberOfUsers" fill="url(#colorBar)" />
                    </BarChart>
                    : null
            }
        </div>
    )
}

export default connector(Chart);