import * as React from 'react';
import { LanguageContext } from '../../utilities/LocalizationModule';
import {
    ScrollablePane,
    ScrollbarVisibility,
    IIconProps,
    CommandBarButton,
    Stack,
    Label,
    Separator,
    IScrollablePaneStyles,
    SearchBox
} from '@fluentui/react';
import { IStyledTenant, ITenant } from '../../data-structures/interfaces';
import { RootState } from '../../redux';
import { connect, ConnectedProps } from 'react-redux';
import { requestTenants } from '../../utilities/helpers/ApiHelper';
import { v4 as uuidv4, validate as uuidValidate } from 'uuid';
import * as DashboardStyle from "../../styles/DashboardStyles"
import TenantRow from "../TenantRow"
import TenantCard from "../TenantCard"
import Chart from "../Chart"
import { GraphType, LicenseType, MessageType } from '../../data-structures/enums';
import EditTenantPanel from '../panels/EditTenantPanel';
import DialogMessage from "../DialogMessage"
import { setUsers, updateTenant as updateTenantRedux, deleteTenant, deleteTenantGlobal, assignTenants, addTenant } from '../../redux/modules/user';
import { removeRequest } from '../../redux/modules/requests'
import { getShuffledColors, ICardColor } from '../../styles/ColorList';
import { useWindowDimensions } from "../../utilities/customHooks"

//https://24ways.org/2010/calculating-color-contrast/
function getContrastYIQ(hexcolor: string): string {
    var r = parseInt(hexcolor.slice(1).substr(0, 2), 16);
    var g = parseInt(hexcolor.slice(1).substr(2, 2), 16);
    var b = parseInt(hexcolor.slice(1).substr(4, 2), 16);
    var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    return (yiq >= 128) ? 'black' : 'white';
}

const addIcon: IIconProps = { iconName: 'Add' };

const mapDispatchToProps = {
    setUsers,
    updateTenantRedux,
    deleteTenant,
    deleteTenantGlobal,
    removeRequest,
    assignTenants,
    addTenant
}

const mapStateToProps = (state: RootState) => {
    return {
        userId: state.user.id,
        users: state.user.users,
        tenants: state.user.tenants,
        partners: state.user.partners,
        isGlobalAdmin: state.user.isGlobalAdmin,
        isPartner: state.user.isPartner,
        isTenantAdmin: state.user.isTenantAdmin,
        requests: state.requests.requests
    };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface DashboardProps extends PropsFromRedux {
    updateData: () => Promise<void>,
    browseToSubscription: (tenant: ITenant) => void;
};

const Dashboard: React.FC<DashboardProps> = ({
    userId,
    users,
    updateData,
    tenants,
    updateTenantRedux,
    deleteTenant,
    isPartner,
    isGlobalAdmin,
    deleteTenantGlobal,
    removeRequest,
    requests,
    addTenant,
    partners,
    browseToSubscription
}: DashboardProps) => {
    const [items, setItems] = React.useState<IStyledTenant[]>([]);
    const [originalItems, setOriginalItems] = React.useState<IStyledTenant[]>([]);
    const languageStrings = React.useContext(LanguageContext);
    const [isSaveDisabled, setIsSaveDisabled] = React.useState<boolean>(true);
    const [isEditPanelOpen, setIsEditPanelOpen] = React.useState<boolean>(false);
    const [showDeleteDialog, setShowDeleteDialog] = React.useState<boolean>(false);
    const [showGlobalDetelDialog, setShowGlobalDeleteDialog] = React.useState<boolean>(false);
    const [showSuccessFailMessage, setShowSuccessFailMessage] = React.useState<boolean>(false);
    const [tenantToEdit, setTenantToEdit] = React.useState<IStyledTenant>(null);
    const [filteredTenants, setFilteredTenants] = React.useState<IStyledTenant[]>([]);
    const [errorMessage, setErrorMessage] = React.useState<string>("");
    const [filterValue, setFilterValue] = React.useState<string>("");
    const { height, width } = useWindowDimensions();
    const [ rows, setRows ] = React.useState<JSX.Element[]>([]);

    const chartWidth = width === 1024 ? 300 : Math.max(width * 0.35, 450);
    const chartHeight = width <= 1024 ? 150 : Math.min(Math.max(height * 0.35, 200), 350);

    React.useEffect(() => {
        const shuffledColorList: ICardColor[] = getShuffledColors(tenants?.length);
        const newTenants: IStyledTenant[] = tenants.map((t, i) =>
        ({
            ...t,
            key: uuidv4(),
            color: shuffledColorList[i].color,
            textColor: getContrastYIQ(shuffledColorList[i].color),
        })
        )
        setItems(newTenants)
        setFilteredTenants(newTenants);
        const originalTenants: IStyledTenant[] = tenants.map(t =>
        ({
            ...t,
            key: uuidv4(),
        })
        );
        setOriginalItems(originalTenants);
    }, [userId, tenants]);

    React.useEffect(() => {
        if (isEditPanelOpen === false) {
            setIsSaveDisabled(true);
        } else {
            isDirty();
        }
        doFilterItems(filterValue);


        setRows(items.map((item, index) => item.id === 0 ?
        <TenantRow
            key={index}
            item={item}
            index={index}
            discardAddTenant={discardAddTenant}
            changeFriendlyName={changeFriendlyName}
            changePartnerId={changePartnerId}
            changeTenantId={changeTenantId}
            languageStrings={languageStrings}
            validateId={validateId}
            validateName={validateName}
            isDirty={isDirty}
            partners={partners}
            isGlobalAdmin={isGlobalAdmin}
        /> : null)
        .filter(item => item !== null));       

    }, [items])

    React.useEffect(() => {
        if (tenants.length > 0 && tenantToEdit) {
            setTenantToEdit(tenants.find(t => tenantToEdit.id === t.id))
        }
    }, [tenants]);

    const discardAddTenant = () => {
        setItems(items.filter(x => x.id !== 0));
    }

    const deleteItem = async (toDelete: ITenant) => {
        let newItems: IStyledTenant[] = [];
        items.forEach(item => {
            newItems.push(item.key !== toDelete.key ? item : null)
        });
        setItems(newItems.filter(item => item != null));
        await deleteTenant(toDelete);
        setIsSaveDisabled(true);
    };
    const deleteItemGlobal = async (toDelete: ITenant) => {
        const request = requests.find(r => r.tenant.id == toDelete.id);
        if (request) {
            removeRequest(request.id)
        }
        await deleteTenantGlobal(toDelete.id);
    };

    const changeFriendlyName = (text: string, item: IStyledTenant) => { //used when adding a NEW tenant - adding rows.
        const changedTenant = { ...item };
        changedTenant.friendlyName = text;
        const tmpItems = [...items];
        tmpItems[tmpItems.findIndex(i => i.key === changedTenant.key)] = changedTenant;
        setItems(tmpItems); //update local state

        if (item?.id !== 0) {
            updateTenantRedux(changedTenant);
        }
    };
    const updateTenant = (newItem: ITenant, item: IStyledTenant) => {
        const tmpItems = [...items];
        tmpItems[tmpItems.findIndex(i => i.key === newItem.key)] = newItem;
        setItems(tmpItems); //update local state

        if (item?.id !== 0) {
            updateTenantRedux(newItem); //redux and api call
        }
    };

    const changeTenantId = (text: string, item: IStyledTenant) => {
        item.tenantId = text;
        const tmpItems = [...items];
        tmpItems[tmpItems.findIndex(i => i.key === item.key)] = item;
        setItems(tmpItems)
    };

    const changePartnerId = (item: IStyledTenant) => {
        const tmpItems = [...items];
        tmpItems[tmpItems.findIndex(i => i.key === item.key)] = item;
        setItems(tmpItems);
    };

    const saveChanges = async (items: ITenant[]) => {
        try {
            //await updatePartnerTenants(partnerId, items); //apiCall
            if (isGlobalAdmin) {
                const newTenants = items.filter(t => t.id == 0);
                //redux add tenant ? Not yet
                setTenantToEdit(newTenants[0])
                //set tenant as current edit item
                setIsEditPanelOpen(true);
                //open panel as a sideeffect after setting current edit item
            }
            else if (isPartner) {
                const newTenants = items.filter(t => t.id == 0);
                const result = await requestTenants(userId, newTenants[0].partner.id, newTenants); //apiCall
                switch (result) {
                    case "AssignedToAnother":
                        setErrorMessage(languageStrings.AssignedToAnother);
                        break;
                    case "AssignedToYou":
                        setErrorMessage(languageStrings.AssignedToYou);
                        break;
                    default: setErrorMessage(""); break;
                }
                setShowSuccessFailMessage(true)
                // await updateData();
            }
        } catch (e) {
            console.error(e);
            setErrorMessage("");
        }
    };

    const isDirty = () => {
        let enableSave: boolean = false;
        if (originalItems && items) {
            //check if the number of items have changed
            if (originalItems.length !== items.length) {
                //new number of items
                const hasInvalidID = items.some(item => validateId(item.tenantId) != ""); //check if any items have invalid ids
                const hasInvalidName = items.some(item => validateName(item.friendlyName) != ""); //check if any items have invalid names
                enableSave = !hasInvalidID && !hasInvalidName;
            }
            else { //number of items are the same
                //check if contents have changed
                for (let i = 0; i < items.length; i++) {
                    if (items[i].friendlyName !== originalItems[i].friendlyName ||
                        items[i].tenantId !== originalItems[i].tenantId) {
                        enableSave = true;
                        break;
                    }
                }
            }

        }
        setIsSaveDisabled(!enableSave);
    }

    const validateId = (value: string): string => {
        const isValid: boolean = uuidValidate(value);
        return isValid ? '' : "Please enter a valid Tenant ID";
    }
    const validateName = (value: string): string => {
        const isValid: boolean = value != "";
        return isValid ? '' : "Please enter a friendly name";
    }

    const cards = [...filteredTenants].filter(item => item.id != 0).map((item, index) => {
        return (<TenantCard key={index} index={index} tenant={item} users={users}
            setTenantToEdit={setTenantToEdit} setIsEditPanelOpen={setIsEditPanelOpen} />)
    })

    const scrollpane: Partial<IScrollablePaneStyles> = {
        contentContainer: {
            overflowX: "hidden"
        }
    };

    const doFilterItems = (filterText: string) => {
        //Add filtering capabilities for enum
        const resultProperties = items.length ? Object.keys(items[0]) : null;
        const filterResult = filterText?.length ?
            items.filter(item => {
                return resultProperties.some(property => {
                    return (
                        item[property]
                            ?.toString()
                            .toLowerCase()
                            .includes(filterText.toLowerCase())) || ((languageStrings.NoPartner.toLowerCase().includes(filterText.toLowerCase()) && !(item.partner)) || item.partner?.name.toLowerCase().includes(filterText.toLowerCase()))
                });
            }) :
            items;
        setFilteredTenants(filterResult);
    }

    const onSearchFilter = async (filterText: string) => {
        setFilterValue(filterText ?? "");
    };
    const onChangeFilter = (filterText: string) => {
        setFilterValue(filterText ?? "");
        doFilterItems(filterText ?? "");
    };
    const resetFilterValue = () => {
        setFilterValue("");
        doFilterItems("");
        setFilteredTenants(items);
    }
    const onClearFilter = () => {
        resetFilterValue();
    };
    const onEscapeFilter = () => {
        resetFilterValue();
    };
   
    return (
        <div style={{ position: "relative" }}>
            <Label styles={DashboardStyle.headingStyle}>{languageStrings.ManagedTenants}</Label>
            {(isPartner || isGlobalAdmin) && <Stack horizontal style={{ paddingTop: 10 }}>
                <Stack.Item>
                    <CommandBarButton
                        iconProps={addIcon}
                        disabled={!([...items].filter(i => i.id == 0).length < 1)}
                        style={{ paddingTop: 5, paddingBottom: 5 }}
                        text={isGlobalAdmin ? languageStrings.AddTenant : languageStrings.RequestTenant}
                        onClick={() => {
                            setIsSaveDisabled(true);
                            setItems([...items, {
                                key: uuidv4(),
                                id: 0,
                                tenantId: "",
                                friendlyName: "",
                                enableOneDrive: true,
                                enableSharePoint: true,
                                enableTeams: true,
                                saveOnSend: false,
                                autoSelectAttachments: false,
                                autoRemoveMailItemAttachments: false,
                                convertEmailToPDF: false,
                                enableApprovals: false,
                                defaultLicenseType: LicenseType.Eval,
                                allowOpenInSharePoint: true,
                                apiKey: null
                            }])
                        }}
                    />
                </Stack.Item>
                {!isSaveDisabled && <Stack.Item>
                    <CommandBarButton
                        disabled={isSaveDisabled}
                        iconProps={{ iconName: isGlobalAdmin ? "SaveAs" : "Save" }}
                        style={{ paddingTop: 5, paddingBottom: 5 }}
                        text={isGlobalAdmin ? languageStrings.Configure : languageStrings.SaveRequest}
                        onClick={() => saveChanges(items)} //call redux thunk action
                    />
                </Stack.Item>}
            </Stack>}
            <Separator className={DashboardStyle.divider} />
            {rows.length > 0 ? <Stack horizontal style={{ paddingBottom: 5 }}>
                <Label className={DashboardStyle.tableHeader}>Tenant ID</Label>
                <Label className={DashboardStyle.tableHeader}>Friendly name</Label>
                {!isGlobalAdmin && <Label className={DashboardStyle.tableHeader}>Partner</Label>}
            </Stack> : null}
            {rows.length > 0 ? <Stack tokens={{ childrenGap: 15 }}>
                {rows}
                <Separator className={DashboardStyle.divider} />
            </Stack> : null}
            <div style={DashboardStyle.tableContainerStyle}>

                <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto} styles={scrollpane}>


                    <Stack horizontal tokens={{ childrenGap: 70 }} wrap grow={true}>
                        <Chart title={languageStrings.TotalRegistrations} width={chartWidth} height={chartHeight} type={GraphType.Registrations} />
                        <Chart title={languageStrings.UserActivity} width={chartWidth} height={chartHeight} type={GraphType.UserActivity} />
                    </Stack>
                    <Separator className={DashboardStyle.wideDivider} />
                    <div>
                        <Stack horizontal verticalAlign="center" style={{ marginTop: 15 }} tokens={{ childrenGap: 60 }} >
                            <Label styles={DashboardStyle.subheadingStyle}>{languageStrings.Tenants}</Label>
                            {isGlobalAdmin || isPartner ? <SearchBox
                                styles={DashboardStyle.searchBoxStyles}
                                disabled={!items?.length}
                                placeholder={languageStrings.Search}
                                onSearch={onSearchFilter}
                                onChange={(_, nv) => onChangeFilter(nv)}
                                onClear={onClearFilter}
                                onEscape={onEscapeFilter} /> : null}
                        </Stack>
                        <Stack wrap horizontal tokens={{ childrenGap: 50 }} style={{ marginTop: 20 }} grow={false}>
                            {cards}
                        </Stack>
                    </div>
                </ScrollablePane>
            </div>
            {tenantToEdit ?
                <EditTenantPanel
                    isOpen={isEditPanelOpen}
                    setIsOpen={setIsEditPanelOpen}
                    tenant={tenantToEdit}
                    tenantUsers={users.filter(u => u.tenantId == tenantToEdit.tenantId)}
                    updateTenant={updateTenant}
                    validateName={validateName}
                    setShowDeleteDialog={setShowDeleteDialog}
                    setShowGlobalDeleteDialog={setShowGlobalDeleteDialog}
                    closeOnAddSuccess={() => setItems(items.filter(x => x.id !== 0))}
                    browseToSubscription={browseToSubscription}
                /> : null}
            {showDeleteDialog ?
                <DialogMessage
                    isHidden={!showDeleteDialog}
                    hideDialog={() => setShowDeleteDialog(false)}
                    title="Delete tenant"
                    message="Are you sure you want to delete this tenant?"
                    confirmAction={() => deleteItem(tenantToEdit)}
                    type={MessageType.Confirmation}
                />
                : null
            }
            <DialogMessage
                isHidden={!showGlobalDetelDialog}
                hideDialog={() => setShowGlobalDeleteDialog(false)}
                title="Delete tenant"
                message="Are you sure you want to delete this tenant?"
                confirmAction={() => deleteItemGlobal(tenantToEdit)}
                type={MessageType.Confirmation}
            />
            <DialogMessage
                isHidden={!showSuccessFailMessage}
                hideDialog={() => setShowSuccessFailMessage(false)}
                title={errorMessage ? languageStrings.Error : languageStrings.Success}
                message={errorMessage ? errorMessage : languageStrings.TenantRequestSuccess}
                confirmAction={() => {
                    const popItem = () => {
                        const newItems = [...items];
                        newItems.pop();
                        setItems(newItems)
                    }
                    return errorMessage ? null : popItem()
                }}
                type={errorMessage ? MessageType.Fail : MessageType.Success}
            />
        </div>
    )
}

export default connector(Dashboard);