import { configureStore } from '@reduxjs/toolkit';
import { datasourceReducer } from "../../redux/modules/datasource";
import { requestReducer } from "../../redux/modules/requests";
import { userReducer } from "../../redux/modules/user";
import { filterReducer } from "../../redux/modules/filters";

import { apiGoogleDlpSlice } from './api/google-dlp-api-slice';

export const store = configureStore({
  reducer: {
    user: userReducer,
    requests: requestReducer,
    datasource: datasourceReducer,
    filter: filterReducer,
    [apiGoogleDlpSlice.reducerPath]: apiGoogleDlpSlice.reducer
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false
    }).concat(apiGoogleDlpSlice.middleware);
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;