
import React from 'react';
import { LanguageContext } from '../../utilities/LocalizationModule';
import { Panel, Stack, PrimaryButton, Label, CommandBarButton, PanelType, Spinner, Dropdown, IDropdownOption, DefaultButton, TextField, SpinnerSize, Dialog, DialogType, IModalProps, mergeStyles, DialogFooter } from "@fluentui/react"
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../redux';
import { panelActionButtonStyle, panelCommandButtonStyle } from "../../styles/PanelStyle"
import { IDatasource, IDatasourceAuthDto, ITenant } from '../../data-structures/interfaces';
import { createNewDatasource, deleteDatasource, getDatasourceStructure, getTenantDatasource } from '../../utilities/helpers/ApiHelper';
import { setTenantDatasource } from '../../redux/modules/user';
import DatasourceGrid from "../DatasourceGrid"
import EditDatasourcePanel from "./EditDatasourcePanel"
import InfoBox from "../InfoBox"
import { setLoading } from '../../redux/modules/requests';
import { useAccount, useMsal } from '@azure/msal-react';
import { DatasourceTypeEnum } from '../../data-structures/enums';
import { sitesScopes } from '../../utilities/authConfig';
import { AccountInfo, AuthenticationResult, InteractionRequiredAuthError } from '@azure/msal-browser';
import * as LZString from "lz-string";
import { ErrorHelper } from '../../utilities/helpers/ErrorHelper';

const mapStateToProps = (state: RootState) => {
    return {
        isLoading: state.user.isUserLoading,
        dsTypes: state.datasource.types,
    };
};

const mapDispatchToProps = {
    //getTenantDatasources,
    setTenantDatasource
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface DataSourcePanelProps extends PropsFromRedux {
    isOpen: boolean,
    dismissPanel: () => void,
    tenant: ITenant,
};

const dialogStyles = { main: { maxWidth: 450 } };

const createTokenGap = 15;

const DataSourcePanel: React.FC<DataSourcePanelProps> = ({
    isOpen,
    dismissPanel,
    tenant,
    setTenantDatasource,
    dsTypes
    //getTenantDatasources,
}: DataSourcePanelProps) => {
    const languageStrings = React.useContext(LanguageContext);

    const { instance, accounts, inProgress } = useMsal();
    const account = useAccount(accounts[0] || {});

    const [isDeleteDisabled, setIsDeleteDisabled] = React.useState(true)
    const [isEditDisabled, setIsEditDisabled] = React.useState(true)
    const [isCreateMode, setIsCreateMode] = React.useState(false);
    const [newDatasource, setNewDatasource] = React.useState<IDatasource>(null);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [selectedDatasource, setSelectedDatasource] = React.useState<number>()
    const [shouldClearSelection, setShouldClearSelection] = React.useState(false);
    const [isEditPanelOpen, setIsEditPanelOpen] = React.useState(false);
    const [datasources, setDatasources] = React.useState<IDatasource[]>([]);
    const [structure, setStructure] = React.useState<string[]>([]);
    const [showDeleteDialog, setShowDeleteDialog] = React.useState<boolean>(false);

    React.useEffect(() => {
        const asyncWrapper = async () => {
            if (!tenant.datasources && tenant.id !== 0) {
                setIsLoading(true);
                const datasourcesTmp = await getTenantDatasource(tenant.id);
                setDatasources(datasourcesTmp);
                setTenantDatasource(tenant.id, datasourcesTmp)
                setIsLoading(false);
            } else {
                setDatasources(tenant.datasources);
            }
        }
        asyncWrapper();
    }, [isOpen]);

    React.useEffect(() => {
        if (shouldClearSelection === true) {
            setShouldClearSelection(false);
            setIsEditDisabled(true);
            setIsDeleteDisabled(true);
        }
    }, [isOpen, shouldClearSelection]);

    React.useEffect(() => {
        setDatasources(tenant.datasources);
        onSelect(selectedDatasource); //sets the selected ds to the current one
    }, [tenant.datasources]);

    React.useEffect(() => {
        function handleMessage(event) {
            if (event.origin !== window.location.origin) return;

            const receivedData = event.data;

            if (receivedData.type === 'authCode') {
                setNewDatasource(prev => {
                    return ({
                        ...prev,
                        authTokenData: receivedData.code
                    })
                })
            }
        }

        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);

    React.useEffect(() => {
        const asyncWrapper = async () => {
            const datasource = datasources?.find(d => d.id == selectedDatasource);
            if (datasource) {
                let struct: string[];
                if (account && datasource.typeId === DatasourceTypeEnum.SharePoint) {
                    // const accessToken: string = await getGraphSitesAccessToken(account);
                    // if (accessToken) {
                    //     const columns = getListStructure(accessToken, datasource.url, datasource.name);
                    // }
                    // setSitesAccessToken(accessToken);
                }
                else {
                    struct = await getDatasourceStructure({
                        name: datasource.name,
                        tenantId: datasource.tenantId,
                        typeId: datasource.typeId,
                        typeName: dsTypes.find(t => t.id === datasource.typeId).name, //removes all white spaces
                    });
                }
                setStructure(struct ?? []);
            }
        }
        asyncWrapper();
    }, [selectedDatasource, account, instance]);

    // React.useEffect(() => {
    //     if (newDatasource?.type?.id === DatasourceTypeEnum.SharePoint && sitesAccessToken === undefined) {
    //         (async () => {
    //             const accessToken: string = await getGraphSitesAccessToken(account);
    //             setSitesAccessToken(accessToken);
    //         })();
    //     }
    // }, [newDatasource]);

    const getGraphSitesAccessToken = async (account: AccountInfo): Promise<string | null> => {
        const sharePointAccessRequest = {
            scopes: sitesScopes,
            account: account
        };

        let accessTokenResponse: AuthenticationResult;
        try {
            accessTokenResponse = await instance.acquireTokenSilent(sharePointAccessRequest);
        } catch (error) {
            if (error instanceof InteractionRequiredAuthError) {
                try {
                    accessTokenResponse = await instance.acquireTokenPopup(sharePointAccessRequest);
                } catch (error) {
                    ErrorHelper.handle(error);
                }
            }
        }

        if (accessTokenResponse) {
            return accessTokenResponse.accessToken;
        }

        return null;
    }

    const onSelectDataSourceType = (option: IDropdownOption) => {
        const dsType = dsTypes.find(t => t.id === option.key);
        setNewDatasource(prev => {
            return ({
                ...prev,
                typeId: dsType.id,
                type: dsType
            })
        })
    }

    const isCreateDisabled: boolean = React.useMemo(() => {
        if (!newDatasource
            || (newDatasource && !newDatasource.name)
            || (newDatasource.type?.isUrlRequired && !newDatasource.url)
            || (newDatasource.type?.isKeyRequired && !newDatasource.authKey)
            || (newDatasource.type?.isSecretRequired && !newDatasource.authSecret)
            || (newDatasource.type?.isTokenRequired && !newDatasource.authTokenData)) {
            return true;
        }
        return false;
    }, [newDatasource]);

    const isConfirgureAuthDisabled: boolean = React.useMemo(() => {
        if (!newDatasource
            || (newDatasource && !newDatasource.name)
            || (newDatasource.type?.isUrlRequired && !newDatasource.url)
            || (newDatasource.type?.isKeyRequired && !newDatasource.authKey)
            || (newDatasource.type?.isSecretRequired && !newDatasource.authSecret)) {
            return true;
        }
        return false;
    }, [newDatasource]);

    const onCreateNewDataSource = async () => { //when "Done" is clicked
        setIsCreateMode(false);
        setIsLoading(true);
        const newDS = await createNewDatasource(newDatasource);
        const newDatasources = tenant.datasources.concat(newDS);
        setTenantDatasource(tenant.id, newDatasources);
        setDatasources(newDatasources);
        setIsLoading(false);
    }

    const onDeleteDatasource = async () => {
        setLoading(true);
        const toDelete = selectedDatasource;
        const res = await deleteDatasource(toDelete);
        const newDatasources = tenant.datasources.filter(d => d.id != toDelete);
        if (res == 200) {
            setTenantDatasource(tenant.id, newDatasources);
            setDatasources(newDatasources);
        }
        setLoading(false);
    }

    const onSelect = (id: number) => { //select items from the grid
        setSelectedDatasource(id);
        if (!Number.isNaN(Number(id)) && id != null) {
            setIsEditDisabled(false);
            setIsDeleteDisabled(false);
        } else {
            setIsEditDisabled(true);
            setIsDeleteDisabled(true);
        }
    };

    const datasourceTypeOptions: IDropdownOption[] = React.useMemo(() => {
        const typeIds = dsTypes?.map(t => t.id) ?? [];
        const myTypeIds = datasources?.filter(d => !d?.type?.isMultipleAllowed).map(d => d.typeId) ?? [];
        const notYetCreatedIds = typeIds.filter(t => !myTypeIds.includes(t));
        return dsTypes
            .filter(t => t.isAvailableInPortal && notYetCreatedIds.includes(t.id))
            .map<IDropdownOption>(t => {
                return ({
                    key: t.id,
                    text: t.name,
                })
            });
    }, [datasources]);

    const dialogContentProps = {
        type: DialogType.normal,
        title: `${languageStrings.Delete} ${languageStrings.DataSource.toLowerCase()}`,
        closeButtonAriaLabel: 'Close',
        subText: languageStrings.DeleteWarningMessageDataSource,
    };
    const modalProps: IModalProps = {
        isBlocking: false,
        styles: dialogStyles,
        dragOptions: undefined,
        overlay: {
            className: mergeStyles({
                transition: 'all ease 1s',
            }),
        },
    };

    const DeleteDialogMessage: React.FC = () => {
        return (
            <div>
                <Dialog hidden={!showDeleteDialog} onDismiss={() => setShowDeleteDialog(false)} dialogContentProps={dialogContentProps} modalProps={modalProps}>
                    <DialogFooter>
                        <PrimaryButton
                            disabled={isLoading}
                            onClick={async () => {
                                await onDeleteDatasource();
                                setShowDeleteDialog(false);
                            }}
                            text={languageStrings.Confirm}
                        />
                        <DefaultButton
                            disabled={isLoading}
                            onClick={() => {
                                setShowDeleteDialog(false);
                            }}
                            text={languageStrings.Cancel}
                        />
                    </DialogFooter>
                </Dialog>
            </div>
        );
    };

    return (
        <Panel
            headerText={languageStrings.DataSources}
            isOpen={isOpen}
            onDismiss={() => {
                dismissPanel()
            }}
            closeButtonAriaLabel="Close"
            type={PanelType.custom}
            customWidth={"500px"}
        >
            <Stack horizontal horizontalAlign="space-between" style={{ marginBottom: 10, borderBottomColor: 'rgb(237, 235, 233)', borderBottomWidth: 0.5, borderBottomStyle: 'solid' }}>
                <Label >{languageStrings.ManageDatasources}</Label>
                <InfoBox title={languageStrings.ManageDatasources} content={languageStrings.DataSourceDescription} />
            </Stack>

            <Stack horizontal horizontalAlign="start" tokens={{ childrenGap: 10 }}>
                <CommandBarButton
                    iconProps={{ iconName: "add", styles: panelCommandButtonStyle }}
                    style={{ paddingTop: 5, paddingBottom: 5, }}
                    text={languageStrings.Add}
                    onClick={() => {
                        setIsCreateMode(true);
                        setNewDatasource({
                            id: -1,
                            name: "",
                            tenantId: tenant.id,
                            typeId: null,
                            authKey: null,
                            authSecret: null,
                            url: null,
                            boundFields: [],
                        })
                    }}
                />
                <CommandBarButton
                    disabled={isEditDisabled}
                    iconProps={{ iconName: "edit", styles: panelCommandButtonStyle }}
                    style={{ paddingTop: 5, paddingBottom: 5, }}
                    text={languageStrings.Edit}
                    onClick={() => setIsEditPanelOpen(true)}
                />
                <CommandBarButton
                    disabled={isDeleteDisabled}
                    iconProps={{ iconName: "delete", styles: panelCommandButtonStyle }}
                    style={{ paddingTop: 5, paddingBottom: 5, }}
                    text={languageStrings.Delete}
                    onClick={async () => setShowDeleteDialog(true)}
                />

            </Stack>
            {
                isLoading ? <Spinner size={SpinnerSize.large} /> :
                    <>
                        {
                            isCreateMode &&
                            <Stack tokens={{ childrenGap: createTokenGap }} style={{ marginTop: 10 }}>
                                <Dropdown
                                    required
                                    options={datasourceTypeOptions} //Dont show Unive or SQL types
                                    label={languageStrings.SelectDatasourceType}
                                    placeholder={datasourceTypeOptions.length > 0 ? languageStrings.SelectDatasourceType : languageStrings.NoOptionsAvailable}
                                    onChange={(_, option) => onSelectDataSourceType(option)}
                                    disabled={datasourceTypeOptions.length === 0}
                                />
                                {newDatasource?.type?.id !== DatasourceTypeEnum.SharePoint && <TextField
                                    label={languageStrings.DataSourceName}
                                    placeholder={languageStrings.DataSourceName}
                                    onChange={(_, nv) => setNewDatasource(prev => {
                                        return ({
                                            ...prev,
                                            name: nv
                                        })
                                    })}
                                    value={newDatasource.name}
                                    required
                                />}
                                <TextField
                                    label={languageStrings.RestrictToSites}
                                    placeholder={languageStrings.RestrictToSites}
                                    onChange={(_, nv) => setNewDatasource(prev => {
                                        return ({
                                            ...prev,
                                            site: nv
                                        })
                                    })}
                                    value={newDatasource.site}
                                />
                                <TextField
                                    label={languageStrings.RestrictToLibraries}
                                    placeholder={languageStrings.RestrictToLibraries}
                                    onChange={(_, nv) => setNewDatasource(prev => {
                                        return ({
                                            ...prev,
                                            library: nv
                                        })
                                    })}
                                    value={newDatasource.library}
                                />
                                <Stack tokens={{ childrenGap: createTokenGap }} >
                                    {newDatasource?.type?.isUrlRequired &&
                                        <TextField
                                            placeholder={newDatasource?.type?.id !== DatasourceTypeEnum.SharePoint ? languageStrings.EnterUrl : `SharePoint ${languageStrings.SiteUrl}`}
                                            label={newDatasource?.type?.id !== DatasourceTypeEnum.SharePoint ? languageStrings.Url : languageStrings.SiteUrl}
                                            onChange={(_, nv) => setNewDatasource(prev => {
                                                return ({
                                                    ...prev,
                                                    url: newDatasource?.type?.id !== DatasourceTypeEnum.SharePoint ? nv?.trim() : nv?.trim().replace(/^\/+|\/+$/g, "")
                                                })
                                            })}
                                            required
                                            value={newDatasource.url}
                                        />}
                                    {newDatasource?.type?.isKeyRequired &&
                                        <TextField
                                            placeholder={languageStrings.EnterAuthKey}
                                            label={languageStrings.AuthKey}
                                            onChange={(_, nv) => setNewDatasource(prev => {
                                                return ({
                                                    ...prev,
                                                    authKey: nv
                                                })
                                            })}
                                            required
                                            value={newDatasource.authKey}
                                        />}
                                    {newDatasource?.type?.isSecretRequired &&
                                        <TextField
                                            placeholder={languageStrings.EnterAuthSecret}
                                            label={languageStrings.AuthSecret}
                                            onChange={(_, nv) => setNewDatasource(prev => {
                                                return ({
                                                    ...prev,
                                                    authSecret: nv
                                                })
                                            })}
                                            required
                                            value={newDatasource.authSecret}
                                        />}
                                    {newDatasource?.type?.isTokenRequired &&
                                        <Stack>
                                            <Label>{languageStrings.AuthToken}</Label>
                                            <div>
                                                <DefaultButton
                                                    onClick={async () => {
                                                        try {
                                                            const request: IDatasourceAuthDto = {
                                                                name: newDatasource.name,
                                                                tenantId: newDatasource.tenantId,
                                                                typeId: newDatasource.typeId,
                                                                typeName: dsTypes.find(t => t.id === newDatasource.typeId).name,
                                                                credentials: {
                                                                    url: newDatasource.url,
                                                                    clientId: newDatasource.authKey,
                                                                    clientSecret: newDatasource.authSecret,
                                                                }
                                                            }

                                                            const windowName = 'SignInWindow';
                                                            const windowFeatures = 'width=600,height=600,location=no,menubar=no,toolbar=no';
                                                            const windowOrigin = window.location.origin.trimEnd();

                                                            let authUrl: URL = new URL(`${windowOrigin.endsWith("/") ? windowOrigin.slice(0, -1) : windowOrigin}/auth`);
                                                            authUrl.searchParams.append("ds", LZString.compressToEncodedURIComponent(JSON.stringify(request)));

                                                            window.open(authUrl, windowName, windowFeatures);

                                                        } catch (error) {
                                                            ErrorHelper.handle(error);
                                                        }
                                                    }}
                                                    disabled={isConfirgureAuthDisabled}
                                                >
                                                    {languageStrings.Configure}
                                                </DefaultButton>
                                            </div>
                                        </Stack>
                                    }

                                </Stack>
                                {newDatasource?.type?.id === DatasourceTypeEnum.SharePoint && <TextField
                                    label={languageStrings.ListName}
                                    placeholder={`SharePoint ${languageStrings.ListName}`}
                                    onChange={(_, nv) => setNewDatasource(prev => {
                                        return ({
                                            ...prev,
                                            name: nv
                                        })
                                    })}
                                    value={newDatasource.name}
                                    required
                                />}
                                <Stack horizontalAlign="end" horizontal tokens={{ childrenGap: createTokenGap }}>
                                    <PrimaryButton
                                        onClick={async () => await onCreateNewDataSource()}
                                        iconProps={{ iconName: "add" }}
                                        disabled={isCreateDisabled}
                                    >
                                        {languageStrings.Create}
                                    </PrimaryButton>
                                    <DefaultButton
                                        onClick={() => {
                                            setIsCreateMode(false);
                                            setNewDatasource(null);
                                        }}
                                        iconProps={{ iconName: "cancel" }}
                                    >
                                        {languageStrings.Cancel}
                                    </DefaultButton>
                                </Stack>
                            </Stack>}

                        <DatasourceGrid
                            items={tenant.datasources}
                            setSelectedSites={(id) => onSelect(id)}
                            setShouldClearSelection={() => setShouldClearSelection(true)}
                            createMode={isCreateMode}
                            createType={newDatasource?.type ?? null}
                            selectedSite={selectedDatasource}
                        />

                        <Stack horizontal horizontalAlign="end">
                            <PrimaryButton styles={panelActionButtonStyle}
                                iconProps={{ iconName: "Checkmark" }}
                                onClick={async () => {
                                    dismissPanel();
                                }}>
                                {languageStrings.Done}
                            </PrimaryButton>
                        </Stack>
                    </>
            }
            {isEditPanelOpen && <EditDatasourcePanel
                isOpen={isEditPanelOpen}
                dismissPanel={() => setIsEditPanelOpen(false)}
                datasource={datasources?.find(d => d.id == selectedDatasource)}
                tenant={tenant}
                setDatasource={(newDatasource: IDatasource) => {
                    const updatedDs = datasources.map(ds => {
                        if (ds.id != newDatasource.id) return ({ ...ds });
                        return (newDatasource);
                    });
                    setDatasources(updatedDs);
                }}
                structure={structure ?? []}
                datasourceUpdated={(updatedDatasource: IDatasource) => {
                    const updatedDs = datasources.map(ds => {
                        if (ds.id != updatedDatasource.id) return ({ ...ds });
                        return (updatedDatasource);
                    });
                    setTenantDatasource(updatedDatasource.tenantId, updatedDs);
                }}
            />}
            <DeleteDialogMessage />
        </Panel>
    )
}

export default connector(DataSourcePanel)