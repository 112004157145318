import { mergeStyles } from "@fluentui/react"

export const MainViewStyle = mergeStyles({
    paddingLeft: 40,
    width: "100%",
    // paddingRight: 40,
})

export const BottomContainer = mergeStyles({
    height: "calc(100vh - 50px)",
})