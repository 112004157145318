import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const GOOGLE_DLP_API_URL = 'https://dlp.googleapis.com/v2';
const API_KEY = 'AIzaSyDsW4SrSReYinVT7UH9ZR0sOB1FKY7Lx58';

export interface GoogleDlpInfoType {
	description: string;
	displayName: string;
    name: string;
    supportedBy: string[];
	selected?: boolean;
}

export const apiGoogleDlpSlice = createApi({
	reducerPath: 'api-google-dlp',
	baseQuery: fetchBaseQuery({
		baseUrl: GOOGLE_DLP_API_URL,
	}),
	endpoints: (builder) => ({
		getInfoTypes: builder.query<any, void>({
			query: () => ({
				url: `/infoTypes?key=${API_KEY}`
			}),

		}),
	}),
});

export const {
	useLazyGetInfoTypesQuery,
    useGetInfoTypesQuery
} = apiGoogleDlpSlice;
