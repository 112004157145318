import axios, { AxiosRequestConfig } from "axios";
import { AuthHelper } from "./AuthHelper";

export const apiHeaders = {
    key: {
        xDocubirdAuth: "x-docubird-auth"
    },
    value: {
        xDocubirdAuth: "]c4&ql>@P3flVbPM#61bX}d3q<e=$f"
    }
}

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        [apiHeaders.key.xDocubirdAuth]: apiHeaders.value.xDocubirdAuth
    }
});

instance.interceptors.request.use(async (config) => {
    const controller = new AbortController();
    const jwtToken = await AuthHelper.getDocubirdToken();
    if (jwtToken) {
        config.headers["Authorization"] = `Bearer ${jwtToken}`;
    } else {
        controller.abort();
        AuthHelper.signOut();
    }
    return {
        ...config,
        signal: controller.signal
    };
});

export const axiosGet = async (
    url?: string,
    config?: AxiosRequestConfig
) => {
    const res = await instance.get(url, { ...config });
    return res;
}

export const axiosPost = async (
    url: string,
    data: any = null,
    config?: AxiosRequestConfig,
) => {
    const res = await instance.post(url, data, { ...config });
    return res;
}

export const axiosPut = async (
    url: string,
    data: any = null
) => {
    const res = await instance.put(url, data);
    return res;
}

export const axiosDelete = async (
    url: string
) => {
    const res = await instance.delete(url);
    return res;
}