import { IGroup, IColumn } from '@fluentui/react';
import { CommandBarButton, IIconProps, } from '@fluentui/react';
import { LicenseType } from '../../data-structures/enums';
import { ITenantUser, IUserTableItem } from '../../data-structures/interfaces';

export const renderItemColumn = (item: IUserTableItem, index: number, column: IColumn, handlePanelOpen: (content: IUserTableItem) => void, languageStrings: any) => {
    try {
        switch (column.key) {
            case 'licenseType':
                let licenseName: string;
                if (item.licenseType === LicenseType.Expired) licenseName = languageStrings.Expired;
                else if (item.licenseType === LicenseType.Paid) licenseName = languageStrings.Paid;
                else if (item.licenseType === LicenseType.Eval) licenseName = languageStrings.Evaluation;
                else if (item.licenseType === LicenseType.Unassigned) licenseName = languageStrings.Unassigned;
                else if (item.licenseType === LicenseType.Inactive) licenseName = languageStrings.Inactive;
                else licenseName = "-";

                return <span>{licenseName}</span>;
            case 'expiryDate':
                return <span>{item.licenseType === LicenseType.Paid ? "-" : new Date(item.licenseExpiryDate).toLocaleDateString()}</span>;
            case 'dateRegistered':
                return <span>{new Date(item.dateRegistered).toLocaleDateString()}</span>;
            case 'lastActivityDate':
                const lastActivity = item.lastActivityDate && item.lastActivityDate.valueOf() > 0 ? <span>{new Date(item.lastActivityDate).toLocaleDateString()}</span>
                    : <span>{languageStrings.LastActivityNone}</span>;
                return lastActivity;
            case 'edit':
                const editIcon: IIconProps = { iconName: 'Edit' };
                return <span>
                    <CommandBarButton
                        iconProps={editIcon}
                        text={languageStrings.Edit}
                        style={{ backgroundColor: "transparent" }}
                        onClick={() => handlePanelOpen(item)}
                    />
                </span>;
            case 'isActivated':
                return <span>{item.isActivated ? languageStrings.Yes : languageStrings.No}</span>;
            default:
                const fieldContent = item[column.fieldName as keyof IUserTableItem] as string;
                return <span>{!fieldContent ? "-" : fieldContent}</span>;
        }
    } catch (e) {
        console.error(e);
        throw (e)
    }
}

export const getGroups = (items: IUserTableItem[]): IGroup[] => {
    const sortedItems = items.sort((a, b) => (a.tenantName > b.tenantName) ? 1 : ((b.tenantName > a.tenantName) ? -1 : 0));
    const groupNames = new Set(sortedItems.map(item => item.tenantName));
    const occurancesOfGroups = new Array(Array.from(groupNames).length).fill(0);
    sortedItems.forEach((item) => {
        const ind = Array.from(groupNames).findIndex(groupName => groupName === item.tenantName);
        occurancesOfGroups[ind]++;
    })

    const groups: IGroup[] = [];
    let startIndexArray = [];
    Array.from(groupNames).forEach((groupName, index) => {
        groups.push({
            key: `${index}`,
            name: groupName,
            startIndex: index === 0 ? 0 : sum(startIndexArray),
            count: occurancesOfGroups[index],
            level: 0,
        })
        startIndexArray.push(occurancesOfGroups[index]);
    })
    return groups;
}

const sum = (list: number[]) => {
    let s = 0;
    list.forEach(v => s += v);
    return s;
}

export const getData = (users: ITenantUser[], languageStrings: any) => {
    try {
        var tableItems: IUserTableItem[] = users?.map(
            (user) => {
                try {
                    const roleString: string = `${user.isPartner ? languageStrings.Partner : ''}${user.isPartner && user.isTenantAdmin ? ', ' : ''}${user.isTenantAdmin ? languageStrings.TenantAdmin : ''}${!(user.isTenantAdmin || user.isPartner) ? '-' : ''}`
                    return {
                        key: user.id,
                        id: user.id,
                        accountId: user.accountId ?? null,
                        tenantId: user.tenantId,
                        tenantName: user.tenantName,
                        username: user.username,
                        displayName: user.displayName,
                        licenseType: user.licenseType,
                        licenseExpiryDate: user.licenseExpiryDate,
                        licenseExpiryDateValue: user.licenseExpiryDate.valueOf(),
                        dateRegistered: user.dateRegistered,
                        dateRegisteredValue: user.dateRegistered.valueOf(),
                        lastActivityDate: user.lastActivityDate ? user.lastActivityDate : null,
                        lastActivityDateValue: user.lastActivityDate ? user.lastActivityDate?.valueOf() : -1,
                        isTenantAdmin: user.isTenantAdmin ?? false,
                        isPartner: user.isPartner,
                        enableTeams: user.enableTeams,
                        enableSharePoint: user.enableSharePoint,
                        enableOneDrive: user.enableOneDrive,
                        saveOnSend: user.saveOnSend,
                        role: roleString,
                        autoSelectAttachments: user.autoSelectAttachments,
                        autoRemoveMailItemAttachments: user.autoRemoveMailItemAttachments,
                        convertEmailToPDF: user.convertEmailToPDF,
                        enableApprovals: user.enableApprovals,
                        isActivated: user.isActivated,
                        status: user.isActivated ? languageStrings.Active : languageStrings.Deactivated,
                        landingPage: user.landingPage,
                        subscriptionId: user.subscriptionId
                    }
                }
                catch (e) {
                    console.error(e);
                    return null;
                }
            }
        )
        if (!tableItems) return null
        return tableItems;
    } catch (e) {
        console.error(e);
        return null;
    }
}