import * as React from 'react';
import { LanguageContext } from '../../utilities/LocalizationModule';
import {
    Label,
    CommandBar,
    ICommandBarItemProps,
    Stack,
    FontSizes,
    FontWeights,
    ILabelStyles,
    ScrollablePane,
    ScrollbarVisibility,
    mergeStyles,
    MessageBar,
    MessageBarType,
    SearchBox,
} from '@fluentui/react';
import { RootState } from '../../redux';
import { connect, ConnectedProps } from 'react-redux';
import AddSubscriptionPanel from '../panels/AddSubscriptionPanel';
import { assignAllUsersToSubscription, cancelSubscription, getMultiTenantSubscriptions, getPaymentStatus, getTenantSubscriptions, getTenantSubscriptionsPayments, listAvailablePaymentMethods, revokeAllUsersFromSubscription, updateSubscriptionStatus } from '../../utilities/helpers/ApiHelper';
import SubscriptionsList from '../SubscriptionsList';
import SubscriptionsPaymentsList from '../SubscriptionsPaymentsList';
import SelectTenantPanel from '../panels/SelectTenantPanel';
import { AssignSubscriptionRequest, ITenant, ITenantUser, MultiTenantSubscriptionRequest, PaymentStatusResponse, SubscriptionStatusUpdateRequest } from '../../data-structures/interfaces';
import { labelHeadingStyle } from '../../styles/PanelStyle';
import { SubscriptionStatusEnum, SubscriptionTierEnum } from '../../data-structures/enums';
import { getUserLicenseType } from '../../utilities/helpers/UserLicenseTypeHelper';
import { updateUser } from '../../redux/modules/user';
import * as UserTableStyle from "../../styles/UserTableStyle"
import SubscriptionsExportPanel from '../panels/SubscriptionsExportPanel';

export const headingStyle: Partial<ILabelStyles> = {
    root: {
        fontWeight: FontWeights.semibold,
        fontSize: FontSizes.size28,
    }
}

export const tableContainerStyle = mergeStyles({
    height: "calc(100vh - 50px - 52px - 42px - 30px)",
    position: "relative",
})

const mapDispatchToProps = {
    updateUser
}

const mapStateToProps = (state: RootState) => {
    return {
        tenants: state.user.tenants,
        users: state.user.users,
        isGlobalAdmin: state.user.isGlobalAdmin,
        account: state.user.account
    };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface SubscriptionsProps extends PropsFromRedux {
    transactionId?: string;
    defaultTenant?: ITenant;
};

const Subscriptions: React.FC<SubscriptionsProps> = ({
    tenants,
    transactionId,
    users,
    updateUser,
    defaultTenant,
    isGlobalAdmin,
    account
}: SubscriptionsProps) => {

    const languageStrings = React.useContext(LanguageContext);

    const [commandBarItems, setCommandBarItems] = React.useState<ICommandBarItemProps[]>([]);
    const [commandBarFarItems, setCommandBarFarItems] = React.useState<ICommandBarItemProps[]>([]);
    const [isAddSubscriptionPanelOpen, setIsAddSubscriptionPanelOpen] = React.useState(false);
    const [isSelectTenantPanelOpen, setIsSelectTenantPanelOpen] = React.useState(false);
    const [isExportPanelOpen, setIsExportPanelOpen] = React.useState(false);
    const [subscriptions, setSubscriptions] = React.useState<any[]>([]);
    const [payments, setPayments] = React.useState<any[]>([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [paymentMethods, setPaymentMethods] = React.useState<any[]>([]);
    const [selectedTenant, setSelectedTenant] = React.useState<ITenant>(defaultTenant);
    const [filterValue, setFilterValue] = React.useState<string>();

    const [selectedItems, setSelectedItems] = React.useState<any[]>([]);
    const [isBusy, setIsBusy] = React.useState(false);
    const [deselectAllSubscriptions, setDeselectAllSubscriptions] = React.useState(false);

    const [paymentStatus, setPaymentStatus] = React.useState<PaymentStatusResponse>();

    const fetchSubscrptionsAndPayments = async () => {
        const [subscriptionsRes, paymmentRes] = await Promise.all([
            getTenantSubscriptions(selectedTenant.id, languageStrings),
            getTenantSubscriptionsPayments(selectedTenant.id)
        ])

        setSubscriptions(subscriptionsRes.sort((a, b) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime()));
        setPayments(paymmentRes.sort((a, b) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime()));
    }

    const fetchMultiTenantSubscrptions = async () => {
        const request: MultiTenantSubscriptionRequest = {
            tenantIds: tenants.map(x => x.id),
            userProfile: {
                accountId: account.homeAccountId,
                username: account.username
            }
        }

        const multiSubscriptions = await getMultiTenantSubscriptions(request, tenants, languageStrings);

        setSubscriptions(
            multiSubscriptions
                .sort((a, b) => {
                    // Sort by tenantName
                    if (a.tenantName < b.tenantName) return -1;
                    if (a.tenantName > b.tenantName) return 1;

                    // If tenantName is the same, then sort by createdDate
                    return new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime();
                })
        );
    }

    React.useEffect(() => {
        if (Array.isArray(subscriptions) && subscriptions.length > 0 && Array.isArray(selectedItems) && selectedItems.length === 1) {
            const sub = subscriptions.find(x => x.id === selectedItems[0].id);
            if (sub && JSON.stringify(sub) !== JSON.stringify(selectedItems[0])) {
                setSelectedItems([sub]);
            }
        }
    }, [subscriptions, selectedItems]);

    React.useEffect(() => {
        if (tenants?.length === 1) {
            setSelectedTenant(tenants[0])
        }
    }, [tenants]);

    React.useEffect(() => {
        if (transactionId && tenants?.length > 0) {
            (async () => {
                const results = await getPaymentStatus(transactionId);
                const selectedTenant = tenants.find(x => x.id === results.tenantId);
                if (selectedTenant) {
                    setSelectedTenant(selectedTenant);
                }
                setPaymentStatus(results);
            })()
        }
    }, [transactionId, tenants]);

    React.useEffect(() => {
        (async () => {
            setPaymentMethods(await listAvailablePaymentMethods());
        })();
    }, []);

    React.useEffect(() => {
        (async () => {
            if (selectedTenant) {
                setSubscriptions([]);
                fetchSubscrptionsAndPayments();
            } else if (!selectedTenant && tenants?.length > 1 && !transactionId) {
                fetchMultiTenantSubscrptions();
            }
        })();
    }, [selectedTenant, transactionId]);

    React.useEffect(() => {
        (async () => {
            const items: ICommandBarItemProps[] = [];

            if (tenants.length > 1 && selectedTenant) {
                items.push(
                    {
                        key: "alltenants",
                        iconProps: { iconName: "SearchAndApps" },
                        disabled: isBusy,
                        text: `${languageStrings.ShowAll} ${languageStrings.Tenants}`,
                        onClick: () => setSelectedTenant(undefined)
                    }
                )
            }

            if (tenants.length > 1) {
                items.push(
                    {
                        key: "tenant",
                        iconProps: { iconName: "UserSync" },
                        disabled: isBusy,
                        text: selectedTenant ? `${languageStrings.Change} ${languageStrings.Tenant}` : languageStrings.SelectTenant,
                        onClick: () => setIsSelectTenantPanelOpen(true)
                    }
                )
            }

            if (selectedTenant) {
                items.push({
                    key: "add",
                    disabled: !selectedTenant || isBusy,
                    iconProps: { iconName: "Add" },
                    text: languageStrings.AddSubscription,
                    onClick: () => setIsAddSubscriptionPanelOpen(true)
                })
            }

            if (selectedItems.length === 1) {
                items.push(
                    {
                        key: "assignall",
                        iconProps: { iconName: "UserFollowed" },
                        text: languageStrings.AssignAllLicenses,
                        disabled: isBusy ||
                            (selectedItems[0]?.availableLicenses === 0) ||
                            (selectedItems[0] && selectedItems[0].expiryDate && new Date(selectedItems[0].expiryDate) < new Date()),
                        onClick: () => {
                            setIsBusy(true);
                            assignAll(selectedItems[0]);
                        }
                    },
                    {
                        key: "revoke",
                        iconProps: { iconName: "UserRemove" },
                        text: languageStrings.RevokeLicenses,
                        disabled: isBusy || selectedItems[0].assignedLicenses === 0,
                        onClick: () => {
                            setIsBusy(true);
                            revokeAll(selectedItems);
                        }
                    },
                )
            }

            if (selectedItems.length > 0) {
                if (selectedItems.every(x => x.method === "invoice") && (selectedItems.every(x => x.status === SubscriptionStatusEnum.Active) || selectedItems.every(x => x.status === SubscriptionStatusEnum.Suspended))) {

                    const isAllActive = selectedItems.every(x => x.status === SubscriptionStatusEnum.Active);

                    items.push(
                        {
                            key: "suspend",
                            iconProps: { iconName: isAllActive ? "CircleStop" : "Play" },
                            text: isAllActive ? languageStrings.Suspend : languageStrings.Resume,
                            disabled: isBusy,
                            onClick: () => {
                                setIsBusy(true);
                                updateStatus(selectedItems, isAllActive ? SubscriptionStatusEnum.Suspended : SubscriptionStatusEnum.Active);
                            }
                        }
                    )
                }

                items.push(
                    {
                        key: "cancel",
                        iconProps: { iconName: "Cancel" },
                        text: languageStrings.Cancel,
                        disabled: isBusy || selectedItems.every(item => item.status === "canceled" || (selectedItems.every(item => item.tier === SubscriptionTierEnum.Trial && !isGlobalAdmin))),
                        onClick: () => {
                            setIsBusy(true);
                            cancelSubscriptions(selectedItems);
                        }
                    }
                )
            }

            items.push({
                key: "export",
                iconProps: { iconName: "ExcelDocument" },
                text: languageStrings.ExportToExcel,
                onClick: () => { exportFile(); },
            });

            setCommandBarItems(items);

            const farItems: ICommandBarItemProps[] = [
                {
                    key: "Search",
                    onRender: onMenuItem_QuickFilterRender
                },
            ];

            setCommandBarFarItems(farItems);

        })();
    }, [subscriptions, selectedTenant, selectedItems, isBusy, tenants, isGlobalAdmin, subscriptions]);

    const exportFile = () => {
        setIsExportPanelOpen(true);
    };

    const onMenuItem_QuickFilterRender = React.useCallback(() => {
        return <SearchBox
            styles={UserTableStyle.searchBoxStyles}
            disabled={!subscriptions?.length}
            placeholder={languageStrings.Search}
            onSearch={onSearchFilter}
            onChange={onChangeFilter}
            onClear={onClearFilter}
            onEscape={onEscapeFilter}
        />
    }, [subscriptions]);

    const onSearchFilter = async (filterText: string) => {
        setFilterValue(filterText);
    };
    const onChangeFilter = (event?: React.ChangeEvent<HTMLInputElement>, filterText?: string) => {
        setFilterValue(filterText);
    };
    const resetFilterValue = () => {
        setFilterValue(null);
    };
    const onClearFilter = () => {
        resetFilterValue();
    };
    const onEscapeFilter = () => {
        resetFilterValue();
    };

    const revokeAll = async (items: any[]) => {
        try {
            await Promise.all(items.map(async item => {
                await revokeAllUsersFromSubscription(item.id);

                const tenantUsers: ITenantUser[] = users.filter(user => user.subscriptionId === item.id);

                for (let index = 0; index < tenantUsers.length; index++) {
                    const newTenantUser = { ...tenantUsers[index] };
                    newTenantUser.subscriptionId = null;
                    newTenantUser.licenseType = getUserLicenseType(null);
                    updateUser(newTenantUser, true);
                }
            }))
        } catch {

        }

        setIsBusy(false);
        if (selectedTenant) {
            fetchSubscrptionsAndPayments();
        } else if (!selectedTenant && tenants?.length > 1 && !transactionId) {
            fetchMultiTenantSubscrptions();
        }
    }

    const assignAll = async (item: any) => {
        try {
            const request: AssignSubscriptionRequest = {
                tenantId: item.tenantId,
                subscriptionId: item.id
            }
            const updatedUserIds = await assignAllUsersToSubscription(request);

            if (Array.isArray(updatedUserIds) && updatedUserIds.length > 0) {
                const tenantUsers: ITenantUser[] = users?.filter(user => updatedUserIds.includes(user.id)) ?? [];

                for (let index = 0; index < tenantUsers.length; index++) {
                    const newTenantUser = { ...tenantUsers[index] };
                    newTenantUser.subscriptionId = item.id;
                    newTenantUser.licenseType = getUserLicenseType(item);
                    updateUser(newTenantUser, true);
                }
            }
        } catch (error) {
            console.log("error", error)
        }

        setIsBusy(false);
        if (selectedTenant) {
            fetchSubscrptionsAndPayments();
        } else if (!selectedTenant && tenants?.length > 1 && !transactionId) {
            fetchMultiTenantSubscrptions();
        }
    }

    const updateStatus = async (items: any[], status: SubscriptionStatusEnum) => {
        try {
            await Promise.all(items.map(async item => {
                const request: SubscriptionStatusUpdateRequest = {
                    status: status,
                    subscriptionId: item.id
                }

                await updateSubscriptionStatus(request);

                const tenantUsers: ITenantUser[] = users.filter(user => user.subscriptionId === item.id);

                const updatedSubscription = {
                    ...item,
                    status
                }

                for (let index = 0; index < tenantUsers.length; index++) {
                    const newTenantUser = { ...tenantUsers[index] };
                    newTenantUser.licenseType = getUserLicenseType(updatedSubscription);
                    updateUser(newTenantUser, true);
                }
            }))
        } catch (error) {

        }

        setIsBusy(false);
        if (selectedTenant) {
            fetchSubscrptionsAndPayments();
        } else if (!selectedTenant && tenants?.length > 1 && !transactionId) {
            fetchMultiTenantSubscrptions();
        }
    }

    const cancelSubscriptions = async (items: any[]) => {
        await Promise.all(items.map(async item => {
            await cancelSubscription(item.id);
        }))
        setIsBusy(false);
        if (selectedTenant) {
            fetchSubscrptionsAndPayments();
        } else if (!selectedTenant && tenants?.length > 1 && !transactionId) {
            fetchMultiTenantSubscrptions();
        }
    }

    return (
        <>
            {paymentStatus && <div style={{ paddingTop: 15, paddingRight: 30 }}>
                <MessageBar
                    messageBarType={paymentStatus.status === "paid" ? MessageBarType.success : MessageBarType.warning}
                    onDismiss={() => {
                        setPaymentStatus(null);
                    }}
                >
                    {languageStrings.PaymentStatus}: {paymentStatus.status}
                </MessageBar>
            </div>}

            <div style={{ position: "relative" }}>

                <Label styles={headingStyle}>{`${selectedTenant ? `${languageStrings.Subscriptions} (${selectedTenant.friendlyName})` : languageStrings.Subscriptions}`}</Label>
                <CommandBar
                    items={commandBarItems}
                    farItems={commandBarFarItems}
                />
                {(selectedTenant || (tenants?.length > 0 && subscriptions?.length > 0)) && <div className={tableContainerStyle} id="tableContainer">
                    <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                        <Stack tokens={{ childrenGap: 70 }}>
                            {subscriptions?.length > 0 &&
                                <SubscriptionsList
                                    items={subscriptions}
                                    isLoading={isLoading}
                                    itemSelected={(items: any[]) => {
                                        setSelectedItems(items);
                                    }}
                                    deselect={deselectAllSubscriptions}
                                    isMultiTenant={!selectedTenant}
                                    filterText={filterValue}
                                />}
                            {subscriptions?.length > 0 && selectedTenant && <SubscriptionsPaymentsList items={payments} isLoading={isLoading} paymentMethods={paymentMethods} />}
                            {subscriptions?.length === 0 &&
                                <div style={{ textAlign: "center", paddingTop: 20 }}>
                                    <Label styles={labelHeadingStyle}>
                                        {languageStrings.NoSubscriptionsFound}
                                    </Label>
                                </div>}
                        </Stack>
                    </ScrollablePane>
                </div>}

                {isAddSubscriptionPanelOpen &&
                    <AddSubscriptionPanel
                        isOpen={isAddSubscriptionPanelOpen}
                        closePanel={() => setIsAddSubscriptionPanelOpen(false)}
                        paymentMethods={paymentMethods}
                        tenant={selectedTenant}
                        onSuccess={() => {
                            fetchSubscrptionsAndPayments();
                        }}
                    />}
                {isSelectTenantPanelOpen &&
                    <SelectTenantPanel
                        isOpen={isSelectTenantPanelOpen}
                        closePanel={() => setIsSelectTenantPanelOpen(false)}
                        onTenantSelected={setSelectedTenant}
                        tenants={tenants}
                    />}
                {isExportPanelOpen &&
                    <SubscriptionsExportPanel
                        isOpen={isExportPanelOpen}
                        dismissPanel={() => setIsExportPanelOpen(false)}
                    />}
            </div>
        </>

    )
}

export default connector(Subscriptions);