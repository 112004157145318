import React, { useContext } from "react"
import { LanguageContext } from '../../utilities/LocalizationModule';
import { Panel, Stack, PrimaryButton, PanelType, DefaultButton, ColorPicker, IColor, IColorPickerStyles, } from "@fluentui/react"
import { panelActionButtonStyle } from "../../styles/PanelStyle";
import { ITenant } from "../../data-structures/interfaces";

const colorPickerStyles: Partial<IColorPickerStyles> = {
    panel: { padding: 12 },
    root: {
        maxWidth: 352,
        minWidth: 352,
    },
    colorRectangle: { height: 268 },
};

interface ColorPickerPanelProps {
    isOpen: boolean;
    dismissPanel: () => void;
    color?: string;
    tenant: ITenant;
    colorChanged: (color: string) => void;
}

const ColorPickerPanel: React.FunctionComponent<ColorPickerPanelProps> = ({
    dismissPanel,
    isOpen,
    tenant,
    colorChanged
}) => {
    const languageStrings = useContext(LanguageContext);

    const [color, setColor] = React.useState(tenant.appColor || "#ffffff");
    const [showPreview, setShowPreview] = React.useState(true);

    const updateColor = React.useCallback((ev: any, colorObj: IColor) => setColor(colorObj.hex), []);

    const onRenderFooterContent = React.useCallback(
        () => (
            <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 10 }}>
                <DefaultButton styles={panelActionButtonStyle}
                    iconProps={{ iconName: "Cancel" }}
                    onClick={() => {
                        dismissPanel();
                    }}>
                    {languageStrings.Cancel}
                </DefaultButton>
                <PrimaryButton styles={panelActionButtonStyle}
                    iconProps={{ iconName: "Checkmark" }}
                    onClick={async () => {
                        console.log("color", color)
                        colorChanged(`#${color}`);
                        dismissPanel();
                    }}>
                    {languageStrings.Done}
                </PrimaryButton>
            </Stack>
        ),
        [color],
    );

    return (
        <Panel
            isOpen={isOpen}
            type={PanelType.custom}
            customWidth={"500px"}
            onDismiss={() => dismissPanel()}
            headerText={languageStrings.PickColor}
            onRenderFooterContent={onRenderFooterContent}
            isFooterAtBottom={true}
        >
            <Stack tokens={{ childrenGap: 10 }} horizontalAlign="center">
                <ColorPicker
                    color={color}
                    onChange={updateColor}
                    alphaType={"none"}
                    showPreview={showPreview}
                    styles={colorPickerStyles}
                    strings={{
                        hueAriaLabel: 'Hue',
                    }}
                />
            </Stack>

        </Panel>
    )
}

export default ColorPickerPanel;