import { Configuration, RedirectRequest } from "@azure/msal-browser";

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
    auth: {
        clientId: process.env.REACT_APP_CLIENT_ID
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false,
        secureCookies: false
    },
};

export const signInScopes: string[] = [
    "User.Read"
];

export const sitesScopes: string[] = [
    "Sites.Read.All"
];

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const authRequest: RedirectRequest = {
    scopes: signInScopes
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};