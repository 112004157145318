import Cookies from "js-cookie";
const cookieApi = Cookies.withAttributes({ domain: new URL(window.location.origin).host, secure: true });
export class StorageHelper {
    static async setItemAsync(key: string, value: string): Promise<boolean> {
        return await new Promise(resolve => {
            try {
                sessionStorage.setItem(key, value);
                resolve(true);
            } catch (error) {
                try {
                    cookieApi.set(key, value);
                    resolve(true);
                } catch (error) { }
                resolve(false);
            }
        });
    }

    static async getItemAsync(key: string, removeOnGet: boolean = false): Promise<string> {
        const value: string = await new Promise(resolve => {
            try {
                resolve(sessionStorage.getItem(key));
            } catch (error) {
                try {
                    resolve(cookieApi.get(key));
                } catch (error) { }
                resolve(null);
            }
        });

        if (removeOnGet) {
            this.removeItem(key);
        }

        return value;
    }

    static getItem(key: string): string {
        try {
            return sessionStorage.getItem(key);
        } catch (error) {
            try {
                return cookieApi.get(key);
            } catch (error) { }
        }
        return null;
    }

    static async removeItem(key: string): Promise<void> {
        await new Promise<void>(resolve => {
            try {
                sessionStorage.removeItem(key);
            } catch (error) {
                try {
                    cookieApi.remove(key);
                } catch (error) { }
            }
            resolve();
        });
    }
}