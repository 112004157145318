import React, { useMemo } from "react"
import { IDatasource, IDatasourceType, ISite } from "../data-structures/interfaces"
import { DetailsList, ScrollablePane, ScrollbarVisibility, IColumn, SelectionMode, Selection, IObjectWithKey, mergeStyles, Stack, Icon } from '@fluentui/react';
import * as ListStyles from "../styles/ListStyles"
import { LanguageContext } from '../utilities/LocalizationModule';
import simplicateIcon from "../assets/simplicateTransparent.png";
import hordijkIcon from "../assets/hordijk.jpg";
import univeIcon from "../assets/univeTransparent.png";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../redux";

const mapStateToProps = (state: RootState) => {
    return {
        dsTypes: state.datasource.types,
    };
};

const mapDispatchToProps = {

}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface DatasourceGridProps extends PropsFromRedux {
    items?: IDatasource[],
    setSelectedSites: (siteId: number) => void,
    setShouldClearSelection: () => void,
    createType: IDatasourceType,
    createMode: boolean,
    selectedSite: number,
}

const DatasourceGrid: React.FunctionComponent<DatasourceGridProps> = ({
    items,
    setSelectedSites,
    setShouldClearSelection,
    createMode,
    createType,
    selectedSite,
    dsTypes
}) => {
    const languageStrings = React.useContext(LanguageContext);

    const _selection = useMemo(() => new Selection({
        onSelectionChanged: () => handleSelection()
    }), []);

    React.useEffect(() => {
        if (selectedSite) {
            const id = items?.findIndex(item => item.id === selectedSite);
            if (id) {
                _selection.setIndexSelected(id, true, false);
            }
        }
    }, [selectedSite]);

    const handleSelection = () => {
        const selected: IObjectWithKey[] = _selection.getSelection();
        if (selected && selected.length > 0) {
            const ids = selected.map(value => {
                return Number(value["id"])
            }); //has just one value in it, because of single select mode in DetailsList
            setSelectedSites(ids[0]);
        } else {
            setShouldClearSelection();
        }
    }

    const columns: IColumn[] = useMemo(() => {
        return [
            {
                key: 'displayName',
                name: languageStrings.DataSourceName,
                fieldName: 'displayName',
                data: "string",
                minWidth: 150,
                maxWidth: 250,
                isResizable: true,
            },
            {
                key: 'type',
                name: languageStrings.Type,
                fieldName: 'type',
                data: "string",
                minWidth: 120,
                maxWidth: 350,
                isResizable: false,
            },
            {
                key: 'boundFields',
                name: languageStrings.Fields.charAt(0).toUpperCase() + languageStrings.Fields.slice(1),
                fieldName: 'boundFields',
                data: "string",
                minWidth: 50,
                maxWidth: 100,
                isResizable: false,
            },
        ]
    }, []);

    const renderItemColumn = (item: IDatasource, index: number, column: IColumn) => {
        const fieldContent = item[column.fieldName as keyof ISite] as string;
        switch (column.key) {
            case "displayName":
                let icon;
                // switch (item?.type?.id) {
                //     case 1:
                //         icon = <Icon iconName={'TableGroup'} style={{ ...imgStyle, fontSize: 22 }} />
                //         break;
                //     case 2:
                //         icon = <img src={univeIcon} style={imgStyle} />
                //         break;
                //     case 3:
                //         icon = <img src={simplicateIcon} style={imgStyle} />
                //         break;
                //     case 4:
                //         icon = <img src={simplicateIcon} style={imgStyle} />
                //         break;
                //     case 5:
                //         icon = <img src={simplicateIcon} style={imgStyle} />
                //         break;
                //     case 6:
                //         icon = <img src="https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product-fluent/png/sharepoint_32x1.png" style={imgStyle} />
                //         break;
                //     default:
                //         icon = <Icon iconName={'TableGroup'} style={{ ...imgStyle, fontSize: 22 }} />
                //         break;
                // }
                const datasourceType: string = item?.type?.name?.toLowerCase();
                if (datasourceType === "sqlserver") {
                    icon = <Icon iconName={'TableGroup'} style={{ ...imgStyle, fontSize: 22 }} />
                }
                else if (datasourceType === "univecrm") {
                    icon = <img src={univeIcon} style={imgStyle} />
                }
                else if (datasourceType === "sharepoint") {
                    icon = <img src="https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product-fluent/png/sharepoint_32x1.png" style={imgStyle} />
                }
                else if (datasourceType.startsWith("simplicate")) {
                    icon = <img src={simplicateIcon} style={imgStyle} />
                }
                else if (datasourceType.startsWith("hordijk")) {
                    icon = <img src={hordijkIcon} style={imgStyle} />
                }
                else {
                    icon = <Icon iconName={'TableGroup'} style={{ ...imgStyle, fontSize: 22 }} />
                }

                return <Stack horizontal verticalAlign="center">{icon}{item.name}</Stack>
            case "type":
                return <span style={{ display: "flex", alignItems: "center", height: "100%" }}>{dsTypes.find(t => t.id === item.typeId)?.name}</span>
            case "boundFields":
                return <span style={{ display: "flex", alignItems: "center", height: "100%" }}>{item?.boundFields?.length}</span>
            default:
                return <span>{fieldContent}</span>;
        }
    }

    const extraItems = createType == null ? 0 : (createType?.isKeyRequired ? 1 : 0) + (createType?.isUrlRequired ? 1 : 0) + (createType?.isSecretRequired ? 1 : 0);

    const gridStyle = createMode ?
        ListStyles.datasourceGridStyleCreate(extraItems)
        : ListStyles.datasourceGridStyle;

    return (
        <div className={gridStyle} id="datasourceGridContainer">
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                <DetailsList
                    items={items ?? []}
                    onRenderItemColumn={renderItemColumn}
                    columns={columns}
                    selectionMode={SelectionMode.single}
                    selection={_selection}
                    checkboxCellClassName={mergeStyles({
                        display: "flex",
                        alignItems: "center"
                    })}
                />
            </ScrollablePane>
        </div>
    )
}

const imgStyle: React.CSSProperties = {
    maxHeight: "100%",
    maxWidth: "100%",
    height: 30,
    width: 30,
    objectFit: "contain",
    aspectRatio: "1",
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'center',
    justifyContent: 'center',
    justifySelf: 'center',
    marginRight: 15,
}

export default connector(DatasourceGrid)