
import React from 'react';
import { LanguageContext } from '../../utilities/LocalizationModule';
import { Panel, Stack, DefaultButton, Label, CommandBarButton, IIconProps, IColumn, Persona, PersonaSize, PanelType, Text, PrimaryButton, TextField } from "@fluentui/react"
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../redux';
import * as PanelStyle from "../../styles/PanelStyle"
import ViewListPanel from './ViewListPanel';
import { IPartner, IPortalPartner, ITenant, ITenantUser } from '../../data-structures/interfaces';
import DialogMessage from "../DialogMessage"
import { MessageType, PanelReturnType } from '../../data-structures/enums';
import { assignPartnerUsers, deletePartner, updatePartner, removePartnerUsers, assignTenantsAPI, removeTenantsAPI } from '../../utilities/helpers/ApiHelper';
import { assignTenants, editPartner, removeTenants } from '../../redux/modules/user';
import { ITenantPartner } from '../../data-structures/interfaces';

const mapStateToProps = (state: RootState) => {
    return {
        tenants: state.user.tenants,
        users: state.user.users,
    };
};

const mapDispatchToProps = {
    editPartner,
    assignTenants,
    removeTenants
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface DisplayPartnerPanelProps extends PropsFromRedux {
    isOpen: boolean,
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
    partner: IPortalPartner,
    startInViewList?: boolean,
    partnerUpdated?: () => void;
};

const DisplayPartnerPanel: React.FC<DisplayPartnerPanelProps> = ({
    isOpen,
    setIsOpen,
    partner,
    tenants,
    users,
    startInViewList = false,
    partnerUpdated,
    editPartner,
    assignTenants,
    removeTenants
}: DisplayPartnerPanelProps) => {


    const languageStrings = React.useContext(LanguageContext);
    const [isTenantViewListOpen, setIsTenantViewListOpen] = React.useState(startInViewList);
    const [isUserViewListOpen, setIsUserViewListOpen] = React.useState(startInViewList);
    const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
    const [newPartner, setNewPartner] = React.useState<IPortalPartner>();
    const [selectedTenants, setSelectedTenants] = React.useState<ITenant[]>([]);
    const [selectedUsers, setSelectedUsers] = React.useState<ITenantUser[]>([]);

    const [availableUsers, setAvailableUsers] = React.useState<ITenantUser[]>([]);
    const [availableTenants, setAvailableTenants] = React.useState<ITenant[]>([]);

    const [returnType, setReturnType] = React.useState<PanelReturnType>(null);

    const [tenantPersonas, setTenantPersonas] = React.useState<JSX.Element[]>([]);
    const [userPersonas, setUserPersonas] = React.useState<JSX.Element[]>([]);

    const editIcon: IIconProps = {
        iconName: 'Edit', styles: {
            root: {
                color: 'rgb(50, 49, 48)',
            }
        }
    };

    React.useEffect(() => {
        setNewPartner(partner);
        const selectedUserIds = new Set(partner.users.map(item => item.id));
        setAvailableUsers(users.filter(item => !selectedUserIds.has(item.id)));

        const selectedTenantIds = new Set(partner.tenants.map(item => item.id));
        setAvailableTenants(tenants.filter(item => !selectedTenantIds.has(item.id)));
    }, [partner]);

    React.useEffect(() => {
        if (newPartner) {
            setTenantPersonas(newPartner.tenants.map((t, i) => {
                return (
                    <Persona text={t.friendlyName} secondaryText={t.tenantId} size={PersonaSize.size40} key={i} />
                )
            }));

            setUserPersonas(newPartner.users.map((u, i) => {
                return (
                    <Persona text={u.displayName} secondaryText={u.username} size={PersonaSize.size40} key={i} />
                )
            }));

            const selectedUserIds = new Set(newPartner.users.map(item => item.id));
            setAvailableUsers(users.filter(item => !selectedUserIds.has(item.id)));

            const selectedTenantIds = new Set(newPartner.tenants.map(item => item.id));
            setAvailableTenants(tenants.filter(item => !selectedTenantIds.has(item.id)));
        }
    }, [newPartner]);

    React.useEffect(() => {
        (async () => {
            if (returnType === PanelReturnType.Add) {
                const allTenants = [...newPartner.tenants, ...selectedTenants];
                const sortedTenants = allTenants.sort((a, b) => a.friendlyName.localeCompare(b.friendlyName));

                const updatedPartner: IPortalPartner = {
                    ...newPartner,
                    tenants: sortedTenants,
                }

                const reduxPartner: ITenantPartner = {
                    id: newPartner.id,
                    name: newPartner.name
                }

                try {
                    await assignTenantsAPI(updatedPartner.id, updatedPartner.tenants.map(tenant => tenant.id));
                    assignTenants(allTenants, reduxPartner);
                    setNewPartner(updatedPartner);
                } catch (error) {
                    
                }

            }
            if (returnType === PanelReturnType.Remove) {
                const ids = new Set(selectedTenants.map(tenant => tenant.id));
                const updatedPartner = {
                    ...newPartner,
                    tenants: newPartner.tenants.filter(tenant => !ids.has(tenant.id)),
                }

                const reduxPartner: ITenantPartner = {
                    id: newPartner.id,
                    name: newPartner.name
                }
                
                try {
                    await removeTenantsAPI(updatedPartner.id, selectedTenants.map(tenant => tenant.id));
                    setNewPartner(updatedPartner);
                    removeTenants(tenants, reduxPartner);
                } catch (error) {
                    
                }
                
            }
            if (returnType === PanelReturnType.AddPartnerUser) {
                const allUsers = [...newPartner.users, ...selectedUsers];
                const sortedUsers = allUsers.sort((a, b) => a.displayName.localeCompare(b.displayName))
                const updatedPartner = {
                    ...newPartner,
                    users: sortedUsers,
                }
    
                try {
                    await assignPartnerUsers(updatedPartner.id, selectedUsers.map(user => user.id));
                    setNewPartner(updatedPartner);
                } catch (error) {
                    
                }
                
            }
            if (returnType === PanelReturnType.RemovePartnerUser) {
                const ids = new Set(selectedUsers.map(user => user.id));
                const updatedPartner = {
                    ...newPartner,
                    users: newPartner.users.filter(user => !ids.has(user.id)),
                }
    
                setNewPartner(updatedPartner);
                await removePartnerUsers(updatedPartner.id, selectedUsers.map(user => user.id));
            }
    
            if (returnType >= 0 && partnerUpdated) {
                partnerUpdated();
            }
    
            setReturnType(null);
        })();
        
    }, [returnType])

    const columns: IColumn[] = ([
        {
            key: 'friendlyName',
            name: languageStrings.Tenant,
            fieldName: 'friendlyName',
            data: "string",
            minWidth: 100,
            maxWidth: 350,
            isResizable: true,
        },
    ]);

    const userColumns: IColumn[] = ([
        {
            key: 'displayName',
            name: languageStrings.User,
            fieldName: 'displayName',
            data: "string",
            minWidth: 100,
            maxWidth: 350,
            isResizable: true,
        },
    ]);

    const addCallback = () => {
        setReturnType(PanelReturnType.Add);
    }
    const removeCallback = () => {
        setReturnType(PanelReturnType.Remove);
    }

    const renderItemColumn = (item: ITenant, index: number, column: IColumn) => {
        const fieldContent = item[column.fieldName as keyof ITenant] as string;
        switch (column.key) {
            case "friendlyName":
                return <Persona text={item.friendlyName} size={PersonaSize.size40} secondaryText={item.tenantId} />
            default:
                return <span>{fieldContent}</span>;
        }
    }

    const renderUserItemColumn = (item: ITenantUser, index: number, column: IColumn) => {
        const fieldContent = item[column.fieldName as keyof ITenantUser] as string;
        switch (column.key) {
            case "displayName":
                return <Persona text={item.displayName} size={PersonaSize.size40} secondaryText={item.username} />
            default:
                return <span>{fieldContent}</span>;
        }
    }

    const removePartner = async () => {
        await deletePartner(newPartner.id);
        setShowDeleteDialog(false);
        setIsOpen(false);
        partnerUpdated();
    }

    return (
        <Panel
            headerText={languageStrings.Edit + ' ' + languageStrings.Partner}
            isOpen={isOpen}
            isLightDismiss
            onDismiss={() => {
                setIsOpen(false);
            }}
            closeButtonAriaLabel="Close"
            type={PanelType.custom}
            customWidth={"500px"}
        >
            {newPartner && <Stack tokens={{ childrenGap: 20 }}>
                <Stack style={{ marginTop: 15 }}>
                    <Label styles={PanelStyle.labelHeadingStyle}>{languageStrings.Name}</Label>
                    <TextField value={newPartner.name} onChange={(_, nv) => setNewPartner({ ...newPartner, name: nv })} />
                    {/* {partner.tenantId ? <Label disabled styles={PanelStyle.labelInfoStyle}>{partner?.displayName}</Label>
                        : <TextField value={newPartner.displayName} onChange={(_, nv) => setNewPartner({ ...newPartner, displayName: nv })} />} */}
                </Stack>
                <Stack>
                    <Stack horizontal horizontalAlign="space-between">
                        <Label styles={PanelStyle.labelHeadingStyle}>
                            {languageStrings.ManagedTenants}
                        </Label>
                        <CommandBarButton
                            iconProps={editIcon}
                            text={languageStrings.Edit}
                            styles={{
                                root: {
                                    backgroundColor: 'transparent',
                                }
                            }}
                            onClick={() => { setIsTenantViewListOpen(true) }}
                        />
                    </Stack>
                </Stack>

                {tenantPersonas.length > 0 ?
                    tenantPersonas.length > 5 ?
                        <Stack tokens={{ childrenGap: 10 }} style={{ marginTop: 15 }}>
                            {tenantPersonas.slice(0, 5)}
                            <Label onClick={() => { setIsTenantViewListOpen(true) }} styles={{
                                root: {
                                    cursor: "pointer",
                                    width: 100,
                                    selectors: {
                                        ':hover': {
                                            color: 'rgb(16, 110, 190)',
                                            backgroundColor: 'rgb(243, 242, 241)'
                                        }
                                    }
                                }
                            }}>
                                ... and {tenantPersonas.length - 5} others
                            </Label>
                        </Stack>
                        : <Stack tokens={{ childrenGap: 10 }} style={{ marginTop: 15 }}> {tenantPersonas} </Stack>
                    : <Label disabled styles={PanelStyle.labelInfoStyle}>{languageStrings.NoTenants}</Label>}

                <Stack>
                    <Stack horizontal horizontalAlign="space-between">
                        <Label styles={PanelStyle.labelHeadingStyle}>
                            {languageStrings.PartnerUsers}
                        </Label>
                        <CommandBarButton
                            iconProps={editIcon}
                            text={languageStrings.Edit}
                            styles={{
                                root: {
                                    backgroundColor: 'transparent',
                                }
                            }}
                            onClick={() => { setIsUserViewListOpen(true) }}
                        />
                    </Stack>
                </Stack>

                {userPersonas.length > 0 ?
                    userPersonas.length > 5 ?
                        <Stack tokens={{ childrenGap: 10 }} style={{ marginTop: 15 }}>
                            {userPersonas.slice(0, 5)}
                            <Label onClick={() => { setIsUserViewListOpen(true) }} styles={{
                                root: {
                                    cursor: "pointer",
                                    width: 100,
                                    selectors: {
                                        ':hover': {
                                            color: 'rgb(16, 110, 190)',
                                            backgroundColor: 'rgb(243, 242, 241)'
                                        }
                                    }
                                }
                            }}>
                                ... and {userPersonas.length - 5} others
                            </Label>
                        </Stack>
                        : <Stack tokens={{ childrenGap: 10 }} style={{ marginTop: 15 }}> {userPersonas} </Stack>
                    : <Label disabled styles={PanelStyle.labelInfoStyle}>{languageStrings.NoPartnerUsers}</Label>}
                <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 15 }}>
                    
                        <PrimaryButton styles={PanelStyle.panelActionButtonStyle}
                            disabled={partner == null || !(newPartner.name)}
                            iconProps={{ iconName: "save" }}
                            onClick={async () => {
                                await updatePartner(newPartner.id, newPartner.name);
                                partnerUpdated();
                                setIsOpen(false); //close panel
                            }}>
                            {languageStrings.Save}
                        </PrimaryButton>

                        <DefaultButton styles={PanelStyle.panelActionButtonStyle}
                            iconProps={{ iconName: "delete" }}
                            onClick={async () => {
                                setShowDeleteDialog(true);
                            }}>
                            {languageStrings.Delete}
                        </DefaultButton> 
                    
{/*                     
                        <DefaultButton styles={PanelStyle.panelActionButtonStyle}
                            iconProps={{ iconName: "delete" }}
                            onClick={async () => {
                                setShowDeleteDialog(true);
                            }}>
                            {languageStrings.Delete}
                        </DefaultButton> 
                    

                            <DefaultButton styles={PanelStyle.panelActionButtonStyle}
                                iconProps={{ iconName: "cancel" }}
                                onClick={async () => {
                                    setIsOpen(false); //close panel
                                }}>
                                {languageStrings.Cancel}
                            </DefaultButton>
                            
                            <PrimaryButton styles={PanelStyle.panelActionButtonStyle}
                                iconProps={{ iconName: "checkmark" }}
                                onClick={async () => {
                                    setIsOpen(false); //close panel
                                }}>
                                {languageStrings.Ok}
                            </PrimaryButton>
                     */}

                </Stack>                
                {/* <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 15 }}>
                    {
                        !(partner.tenantId) ? <PrimaryButton styles={PanelStyle.panelActionButtonStyle}
                            disabled={partner == null || !(newPartner?.displayName) || !(newPartner?.username)}
                            iconProps={{ iconName: "save" }}
                            onClick={async () => {
                                editPartner(newPartner);
                                setIsOpen(false); //close panel
                            }}>
                            {languageStrings.Save}
                        </PrimaryButton> : null
                    }
                    {
                        !(partner.tenantId) ? <DefaultButton styles={PanelStyle.panelActionButtonStyle}
                            iconProps={{ iconName: "delete" }}
                            onClick={async () => {
                                setShowDeleteDialog(true);
                            }}>
                            {languageStrings.Delete}
                        </DefaultButton> : null
                    }
                    {
                        !(partner.tenantId) ?
                            <DefaultButton styles={PanelStyle.panelActionButtonStyle}
                                iconProps={{ iconName: "cancel" }}
                                onClick={async () => {
                                    setIsOpen(false); //close panel
                                }}>
                                {languageStrings.Cancel}
                            </DefaultButton>
                            :
                            <PrimaryButton styles={PanelStyle.panelActionButtonStyle}
                                iconProps={{ iconName: "checkmark" }}
                                onClick={async () => {
                                    setIsOpen(false); //close panel
                                }}>
                                {languageStrings.Ok}
                            </PrimaryButton>
                    }

                </Stack> */}

            </Stack>}
            {(isTenantViewListOpen || startInViewList) &&
                <ViewListPanel
                    isOpen={isTenantViewListOpen}
                    setIsOpen={setIsTenantViewListOpen}
                    text={languageStrings.AddOrRemoveTenants}
                    addCallback={addCallback}
                    removeCallback={removeCallback}
                    columns={columns}
                    renderItemColumn={renderItemColumn}
                    shortList={newPartner.tenants}
                    title={partner?.name}
                    addTitle={languageStrings.AddTenant}
                    fullList={availableTenants}
                    addText={languageStrings.Add}
                    removeText={languageStrings.Remove}
                    multiSelect={true}
                    indicateNumbers={true}
                    listSetter={setSelectedTenants}
                />}
            {(isUserViewListOpen || startInViewList) &&
                <ViewListPanel
                    isOpen={isUserViewListOpen}
                    setIsOpen={setIsUserViewListOpen}
                    text={languageStrings.AddOrRemovePartnerUsers}
                    addCallback={() => setReturnType(PanelReturnType.AddPartnerUser)}
                    removeCallback={() => setReturnType(PanelReturnType.RemovePartnerUser)}
                    columns={userColumns}
                    renderItemColumn={renderUserItemColumn}
                    shortList={newPartner.users}
                    title={partner?.name}
                    addTitle={languageStrings.AddTenant}
                    fullList={availableUsers}
                    addText={languageStrings.Add}
                    removeText={languageStrings.Remove}
                    multiSelect={true}
                    indicateNumbers={true}
                    listSetter={setSelectedUsers}
                // listSetter={(list: any[]) => {
                //     // const removeIdSet = new Set(list.map(user => user.id));
                //     // const updatedPartner = {
                //     //     ...newPartner,
                //     //     users: newPartner.users.filter(user => !removeIdSet.has(user.id)),
                //     // }

                //     // setNewPartner(updatedPartner);
                //     // removePartnerUsers(updatedPartner.id, list.map(user => user.id));
                // }}
                />}
            {showDeleteDialog ? <DialogMessage
                title={"Delete partner"}
                isHidden={!showDeleteDialog}
                hideDialog={() => setShowDeleteDialog(false)}
                message="Are you sure you want to delete this partner?"
                confirmAction={async () => await removePartner()}
                type={MessageType.Confirmation}
            /> : null}
        </Panel>
    )
}

export default connector(DisplayPartnerPanel);