
import { Stack, Label, FontWeights, CommandBarButton, Toggle } from '@fluentui/react';
import React from 'react';
import { LanguageContext } from '../utilities/LocalizationModule';
import { labelHeadingStyle } from "../styles/PanelStyle"


interface ListToggleSectionProps {
    heading: string,
    hasEditButton: boolean,
    toggleValue: boolean,
    toggleSetterCallback: (newValue: boolean) => void,
    toggleLabel: string,
    numberOfFields: string,
    editButtonCallback?: () => void,
    editButtonText?: string,
    mainStyle?: React.CSSProperties,
};

const ListToggleSection: React.FC<ListToggleSectionProps> = ({
    heading,
    hasEditButton,
    editButtonText,
    editButtonCallback,
    toggleValue,
    toggleSetterCallback,
    toggleLabel,
    numberOfFields = "",
    mainStyle,
}: ListToggleSectionProps) => {
    const languageStrings = React.useContext(LanguageContext);

    return (
        <Stack style={mainStyle}>
            <Stack horizontal horizontalAlign="space-between">
                <Label styles={labelHeadingStyle}>
                    {heading}
                </Label>
                {hasEditButton ?
                    <CommandBarButton
                        disabled={toggleValue}
                        iconProps={{
                            iconName: 'Edit', styles: {
                                root: {
                                    color: 'rgb(50, 49, 48)',
                                }
                            }
                        }}
                        text={editButtonText}
                        styles={{
                            root: {
                                backgroundColor: 'transparent',
                            }
                        }}
                        onClick={() => { editButtonCallback() }}
                    />
                    : null}

            </Stack>

            <Stack horizontal horizontalAlign={toggleValue ? "start" : "space-between"}>
                <Toggle
                    label={toggleLabel}
                    checked={toggleValue}
                    onChange={(_, newValue) => toggleSetterCallback(newValue)}
                    onText={languageStrings.Yes}
                    offText={languageStrings.No}
                />
                {!toggleValue && numberOfFields &&
                    <Label styles={{
                        root: {
                            fontWeight: FontWeights.regular,
                            marginRight: 8
                        }
                    }}>
                        {numberOfFields}
                    </Label>}
            </Stack>
        </Stack>
    )
}

export default ListToggleSection;