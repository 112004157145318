import { IDropdownOption } from "@fluentui/react"
import { GraphType } from "../../data-structures/enums"
import { ITenantUser, ITenantUserActivity } from "../../data-structures/interfaces"
export const dropDownOptions: IDropdownOption[] = [
    {
        key: 8,
        text: "7 Days"
    },
    {
        key: 15,
        text: "14 Days"
    },
    {
        key: 31,
        text: "30 Days"
    },
    {
        key: 61,
        text: "60 Days"
    },
    {
        key: 91,
        text: "90 Days"
    },
    {
        key: 181,
        text: "180 Days"
    },]

const today = new Date();
const todayUTC = new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate())).getTime();

export const getGraphData = (users: ITenantUser[] = [], activites: ITenantUserActivity[] = [], days: number = 30, type: GraphType,) => {
    let tmpData: any = [];
    let datesRange: number[] = [];
    for (let i = 0; i < days; i++) {
        let date = new Date(todayUTC);
        date.setDate(date.getDate() - i);
        datesRange.push(date.getTime());
    }
    datesRange.reverse();

    if (type === GraphType.Registrations) {
        datesRange.forEach(date => {
            const registeredUntilNow = users.filter(u => {
                return new Date(u.dateRegistered).getTime() < date;
            })?.length;

            tmpData.push({
                date: date,
                Registrations: registeredUntilNow ?? 0,
            });
        });
    } else if (type === GraphType.UserActivity) {
        datesRange.forEach(date => {
            const activitiesToday = activites.find(activity => {
                let activityDate = new Date(activity.date);
                let activityDateUTC = new Date(Date.UTC(activityDate.getFullYear(), activityDate.getMonth(), activityDate.getDate()));
                return activityDateUTC.getTime() === date;
            });

            tmpData.push({
                date: date,
                numberOfUsers: activitiesToday ? activitiesToday.userCount : 0
            });
        });
    }
    return tmpData;
}