
import { Label, Separator, Stack, Dialog, DialogFooter, PrimaryButton, DefaultButton, IModalProps, mergeStyles, DialogType, TextField } from '@fluentui/react';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../redux';
import { LanguageContext } from '../../utilities/LocalizationModule';
import * as OverviewStyles from "../../styles/OverviewStyles"
import { updateRequest as updateRequestRedux } from "../../redux/modules/requests"
import { updateUser, assignTenant } from "../../redux/modules/user"
import RequestCard from "../RequestCard"
import { IPortalTenantRequestFeedback, IStyledRequest } from '../../data-structures/interfaces';
import { getShuffledColors, ICardColor } from '../../styles/ColorList';
import * as CardTypeLabelStyle from "../../styles/RequestStyles"


function getContrastYIQ(hexcolor: string): string {
    var r = parseInt(hexcolor.slice(1).substr(0, 2), 16);
    var g = parseInt(hexcolor.slice(1).substr(2, 2), 16);
    var b = parseInt(hexcolor.slice(1).substr(4, 2), 16);
    var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    return (yiq >= 128) ? 'black' : 'white';
}

const mapDispatchToProps = {
    updateRequestRedux,
    updateUser,
    assignTenant
}

const mapStateToProps = (state: RootState) => {
    return {
        requests: state.requests.requests,
        partners: state.user.users.filter(u => u.isPartner),
        tenants: state.user.tenants,
    };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface RequestProps extends PropsFromRedux { };

const Requests: React.FC<RequestProps> = ({
    requests,
    updateRequestRedux,
    partners,
    updateUser,
    assignTenant,
    tenants
}: RequestProps) => {
    const languageStrings = React.useContext(LanguageContext);
    const [showDialog, setShowDialog] = React.useState(false);
    const [isAccepted, setIsAccepted] = React.useState(false);
    const [shuffledColorList] = React.useState<ICardColor[]>(getShuffledColors(requests?.length));
    // const [reason, setReason] = React.useState<string>("");
    const [selectedRequest, setSelectedRequest] = React.useState<IStyledRequest>(null);
    const rejectRequest = (id: number, reason: string) => {
        const feedback: IPortalTenantRequestFeedback = {
            isApproved: false,
            portalTenantRequestId: id,
            reason: reason
        }
        updateRequestRedux(feedback); //thunk 
    };
    const acceptRequest = (id: number) => {
        const feedback: IPortalTenantRequestFeedback = {
            isApproved: true,
            portalTenantRequestId: id,
            reason: null
        }
        const newTenant = tenants.find(t => t.id == selectedRequest?.tenant.id);

        const updatedTenant = {...newTenant, partner: selectedRequest.partner};
        assignTenant(updatedTenant);
        updateRequestRedux(feedback); //thunk 
    };

    React.useEffect(() => {
        if (selectedRequest) acceptRequest(selectedRequest.id)
    }, [isAccepted])

    const dialogContentProps = {
        type: DialogType.normal,
        title: "Reject request",
        closeButtonAriaLabel: 'Close',
        subText: "Please provide a reason for rejecting this request",
    };
    const modalProps: IModalProps = {
        isBlocking: false,
        styles: dialogStyles,
        dragOptions: undefined,
        overlay: {
            className: mergeStyles({
                "transition": "all ease 1s"
            }),
        }
    };
    const pendingRequests = requests.filter(r => r.isApproved == null).map((r, i) => {
        const styledReq: IStyledRequest = {
            ...r,
            color: shuffledColorList[i].color,
            textColor: getContrastYIQ(shuffledColorList[i].color),
        }
        return (
            <RequestCard
                request={styledReq}
                key={i}
                acceptCallback={() => {
                    setSelectedRequest(styledReq);
                    setIsAccepted(true);
                }}
                openDialog={() => {
                    setSelectedRequest(styledReq);
                    setShowDialog(true)
                }}
            />
        )
    });


    const DialogMessage: React.FC<{ rejectCallback: (reason: string) => void }> = ({ rejectCallback }) => {
        const [rejectionMessage, setRejectionMessage] = React.useState("");
        return (
            <div>
                <Dialog
                    hidden={!showDialog}
                    onDismiss={() => setShowDialog(false)}
                    dialogContentProps={dialogContentProps}
                    modalProps={modalProps}
                >
                    <TextField multiline resizable={false} value={rejectionMessage} onChange={(_, nv) => {
                        setRejectionMessage(nv)
                    }} />
                    <DialogFooter>
                        <PrimaryButton onClick={async () => {
                            await rejectCallback(rejectionMessage);
                            setShowDialog(false)
                        }}>
                            Confirm
                        </PrimaryButton>
                        <DefaultButton onClick={() => {
                            setShowDialog(false)
                        }} text="Cancel" />
                    </DialogFooter>
                </Dialog>
            </div>
        )
    };

    return (
        <div style={{ position: "relative", marginRight: 30 }}>
            <Label styles={OverviewStyles.headingStyle}>{languageStrings.Requests}</Label>
            <Separator className={OverviewStyles.divider} />
            <Stack horizontal tokens={{ childrenGap: pendingRequests.length > 0 ? 15 : 0 }} wrap>
                {pendingRequests.length > 0 ? pendingRequests
                    :
                    <Label styles={CardTypeLabelStyle.NoCardsLabel}>
                        {languageStrings.NoPendingRequests}
                    </Label>}
            </Stack>
            <DialogMessage rejectCallback={(reason) => rejectRequest(selectedRequest.id, reason)} />
        </div>
    )
}



const dialogStyles = { main: { maxWidth: 450 } };




export default connector(Requests);