
import React from 'react';
import { LanguageContext } from '../../utilities/LocalizationModule';
import { Panel, Stack, DefaultButton, Label, PanelType, TextField, PrimaryButton, Spinner, SpinnerSize } from "@fluentui/react"
import { connect, ConnectedProps } from 'react-redux';
import * as PanelStyle from "../../styles/PanelStyle"
import { IPartner, IPortalPartner, ITenantUser } from '../../data-structures/interfaces';
import { setLoading } from "../../redux/modules/user"
import { RootState } from '../../redux';
import DisplayPartnerPanel from "./DisplayPartnerPanel"
import { createPartner } from '../../utilities/helpers/ApiHelper';

const mapStateToProps = (state: RootState) => {
    return {
        isLoading: state.user.isUserLoading,
        users: state.user.users,
        tenants: state.user.tenants,
    }
}

const mapDispatchToProps = {
    //createPartner,
    setLoading
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface CreatePartnerPanelProps extends PropsFromRedux {
    isOpen: boolean,
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
    onSaveSuccess: () => void;
};

const initPortalPartner: IPortalPartner = {
    id: -1,
    name: ""
}

const CreatePartnerPanel: React.FC<CreatePartnerPanelProps> = ({
    isOpen,
    setIsOpen,
    //createPartner,
    setLoading,
    isLoading,
    users,
    tenants,
    onSaveSuccess
}: CreatePartnerPanelProps) => {
    const languageStrings = React.useContext(LanguageContext);
    const [partner, setPartner] = React.useState<IPortalPartner>(initPortalPartner)
    const [isSaveDisabled, setIsSaveDisabled] = React.useState<boolean>(true);
    const [createdPartner, setCreatedPartner] = React.useState<IPortalPartner>(null);
    const [updatedPartner, setUpdatedPartner] = React.useState<IPortalPartner>(null);
    const [isDisplayOpen, setIsDisplayOpen] = React.useState<boolean>(true);

    const validateDisplayname = (value: string): string => {
        const isValid: boolean = value != "";
        return isValid ? '' : "Please enter a friendly name";
    }
    // const emailValidation = (value: string): string => {
    //     const regex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //     const isValid: boolean = regex.test(value)
    //     if (isValid) {
    //         const emailExists: boolean = users.map(u => u.username.toLocaleLowerCase()).includes(value.toLocaleLowerCase());
    //         return emailExists ? "Email address already exists" : ""
    //     }
    //     else {
    //         return "Please enter a valid email address";
    //     }

    // }

    const isDirty = () => {
        const er = validateDisplayname(partner.name);
        if (er == '') {
            setIsSaveDisabled(false)
        } else setIsSaveDisabled(true)
    }

    // React.useEffect(() => {
    //     if (createdPartner != null) {
    //         const partners: IPartner[] = users
    //             .filter(u => u.isPartner)
    //             .map<IPartner>(p => {
    //                 return ({
    //                     ...p,
    //                     profile: { tenants: tenants.filter(t => t.partner?.id == p.id) }
    //                 })
    //             });
    //         setUpdatedPartner(partners[partners.length - 1]);
    //         setIsDisplayOpen(true)
    //     }
    // }, [users])

    return (
        <Panel
            headerText={languageStrings.AddPartner}
            isOpen={isOpen}
            isLightDismiss
            onDismiss={() => {
                setIsOpen(false);
            }}
            closeButtonAriaLabel="Close"
            type={PanelType.custom}
            customWidth={"500px"}
        >
            <Stack tokens={{ childrenGap: 20 }} style={{ marginTop: 20 }}>
                <Stack>
                    <Label styles={PanelStyle.labelHeadingStyle}>{languageStrings.Name}</Label>
                    <TextField
                        onChange={(_, nv) => { setPartner({ ...partner, name: nv }) }}
                        required
                        value={partner.name}
                        validateOnLoad={false}
                        validateOnFocusOut={true}
                        onGetErrorMessage={validateDisplayname}
                        onBlur={isDirty}
                    />
                </Stack>
                {/* <Stack>
                    <Label styles={PanelStyle.labelHeadingStyle}>{languageStrings.Username}</Label>
                    <TextField
                        onChange={(_, nv) => { setPartner({ ...partner, username: nv }) }}
                        required
                        value={partner.username}
                        validateOnLoad={false}
                        validateOnFocusOut={true}
                        onGetErrorMessage={emailValidation}
                        onBlur={isDirty}
                    />
                </Stack> */}

                <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 15 }}>
                    <PrimaryButton styles={PanelStyle.panelActionButtonStyle}
                        iconProps={{ iconName: "Save" }}
                        disabled={isSaveDisabled}
                        onClick={async () => {
                            setLoading(true);
                            await createPartner(partner); //api tredux thunk
                            onSaveSuccess();
                            setLoading(false);
                            // //cleanup the panel
                            // setCreatedPartner({ ...partner, profile: { tenants: [] } });
                            // setPartner(initTenantUser);
                            // setIsSaveDisabled(true);
                        }}>
                        {languageStrings.Save}
                        {
                            isLoading && <Spinner size={SpinnerSize.small} style={{ marginLeft: 15 }} />
                        }
                    </PrimaryButton>
                    <DefaultButton styles={PanelStyle.panelActionButtonStyle}
                        iconProps={{ iconName: "cancel" }}
                        onClick={async () => {
                            setIsOpen(false); //close panel
                        }}>
                        {languageStrings.Cancel}
                    </DefaultButton>
                </Stack>
                {updatedPartner && <DisplayPartnerPanel isOpen={isDisplayOpen} setIsOpen={setIsDisplayOpen} partner={updatedPartner} startInViewList />}
            </Stack>
        </Panel>
    )
}

export default connector(CreatePartnerPanel);