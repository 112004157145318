import { FontSizes, FontWeights, IDetailsListStyles, ILabelStyles, ISearchBoxStyles, getTheme, mergeStyles } from "@fluentui/react"

const theme = getTheme();

export const headingStyle: Partial<ILabelStyles> = {
    root: {
        fontWeight: FontWeights.semibold,
        fontSize: FontSizes.size28,
    }
}

export const tableContainerStyle = mergeStyles({
    height: "calc(100vh - 50px - 52px - 42px - 30px)",
    position: "relative",
})

export const commandBar = mergeStyles({
    marginLeft: 0,
    marginRight: 0,
})

export const detailListStyles: Partial<IDetailsListStyles> = {
    root: {
        margin: "0 0 0 0",
        padding: "0px",
        MsOverflowStyle: "-ms-autohiding-scrollbar"
    },
    headerWrapper: {
        paddingTop: 0
    }
};

export const searchBoxStyles: Partial<ISearchBoxStyles> = {
    root: {
        width: "400px",
        borderColor: theme.palette.themeDarkAlt,
        marginRight: 20,
    }
};

export const pageContainer = mergeStyles({
    height: "calc(100vh - 110px)",
    width: "calc(100vw - 208px - 1px - 80px)"
})