class IEmailMapper {
    public contentType: string = "Email, E-mail";
    public subject: string = "Subject, EmailSubject";
    public from: string = "EmailFrom, From, EmailSender";
    public date: string = "Date, EmailDate";
    public received: string = "EmailReceived, Received";
    public to: string = "To, EmailTo";
    public bcc: string = "";
    public cc: string = "EmailCc, Cc";
    public categories: string = "Categories";
    public conversationIndex: string = "ConversationIndex, ConversationID";
    //public conversationTopic: string = "ConversationTopic";
    public attachments: string = "Attachments, HasAttachments, WithAttachments";
    //public inReplyTo: string = "InReplyTo, EmailInReplyTo, In-Reply-To";
    public messageId: string = "MessageID";
    //public references: string = "References, EmailReferences";
    public replyTo: string = "ReplyTo, EmailReplyTo, Reply-To";
    public importance: string = "Importance";
    public originalSubject: string = "OriginalSubject";
}

export const emailMappingTypes = new IEmailMapper();

interface IEmailColumn {
    name: string, //name is the email property attribute name obtained from the mailbox item
    value: string, //value is the corresponding mapping to a sharepoint column
}

export interface IMappedValue {
    value: any,
    type: string,
}

interface ISpFieldsPerMailCategory {
    category: string,
    fields: string[],
}

class EmailMapper extends IEmailMapper {
    public fieldColumnMap: IEmailColumn[] = []; //the column names we will search for when mapping email contents to meta data fields
    private exchangeEmail: any = null;
    constructor() {
        super();
        const keys = Object.keys(emailMappingTypes);
        this.fieldColumnMap = keys.map(key => {
            return ({
                name: key,
                value: this[key],
            })
        });
    }

    public getColumnValue = (columnName: string): string => {
        if (this.fieldColumnMap) {
            const index = this.fieldColumnMap.findIndex(f => f.name.toLowerCase() === columnName.toLowerCase());
            //returns -1 if no value has been saved.
            return index >= 0 ? this.fieldColumnMap[index].value : null;
        }
        return null;
    }

    public emailTypeIconNameResolver = (mailPropertyName: string) => {
        switch (mailPropertyName.toLowerCase()) {
            case "date":
                return "DateTime";
            case "received":
                return "DateTime";
            case "attachments":
                return "CheckboxComposite";
            default:
                return "TextField";
        }
    }

    public emailMappingCategoryNameResolver = (languageStrings: any, key: string): string => {
        let translation: string;
        switch (key) {
            case "contentType":
                translation = languageStrings.ContentType ?? "No localization contentType";
                break;
            case "subject":
                translation = languageStrings.Subject ?? "No localization subject";
                break;
            case "from":
                translation = languageStrings.From ?? "No localization from";
                break;
            case "date":
                translation = languageStrings.Date ?? "No localization date";
                break;
            case "received":
                translation = languageStrings.Received ?? "No localization received";
                break;
            case "to":
                translation = languageStrings.To ?? "No localization to";
                break;
            case "bcc":
                translation = languageStrings.Bcc ?? "No localization bcc";
                break;
            case "cc":
                translation = languageStrings.Cc ?? "No localization cc";
                break;
            case "categories":
                translation = languageStrings.Categories ?? "No localization categories";
                break;
            case "conversationIndex":
                translation = languageStrings.ConversationIndex ?? "No localization conversationIndex";
                break;
            case "conversationTopic":
                translation = languageStrings.ConversationTopic ?? "No localization conversationIndex";
                break;
            case "attachments":
                translation = languageStrings.Attachments ?? "No localization attachments";
                break;
            case "inReplyTo":
                translation = languageStrings.InReplyTo ?? "No localization inReplyTo";
                break;
            case "messageId":
                translation = languageStrings.MessageId ?? "No localization messageId";
                break;
            case "references":
                translation = languageStrings.References ?? "No localization references";
                break;
            case "replyTo":
                translation = languageStrings.ReplyTo ?? "No localization replyTo";
                break;
            case "importance":
                translation = languageStrings.Importance ?? "No localization importance";
                break;
            case "originalSubject":
                translation = languageStrings.OriginalSubject ?? "No localization originalSubject";
                break;
            default:
                translation = "Invalid type.";
                break;
        }
        return translation[0].toUpperCase() + translation.substring(1);
    }

    public setColumnNameValue = (columnName: string, value: string) => {
        this.fieldColumnMap[this.fieldColumnMap.findIndex(f => f.name === columnName)].value = value;
    }

    public getEmailMappingString = () => {
        return JSON.stringify(this.fieldColumnMap);
    }

    public setMapping = (jsonMappingString: string) => {
        if (jsonMappingString) {
            const mappingObject = JSON.parse(jsonMappingString);
            this.fieldColumnMap = mappingObject;
        }
    }

    public resetMap = () => {
        let mappingTypes = new IEmailMapper();
        const keys = Object.keys(mappingTypes);
        this.fieldColumnMap = keys.map(key => {
            return ({
                name: key,
                value: this[key],
            })
        });
    }
}

const emailMapper = new EmailMapper();
export default emailMapper;