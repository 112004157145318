
import React from 'react';
import { LanguageContext } from '../../utilities/LocalizationModule';
import { Panel, Stack, PanelType, PersonaSize, Persona, IIconProps, ISearchBoxStyles, SearchBox } from "@fluentui/react"
import { ITenant } from '../../data-structures/interfaces';

const searchIcon: IIconProps = { iconName: "Search" };
const searchBoxStyles: Partial<ISearchBoxStyles> = {
    root: { height: "100%", marginBottom: 10, paddingBottom: 5 }
};

interface ISelectTenantPanelProps {
    isOpen: boolean,
    closePanel: () => void;
    onTenantSelected: (tenant: ITenant) => void;
    tenants: ITenant[]
};

const SelectTenantPanel: React.FC<ISelectTenantPanelProps> = ({
    isOpen,
    closePanel,
    onTenantSelected,
    tenants
}: ISelectTenantPanelProps) => {
    const languageStrings = React.useContext(LanguageContext);

    const [filter, setFilter] = React.useState("");

    const onChangeFilter = React.useCallback(async (event?: React.ChangeEvent<HTMLInputElement>, newValue?: string) => {
        setFilter(newValue)
    }, []);

    return (
        <Panel
            headerText={languageStrings.SelectTenant}
            isOpen={isOpen}
            isLightDismiss
            onDismiss={() => {
                closePanel();
            }}
            closeButtonAriaLabel="Close"
            type={PanelType.custom}
            customWidth={"500px"}
        >
            <Stack style={{ marginTop: 20 }}>
                <SearchBox
                    styles={searchBoxStyles}
                    placeholder={languageStrings.FilterTenants}
                    iconProps={searchIcon}
                    underlined={true}
                    onChange={onChangeFilter}
                    showIcon={true}
                />
                <Stack>
                    {tenants.filter(x => x.friendlyName.toLowerCase().includes(filter.toLowerCase()) || x.tenantId.toLowerCase().includes(filter.toLowerCase())).map((tenant, index) => {
                        return (
                            <div key={index} className='tenantItem' onClick={() => { onTenantSelected(tenant); closePanel(); }}>
                                <Persona
                                    text={tenant.friendlyName}
                                    secondaryText={tenant.tenantId}
                                    size={PersonaSize.size40}
                                />
                            </div>
                        )
                    })}
                </Stack>
            </Stack>

        </Panel>
    )
}

export default SelectTenantPanel;