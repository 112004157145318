
import React from 'react';
import {
    DocumentCard,
    DocumentCardActivity,
    IDocumentCardActivityPerson,
    mergeStyles,
    mergeStyleSets,
    Label,
    FontSizes,
    FontWeights,
    Stack,
    getTheme,
} from "@fluentui/react"
import { ITenant, IStyledTenant, ITenantUser } from '../data-structures/interfaces';
import { LanguageContext } from '../utilities/LocalizationModule';
import { RootState } from '../redux';
import { connect, ConnectedProps } from 'react-redux';

const theme = getTheme();

const mapDispatchToProps = {
}


const mapStateToProps = (state: RootState) => {
    return {
        isPartner: state.user.isPartner,
    };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
interface TenantCardProps extends PropsFromRedux {
    tenant: IStyledTenant,
    index: number,
    users: ITenantUser[],
    setTenantToEdit: React.Dispatch<React.SetStateAction<ITenant>>,
    setIsEditPanelOpen: React.Dispatch<React.SetStateAction<boolean>>,
};

const TenantCard: React.FC<TenantCardProps> = ({
    tenant,
    index,
    users,
    setTenantToEdit,
    setIsEditPanelOpen,
    isPartner
}: TenantCardProps) => {
    const languageStrings = React.useContext(LanguageContext);
    const [myUsers, setMyUsers] = React.useState<ITenantUser[]>([]);
    const [adminUsers, setAdminUsers] = React.useState<ITenantUser[]>([]);
    const [people, setPeople] = React.useState<IDocumentCardActivityPerson[]>([]);
    React.useEffect(() => {
        const tenantUsers: ITenantUser[] = users.filter(user => user.tenantId == tenant.tenantId);
        setMyUsers(tenantUsers);
        setAdminUsers(tenantUsers.filter(u => u.isTenantAdmin));
        setPeople(tenantUsers.map(user => ({
            name: user.displayName,
            profileImageSrc: '',
            initials: user.displayName.split(" ").map((n) => n[0]).join("")
        })));
    }, [users, tenant]);
    const styles = mergeStyleSets({
        headerContainerStyle: {
            backgroundColor: tenant.color,
            height: 60,
            cursor: "pointer",
        },
        headerLabel: {
            fontSize: FontSizes.xLargePlus,
            fontWeight: FontWeights.semibold,
            color: tenant.textColor,
            marginLeft: 16,
            cursor: "pointer",
        },
        secondaryLabel: {
            fontSize: FontSizes.mediumPlus,
            fontWeight: FontWeights.regular,
            color: theme.palette.neutralDark,
            marginLeft: 16,
            cursor: "pointer",
        },
        infoLabel: {
            fontSize: FontSizes.medium,
            fontWeight: FontWeights.semilight,
            color: theme.palette.neutralPrimary,
            marginLeft: 16,
            cursor: "pointer",
        },
        guid: {
            fontSize: 14,
            fontWeight: FontWeights.light,
            color: theme.palette.neutralTertiary,
            marginLeft: 16,
            cursor: "pointer",
        },
        cardContainer: {
            display: 'inline-block',
            width: 320,
            cursor: "pointer",
        },
    });
    const conversationTileClass = mergeStyles({ height: isPartner ? 190 : 220 });
    const getTenantLastActivity = (lastActivityUnixNumber: number): string => {
        if (lastActivityUnixNumber > 0) {
            return new Date(lastActivityUnixNumber).toLocaleDateString();
        }
        return "---";
    };

    return (
        <DocumentCard
            className={styles.cardContainer}
            onClick={() => {
                setIsEditPanelOpen(true)
                setTenantToEdit(tenant)
            }}
        >
            <Stack className={styles.headerContainerStyle} verticalAlign="center">
                <Label className={styles.headerLabel}>{tenant.friendlyName}</Label>
            </Stack>
            <Stack className={conversationTileClass}>
                <Label className={styles.secondaryLabel}>Tenant Details</Label>
                <Label className={styles.guid}>{`ID: ${tenant.tenantId}`}</Label>
                {!isPartner ? <Label className={styles.infoLabel}>{languageStrings.Partner}: {tenant.partner ? tenant.partner.name : languageStrings.NoPartner}</Label> : null}
                <Label className={styles.infoLabel}>{`${languageStrings.DateRegistered}: ${tenant.dateRegistered ? new Date(tenant.dateRegistered).toLocaleDateString() : "---"}`}</Label>
                <Label className={styles.infoLabel}>{`${languageStrings.LastActivity}: ${getTenantLastActivity(Math.max(...myUsers.map(u => u.lastActivityDate?.valueOf())))}`}</Label>
                <Label className={styles.infoLabel}>{`${languageStrings.NumberOfUsers}: ${myUsers.length}`}</Label>
            </Stack>
            <DocumentCardActivity activity={`${adminUsers.length} ${languageStrings.TenantAdmin}${adminUsers.length == 0 || adminUsers.length > 1 ? 's' : ''}`} people={people} />
        </DocumentCard>
    )
}

export default connector(TenantCard);