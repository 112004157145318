import { PrimaryButton, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import React from 'react'
import { RootState } from '../redux';
import { useSelector } from 'react-redux';
import { CreateSubscriptionRequest } from '../data-structures/interfaces';
import { createSubscription } from '../utilities/helpers/ApiHelper';
import { SubscriptionTierEnum } from '../data-structures/enums';
import { LanguageContext } from '../utilities/LocalizationModule';

interface IMollieCardProps {
    priceToPay: string;
    interval: number;
    lisenceCount: number;
    tenantId: number;
}

const MollieCard = ({ priceToPay, interval, lisenceCount, tenantId }: IMollieCardProps) => {
    const languageStrings = React.useContext(LanguageContext);

    const account = useSelector((state: RootState) => state.user.account);

    const [buttonDisabled, setButtonDisabled] = React.useState(true);

    const [isCreatingPayment, setIsCreatingPayment] = React.useState(false);

    const mollie = React.useRef(null);
    const card = React.useRef(null);

    React.useEffect(() => {
        // Check if mollie components have been initialized
        if (mollie.current) {
            return;
        }

        mollie.current = window.Mollie(process.env.REACT_APP_MOLLIE_PROFILE_ID,
            process.env.REACT_APP_MOLLIE_TESTMODE === "true" ?
                {
                    locale: "nl_NL",
                    testmode: "test"
                }
                : {
                    locale: "nl_NL",
                });

        var options = {
            styles: {
                base: {
                    color: 'rgb(96, 94, 92)',
                    fontSize: '14px',
                    '::placeholder': {
                        color: 'rgb(96, 94, 92)',
                    }
                },
                valid: {
                    color: 'rgb(96, 94, 92)',
                }
            }
        };

        // Create Mounting objects
        card.current = mollie.current.createComponent("card", options);

        // Mount objects
        card.current.mount("#card");
    }, []);

    const disableForm = () => {
        setButtonDisabled(true);
    };

    const enableForm = () => {
        setButtonDisabled(false);
    };

    const submit = async (event) => {
        event.preventDefault();
        disableForm();

        const { token, error } = await mollie.current.createToken();

        if (error) {
            enableForm();
            return;
        }

        setIsCreatingPayment(true);

        const request: CreateSubscriptionRequest = {
            tenantId,
            accountEmail: account.username,
            method: "creditcard",
            interval,
            lisenceCount,
            cardToken: token,
            tier: SubscriptionTierEnum.Paid
        }

        const paymentResult = await createSubscription(request);

        if (paymentResult) {
            window.location.href = paymentResult;
        }

        setIsCreatingPayment(false);
    };

    return (
        <form method="post" className="form" id="mcForm" onSubmit={submit}>
            <div ref={card} id="card"></div>

            <PrimaryButton
                id="submit-button"
                tabIndex={4}
                className="submit-button"
                type="submit"
                text={`${languageStrings.AddSubscription} (€${priceToPay})`}
                style={{width: "100%"}}
            >
                {isCreatingPayment && <Spinner size={SpinnerSize.small} style={{ paddingRight: 15 }} />}
            </PrimaryButton>
            <div id="form-error" className="form-error" role="alert"></div>
        </form>
    )
}

export default MollieCard