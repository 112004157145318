import React, { useMemo } from "react"
import { IDocumentLibrary, ISite } from "../data-structures/interfaces"
import { DetailsList, ScrollablePane, ScrollbarVisibility, IColumn, SelectionMode, Selection, IObjectWithKey, PersonaSize, Persona, mergeStyles } from '@fluentui/react';
import * as ListStyles from "../styles/ListStyles"
import { LanguageContext } from '../utilities/LocalizationModule';
import { useEffect } from "react";


interface SitesGridProps {
    items?: ISite[],
    setSelectedSites: (siteId: number[]) => void,
    filterText: string,
    setShouldClearSelection: () => void
}

const SitesGrid: React.FunctionComponent<SitesGridProps> = ({
    items,
    setSelectedSites,
    filterText,
    setShouldClearSelection
}) => {
    const languageStrings = React.useContext(LanguageContext);

    const _selection = useMemo(() => new Selection({
        onSelectionChanged: () => handleSelection()
    }), []);

    const handleSelection = () => {
        const selected: IObjectWithKey[] = _selection.getSelection();
        if (selected && selected.length > 0) {
            const selectedIds = selected.map(value => {
                return Number(value["id"])
            });
            setSelectedSites(selectedIds);
        } else {
            setShouldClearSelection();
        }
    }

    const columns: IColumn[] = useMemo(() => {
        return [
            {
                key: 'displayName',
                name: languageStrings.DisplayName,
                fieldName: 'displayName',
                data: "string",
                minWidth: 100,
                maxWidth: 350,
                isResizable: true,
            },
        ]
    }, []);

    const renderItemColumn = (item: ISite, index: number, column: IColumn) => {
        const fieldContent = item[column.fieldName as keyof ISite] as string;
        switch (column.key) {
            case "displayName":
                return <Persona text={item.displayName} size={PersonaSize.size40} />
            default:
                return <span>{fieldContent}</span>;
        }
    }

    const filteredSites = useMemo(() => {
        if (items && items.length > 0) {
            return (
                items.filter(site => site?.displayName.toLowerCase().includes(filterText))
            )
        } else {
            return [];
        }
    }, [items, filterText])

    return (
        <div className={ListStyles.viewListScrollStyle}>
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                <DetailsList
                    items={filteredSites}
                    onRenderItemColumn={renderItemColumn}
                    columns={columns}
                    selectionMode={SelectionMode.multiple}
                    selection={_selection}
                    checkboxCellClassName={mergeStyles({
                        display: "flex",
                        alignItems: "center"
                    })}
                />
            </ScrollablePane>
        </div>
    )
}

export default SitesGrid