import * as React from 'react';
import { DetailsListLayoutMode, Sticky, ScrollablePane, ConstrainMode, IRenderFunction, IDetailsHeaderProps, IDetailsColumnRenderTooltipProps, TooltipHost, StickyPositionType, ScrollbarVisibility, SelectionMode, IColumn, SearchBox, Stack, ShimmeredDetailsList, Selection, CommandBarButton } from '@fluentui/react';
import { renderItemColumn } from "../../utilities/helpers/ParterTableHelper"
import { IPortalPartner } from '../../data-structures/interfaces';
import { RootState } from '../../redux';
import { connect, ConnectedProps } from 'react-redux';
import { LanguageContext } from '../../utilities/LocalizationModule';
import * as PartnerTableStyle from "../../styles/PartnerTableStyle"
import DisplayPartnerPanel from "../panels/DisplayPartnerPanel"
import CreatePartnerPanel from "../panels/CreatePartnerPanel"
import { getPartners } from '../../utilities/helpers/ApiHelper';

const onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (props, defaultRender) => {
    if (!props) {
        return null;
    }
    const onRenderColumnHeaderTooltip: IRenderFunction<IDetailsColumnRenderTooltipProps> = tooltipHostProps => (
        <TooltipHost {...tooltipHostProps} />
    );
    return (
        <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
            {defaultRender!({
                ...props,
                onRenderColumnHeaderTooltip,
            })}
        </Sticky>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        reduxTenants: state.user.tenants,
    };
};

const mapDispatchToProps = {

}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;


interface PartnerTableProps extends PropsFromRedux {

};

const getSelectionDetails = (selection: Selection, setIsEditDisabled: (...args: any[]) => any, setEditItem: (...args: any[]) => any) => {
    setIsEditDisabled(!(selection.getSelectedCount() > 0));
    const _selectedItems = selection.getSelectedCount() > 0 ? selection.getSelection()[0] : []
    if (selection.getSelection().length > 0) setEditItem(_selectedItems as IPortalPartner);
}

const PartnerTable: React.FC<PartnerTableProps> = ({reduxTenants}: PartnerTableProps) => {
    const [items, setItems] = React.useState<IPortalPartner[]>([]);
    const [filteredItems, setFilteredItems] = React.useState<IPortalPartner[]>([]);
    const [editItem, setEditItem] = React.useState<IPortalPartner>(null);
    const [isPanelOpen, setIsPanelOpen] = React.useState<boolean>(false);
    const [isCreatePanelOpen, setIsCreatePanelOpen] = React.useState<boolean>(false);
    const [isEditDisabled, setIsEditDisabled] = React.useState<boolean>(true);
    const [filterValue, setFilterValue] = React.useState<string>("");
    const [filterDisabled, setFilterDisabled] = React.useState<boolean>(true);

    const _selection: Selection = new Selection({
        onSelectionChanged: () => getSelectionDetails(_selection, setIsEditDisabled, setEditItem)
    });
    const refItems = React.useRef(items);
    const languageStrings = React.useContext(LanguageContext);

    React.useEffect(() => {
        (async () => {
            updateItems(await getPartners());
        })();
    }, []);

    // React.useEffect(() => {
    //     const newPartners: IPartner[] = [];
    //     partners.forEach((p, i) => {
    //         newPartners[i] = { ...p, profile: { tenants: reduxTenants.filter(t => t.partner?.id == p.id) } };
    //     })
    //     updateItems(newPartners);
    // }, [partners, reduxTenants]);

    // React.useEffect(() => {
    //     if (isPanelOpen == false && isCreatePanelOpen == false) {
    //         setFilterValue("");
    //         const newPartners: IPartner[] = [];
    //         partners.forEach((p, i) => {
    //             newPartners[i] = { ...p, profile: { tenants: reduxTenants.filter(t => t.partner?.id == p.id) } };
    //         })
    //         updateItems(newPartners);
    //         setEditItem(null);
    //     }
    // }, [isPanelOpen, isCreatePanelOpen]);

    window.dispatchEvent(new Event('resize'));

    const updateItems = (newItems: IPortalPartner[]) => {
        setFilterDisabled(newItems?.length == 0)
        refItems.current = newItems;
        setItems(newItems);
        setFilteredItems(newItems);
    }
    const _onColumnClick = (_ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        try {
            const newColumns: IColumn[] = columns.slice();
            const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
            newColumns.forEach((newCol: IColumn) => {
                if (newCol === currColumn) {
                    currColumn.isSortedDescending = !currColumn.isSortedDescending;
                    currColumn.isSorted = true;
                } else {
                    newCol.isSorted = false;
                    newCol.isSortedDescending = true;
                }
            });
            const newItems = copyAndSort(refItems.current, currColumn.fieldName!, currColumn.isSortedDescending);
            setColumns(newColumns);
            updateItems(newItems);
        } catch (error) {
            console.error("onColumnClick: ", error);
        }
    };

    function copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
        try {
            const key = columnKey as keyof T;
            return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
        } catch (error) {
            console.error("copyAndSort: ", error);
            return items;
        }
    }

    const doFilterItems = (filterText: string) => {
        const resultProperties = items.length ? Object.keys(items[0]) : null;
        const filterResult = filterText?.length ?
            items.filter(item => {
                return resultProperties.some(property => {
                    let toSearch = item[property];
                    return (
                        toSearch
                            ?.toString()
                            .toLowerCase()
                            .includes(filterText.toLowerCase()))
                });
            }) :
            items;
        setFilteredItems(filterResult);
    }
    const onSearchFilter = async (filterText: string) => {
        setFilterValue(filterText);
    };
    const onChangeFilter = (filterText: string) => {
        setFilterValue(filterText);
        doFilterItems(filterText);
    };
    const resetFilterValue = () => {
        setFilterValue("");
        doFilterItems("");
        setFilteredItems(items);
    }
    const onClearFilter = () => {
        resetFilterValue();
    };
    const onEscapeFilter = () => {
        resetFilterValue();
    };
    const [columns, setColumns] = React.useState<IColumn[]>([
        {
            key: 'displayName',
            name: languageStrings.Name,
            fieldName: 'name',
            data: "string",
            minWidth: 300,
            maxWidth: 350,
            isResizable: true,
            onColumnClick: _onColumnClick,
        },
        {
            key: 'tenants',
            name: languageStrings.Tenants,
            fieldName: '',
            data: "string",
            minWidth: 100,
            maxWidth: 300,
            isResizable: true,
            onColumnClick: _onColumnClick,
            onRender: (item) => {
                return (
                    <span>
                        {item.tenants?.length > 0 ? item.tenants.map(tenant => tenant.friendlyName).join(", ") : "-"}
                    </span>
                );
            }
        },
        {
            key: 'users',
            name: languageStrings.Users,
            fieldName: '',
            data: "string",
            minWidth: 80,
            maxWidth: 150,
            isResizable: true,
            onColumnClick: _onColumnClick,
            onRender: (item) => {
                return (
                    <span>
                        {item.users?.length > 0 ? item.users.map(user => user.displayName).join(", ") : "-"}
                    </span>
                );
            }
        },
    ]);

    return (
        <div className={PartnerTableStyle.pageContainer}>
            <Stack horizontal style={{ paddingTop: 10 }} verticalAlign="center" horizontalAlign="space-between" wrap className={PartnerTableStyle.commandBar}>
                <Stack.Item>
                    <CommandBarButton
                        disabled={isEditDisabled}
                        iconProps={{ iconName: "edit" }}
                        style={{ paddingTop: 5, paddingBottom: 5 }}
                        text={languageStrings.EditDetails}
                        onClick={() => {
                            setIsPanelOpen(true)
                        }}
                    />
                    <CommandBarButton
                        iconProps={{ iconName: "Add" }}
                        style={{ paddingTop: 5, paddingBottom: 5 }}
                        text={languageStrings.AddPartner}
                        onClick={() => {
                            const ind = _selection.getSelectedIndices();
                            if (ind[0]) _selection.setIndexSelected(ind[0], false, false);
                            setIsCreatePanelOpen(true)
                        }}
                    />
                </Stack.Item>
                <Stack.Item>
                    <SearchBox
                        styles={PartnerTableStyle.searchBoxStyles}
                        disabled={filterDisabled}
                        placeholder={languageStrings.Search}
                        onSearch={onSearchFilter}
                        onChange={(_, nv) => onChangeFilter(nv)}
                        onClear={onClearFilter}
                        onEscape={onEscapeFilter} />
                </Stack.Item>
            </Stack>
            <div className={PartnerTableStyle.tableContainerStyle}>
                <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                    <ShimmeredDetailsList
                        compact={false}
                        selectionPreservedOnEmptyClick={false}
                        items={filteredItems || []}
                        columns={columns}
                        enableShimmer={!filteredItems}
                        selection={_selection}
                        styles={{ ...PartnerTableStyle.detailListStyles, }}
                        layoutMode={DetailsListLayoutMode.justified}
                        selectionMode={SelectionMode.single}
                        constrainMode={ConstrainMode.unconstrained}
                        onRenderItemColumn={(item, index, column) => renderItemColumn(item, index, column, null, languageStrings)}
                        onRenderDetailsHeader={onRenderDetailsHeader}
                    />
                </ScrollablePane>
            </div>
            {editItem && isPanelOpen && <DisplayPartnerPanel isOpen={isPanelOpen} setIsOpen={setIsPanelOpen} partner={editItem} partnerUpdated={async () => updateItems(await getPartners())}/>}
            {isCreatePanelOpen == true ?
                <CreatePartnerPanel
                    isOpen={isCreatePanelOpen}
                    setIsOpen={setIsCreatePanelOpen}
                    onSaveSuccess={async () => {
                        updateItems(await getPartners());
                        setIsCreatePanelOpen(false);
                    }}
                />
                : null}
        </div>
    )
}

export default connector(PartnerTable);