import * as React from 'react';
import { Nav, INavLink, INavLinkGroup } from '@fluentui/react';
import { HomeView } from '../data-structures/enums';
import { navPanelStyle, navIconStyle } from "../styles/NavMenuStyle"
import { RootState } from '../redux';
import { connect, ConnectedProps } from 'react-redux';
import { LanguageContext } from "../utilities/LocalizationModule"

const mapStateToProps = (state: RootState) => {
    return {
        isGlobalAdmin: state.user.isGlobalAdmin
    }
}

const connector = connect(mapStateToProps, null)
type PropsFromRedux = ConnectedProps<typeof connector>;
interface NavMenuProps extends PropsFromRedux {
    setSelectedView: React.Dispatch<React.SetStateAction<HomeView>>
}

const NavMenu: React.FC<NavMenuProps> = ({
    setSelectedView,
    isGlobalAdmin
}: NavMenuProps) => {
    const languageStrings = React.useContext(LanguageContext);
    const [selectedKey, setSelectedKey] = React.useState<string>("Dashboard");
    const [isPartnersExpanded, setPartnersExpanded] = React.useState<boolean>(false);

    const navLinkGroups: INavLinkGroup[] = React.useMemo(() => [
        {
            links: [
                {
                    name: "Dashboard",
                    url: "#",
                    key: HomeView.Dashboard,
                    target: "_blank",
                    iconProps: {
                        iconName: "LineChart",
                        styles: navIconStyle,
                    }
                },
                {
                    name: languageStrings.Users,
                    url: "#",
                    key: HomeView.Users,
                    target: "_blank",
                    iconProps: {
                        iconName: "People",
                        styles: navIconStyle
                    }
                },
            ],
        },
    ], [isPartnersExpanded]);

    navLinkGroups[0].links = !navLinkGroups[0].links.some(l => l.key == HomeView.Subscriptions) ? navLinkGroups[0].links.concat([
        {
            name: languageStrings.Subscriptions,
            url: "#",
            key: HomeView.Subscriptions,
            target: "_blank",
            iconProps: {
                iconName: "PaymentCard",
                styles: navIconStyle,
            }
        },
    ]) : navLinkGroups[0].links;

    //The check needs to be done to see if the nav link has been added to the list yet, or otherwise dubplicates are created on each rerender.

    navLinkGroups[0].links = isGlobalAdmin && !navLinkGroups[0].links.some(l => l.key == HomeView.Partners) ? navLinkGroups[0].links.concat([{
        name: languageStrings.Partners,
        url: "#",
        key: HomeView.Partners,
        iconProps: {
            iconName: "Work",
            styles: navIconStyle
        },
        isExpanded: isPartnersExpanded,
        links: [
            {
                name: languageStrings.Overview,
                url: '#',
                key: HomeView.PartnersOverview,
                target: "_blank",
                iconProps: {
                    iconName: "ViewListGroup",
                    styles: navIconStyle
                }
            },
            {
                name: languageStrings.Requests,
                url: "#",
                key: HomeView.PartnersRequests,
                target: "_blank",
                iconProps: {
                    iconName: "PeopleAlert",
                    styles: navIconStyle
                }
            },
        ]
    }]) : navLinkGroups[0].links;


    // {
    //     name: languageStrings.DataSources,
    //     url: '#',
    //     key: HomeView.DataSources,
    //     target: "_blank",
    //     iconProps: {
    //         iconName: "Dataflows",
    //         styles: navIconStyle
    //     }
    // }

    function _onLinkClick(ev?: React.MouseEvent<HTMLElement>, item?: INavLink) {
        switch (item.key) {
            case HomeView.Users: setSelectedView(HomeView.Users); break;
            case HomeView.Dashboard: setSelectedView(HomeView.Dashboard); break;
            case HomeView.Partners: setPartnersExpanded(!isPartnersExpanded); break;
            case HomeView.PartnersOverview: setSelectedView(HomeView.PartnersOverview); break;
            case HomeView.PartnersRequests: setSelectedView(HomeView.PartnersRequests); break;
            case HomeView.DataSources: setSelectedView(HomeView.DataSources); break;
            case HomeView.Subscriptions: setSelectedView(HomeView.Subscriptions); break;
            default: setSelectedView(HomeView.Dashboard); break;
        }

        setSelectedKey(item.key)
        ev.preventDefault();
    }
    return (
        <Nav
            onLinkClick={_onLinkClick}
            selectedKey={selectedKey}
            ariaLabel="Navigation Menu"
            styles={navPanelStyle}
            groups={navLinkGroups}
        />
    );
};

export default connector(NavMenu)
