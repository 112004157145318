export const SET_USER = "SET_USER";
export const SET_USER_ACCOUNT = "SET_USER_ACCOUNT";
export const SET_USER_PROFILE = "SET_USER_PROFILE";
export const SET_SIGNED_IN = "SET_SIGNED_IN";
export const SET_USERS = "SET_USERS";
export const UPDATE_PORTAL_USER = "UPDATE_PORTAL_USER";
export const DELETE_TENANT = "DELETE_TENANT";
export const UPDATE_TENANT = "UPDATE_TENANT";
export const SET_TENANTS = "SET_TENANTS";
export const SET_ACTIVITIES = "SET_ACTIVITIES";
export const SET_LOADING = "SET_LOADING";
export const SET_TENANT_SITE = "SET_TENANT_SITE";
export const SET_SITE_LIB = "SET_SITE_LIB";
export const SET_TENANT_DATASOURCE = "SET_TENANT_DATASOURCE";

export const SET_REQUESTS = "SET_REQUESTS";
export const REMOVE_REQUEST = "REMOVE_REQUEST";

export const SET_DS_TYPES = "SET_DS_TYPES";

export const SET_FILTER_SECTIONS = "SET_FILTER_SECTIONS";
export const SET_FILTER_SECTIONS_RESET = "SET_FILTER_SECTIONS_RESET";
export const SET_FILTER_SECTION_ITEM = "SET_FILTER_SECTION_ITEM";
export const SET_FILTER_SECTION_ITEMS = "SET_FILTER_SECTION_ITEMS";
export const SET_FILTER_SECTION_ITEMS_FROM_RANGE = "SET_FILTER_SECTION_ITEMS_FROM_RANGE";
export const SET_FILTER_SECTION_TOGGLE_SHOW_MORE = "SET_FILTER_SECTION_TOGGLE_SHOW_MORE";
export const SET_FILTER_SECTION_TOGGLE_SLIDER = "SET_FILTER_SECTION_TOGGLE_SLIDER";
export const SET_FILTER_SECTION_TOGGLE_RELOAD = "SET_FILTER_SECTION_TOGGLE_RELOAD";