import React, { useContext, useRef, } from "react"
import { LanguageContext } from '../../utilities/LocalizationModule';
import { Panel, Label, Stack, PrimaryButton, PanelType, DefaultButton, Callout, DirectionalHint, FocusTrapZone, Calendar, DayOfWeek, } from "@fluentui/react"
import { useSelector } from 'react-redux';
import { RootState } from '../../redux';
import { labelHeadingStyle } from "../../styles/PanelStyle";
import { DayPickerStrings } from "../../utilities/DayPickerStrings";
import { getSubscriptionReport } from "../../utilities/helpers/ApiHelper";

interface ManageSitesPanelProps {
    isOpen: boolean,
    dismissPanel: () => void
}

const SubscriptionsExportPanel: React.FunctionComponent<ManageSitesPanelProps> = ({
    dismissPanel,
    isOpen
}) => {
    const languageStrings = useContext(LanguageContext);

    const user = useSelector((state: RootState) => state.user);

    const [showFromCalendar, setFromShowCalendar] = React.useState<boolean>(false);
    const [showToCalendar, setToShowCalendar] = React.useState<boolean>(false);

    const [selectedFromDate, setSelectedFromDate] = React.useState<Date>(null);
    const [selectedToDate, setSelectedToDate] = React.useState<Date>(null);

    const calendarFromButtonElement = useRef(null);
    const calendarToButtonElement = useRef(null);

    const [isExporting, setIsExporting] = React.useState<boolean>(false);

    const onSelectFromDate = (date: Date): void => {
        setFromShowCalendar(!showFromCalendar);
        setSelectedFromDate(date);
    };

    const onSelectToDate = (date: Date): void => {
        setToShowCalendar(!showToCalendar);
        setSelectedToDate(date);
    };

    function createExcelReportFilename(startDate: Date, endDate: Date): string {
        const formatDate = (date: Date): string =>
            new Intl.DateTimeFormat('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' })
                .format(date)
                .replace(/\//g, '-');

        const adjustedEndDate = new Date(endDate);
        adjustedEndDate.setDate(endDate.getDate() + 1);
        adjustedEndDate.setSeconds(adjustedEndDate.getSeconds() - 1);

        return `${languageStrings.DocubirdSubscriptionReport} (${formatDate(startDate)} - ${formatDate(adjustedEndDate)}).xlsx`;
    }

    return (
        <Panel
            isOpen={isOpen}
            type={PanelType.custom}
            customWidth={"500px"}
            onDismiss={() => dismissPanel()}
            headerText={languageStrings.ExportToExcel}
        >
            <Stack tokens={{ childrenGap: 20 }} style={{ marginTop: 20 }}>
                <Stack horizontal tokens={{ childrenGap: 20 }}>
                    <Stack.Item style={{ paddingTop: 5 }}>
                        <Label styles={labelHeadingStyle}>{languageStrings.From}</Label>
                        <div ref={calendarFromButtonElement}>
                            <DefaultButton
                                onClick={() => {
                                    setFromShowCalendar(true)
                                    setSelectedFromDate(new Date());
                                }}
                                text={!selectedFromDate ? languageStrings.ClickForCalendar : new Date(selectedFromDate).toDateString()}
                            />
                        </div>
                        {showFromCalendar && (
                            <Callout
                                isBeakVisible={false}
                                className="ms-DatePicker-callout"
                                gapSpace={0}
                                doNotLayer={false}
                                target={calendarFromButtonElement}
                                directionalHint={DirectionalHint.rightCenter}
                                onDismiss={() => setFromShowCalendar(false)}
                                setInitialFocus
                            >
                                <FocusTrapZone isClickableOutsideFocusTrap>
                                    <Calendar
                                        // eslint-disable-next-line react/jsx-no-bind
                                        onSelectDate={onSelectFromDate}
                                        onDismiss={() => setFromShowCalendar(false)}
                                        isMonthPickerVisible={true}
                                        value={new Date(selectedFromDate)!}
                                        firstDayOfWeek={DayOfWeek.Sunday}
                                        strings={DayPickerStrings}
                                        isDayPickerVisible={true}
                                        highlightCurrentMonth={true}
                                        highlightSelectedMonth={true}
                                        showMonthPickerAsOverlay={true}
                                        showGoToToday={true}
                                    />
                                </FocusTrapZone>
                            </Callout>
                        )}
                    </Stack.Item>
                    <Stack.Item style={{ paddingTop: 5 }}>
                        <Label styles={labelHeadingStyle}>{languageStrings.To}</Label>
                        <div ref={calendarToButtonElement}>
                            <DefaultButton
                                onClick={() => {
                                    setToShowCalendar(true)
                                    setSelectedToDate(new Date());
                                }}
                                text={!selectedToDate ? languageStrings.ClickForCalendar : new Date(selectedToDate).toDateString()}
                            />
                        </div>
                        {showToCalendar && (
                            <Callout
                                isBeakVisible={false}
                                className="ms-DatePicker-callout"
                                gapSpace={0}
                                doNotLayer={false}
                                target={calendarToButtonElement}
                                directionalHint={DirectionalHint.rightCenter}
                                onDismiss={() => setToShowCalendar(false)}
                                setInitialFocus
                            >
                                <FocusTrapZone isClickableOutsideFocusTrap>
                                    <Calendar
                                        // eslint-disable-next-line react/jsx-no-bind
                                        onSelectDate={onSelectToDate}
                                        onDismiss={() => setToShowCalendar(false)}
                                        isMonthPickerVisible={true}
                                        value={new Date(selectedToDate)!}
                                        firstDayOfWeek={DayOfWeek.Sunday}
                                        strings={DayPickerStrings}
                                        isDayPickerVisible={true}
                                        highlightCurrentMonth={true}
                                        highlightSelectedMonth={true}
                                        showMonthPickerAsOverlay={true}
                                        showGoToToday={true}
                                    />
                                </FocusTrapZone>
                            </Callout>
                        )}
                    </Stack.Item>
                </Stack>

                <Stack.Item>
                    <PrimaryButton
                        text={languageStrings.ExportToExcel}
                        disabled={!selectedFromDate || !selectedToDate || selectedFromDate > selectedToDate || isExporting}
                        onClick={async () => {
                            setIsExporting(true);
                            const result = await getSubscriptionReport({ startDate: selectedFromDate.toISOString(), endDate: selectedToDate.toISOString() }, user.id);
                            if (result) {
                                const url = window.URL.createObjectURL(new Blob([result]));
                                const link = document.createElement('a');
                                link.href = url;
                                link.setAttribute('download', createExcelReportFilename(selectedFromDate, selectedToDate));
                                document.body.appendChild(link);
                                link.click();
                            }
                            setIsExporting(false);
                        }}
                    />
                </Stack.Item>
            </Stack>
        </Panel>
    )
}

export default SubscriptionsExportPanel;