import { IColumn } from '@fluentui/react';
import { IPartner } from '../../data-structures/interfaces';

export const renderItemColumn = (item: IPartner, index: number, column: IColumn, handlePanelOpen: (content: IPartner) => void, languageStrings: any) => {
    const fieldContent = item[column.fieldName as keyof IPartner] as string;
    switch (column.key) {
        case 'managedTenants':
            return <span>{item.profile.tenants?.length}</span>;
        default:
            return <span>{fieldContent}</span>;
    }
}

