import React from 'react';
import { Dropdown, IconButton, IDropdownOption, IDropdownStyles, IIconProps, ITooltipHostStyles, Stack, TextField, TooltipHost } from "@fluentui/react";
import { IPortalPartner, ITenant, ITenantPartner } from "../data-structures/interfaces"
import { textBox } from "../styles/DashboardStyles"
import { getPartners } from '../utilities/helpers/ApiHelper';

const discardIcon: IIconProps = { iconName: 'Clear' };
const hostStyles: Partial<ITooltipHostStyles> = { root: { display: 'inline-block', paddingLeft: 10 } };
const hostStylesGlobalAdmin: Partial<ITooltipHostStyles> = { root: { display: 'inline-block' } };

const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: 300 },
};

interface TenantRowProps {
    item: ITenant,
    index: number,
    discardAddTenant: () => void,
    changeTenantId: (text: string, item: ITenant) => void,
    changeFriendlyName: (text: string, item: ITenant) => void,
    changePartnerId: (item: ITenant) => void,
    languageStrings: any,
    validateId: (value: string) => string,
    validateName: (value: string) => string,
    isDirty: () => void,
    partners: ITenantPartner[],
    isGlobalAdmin: boolean
};

const TenantRow: React.FC<TenantRowProps> = ({
    item,
    index,
    discardAddTenant,
    changeTenantId,
    changeFriendlyName,
    changePartnerId,
    languageStrings,
    validateId,
    validateName,
    isDirty,
    partners,
    isGlobalAdmin
}: TenantRowProps) => {

    const readOnly = item.id === 0 ? false : true;

    const [partnersOptions, setPartnersOptions] = React.useState<IDropdownOption[]>([]);

    React.useEffect(() => {
        if (!isGlobalAdmin && partners.length > 0) {
            setPartnersOptions(partners.map(partner => {
                return (
                    { key: partner.id, text: partner.name }
                )
            }));
    
            const updatedItem: ITenant = {
                ...item,
                partner: {
                    id: partners[0].id,
                    name: partners[0].name
                }
            }
            changePartnerId(updatedItem);
        }
    }, [partners])

    return (
        <Stack horizontal>
            <Stack.Item>
                <TextField value={item.tenantId} onChange={(e, nv) => changeTenantId(nv, item)} readOnly={readOnly} required
                    validateOnLoad={false}
                    validateOnFocusOut={true}
                    onGetErrorMessage={validateId}
                    onBlur={isDirty} className={textBox} />
            </Stack.Item>
            <Stack.Item>
                <TextField value={item.friendlyName} onChange={(e, nv) => changeFriendlyName(nv, item)} required
                    validateOnLoad={false}
                    validateOnFocusOut={true}
                    onGetErrorMessage={validateName}
                    onBlur={isDirty} className={textBox} />
            </Stack.Item>
            {!isGlobalAdmin && <Stack.Item>
                <Dropdown
                    required
                    defaultSelectedKey={partners?.length > 0 ? partners[0].id : null}
                    options={partnersOptions}
                    styles={dropdownStyles}
                    onChange={(e, o, i) => {
                        const selectedPartner = partners.find(x => x.id === o.key);

                        const updatedItem: ITenant = {
                            ...item,
                            partner: {
                                id: selectedPartner.id,
                                name: selectedPartner.name
                            }
                        }

                        changePartnerId(updatedItem);
                    }}
                />
            </Stack.Item>}
            <Stack.Item>
                <TooltipHost
                    content={`${languageStrings.Discard}`}
                    id={"discard" + index}
                    calloutProps={{ gapSpace: 0 }}
                    styles={isGlobalAdmin ? hostStylesGlobalAdmin : hostStyles}>
                    <IconButton iconProps={discardIcon} ariaLabel={`${languageStrings.Discard}`} onClick={() => discardAddTenant()} />
                </TooltipHost>
            </Stack.Item>

        </Stack>
    )
}

export default TenantRow;