
import { Label, Separator } from '@fluentui/react';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../redux';
import { LanguageContext } from '../../utilities/LocalizationModule';
import * as OverviewStyles from "../../styles/OverviewStyles"
import PartnersTable from "./PartnersTable"

const mapDispatchToProps = {
}


const mapStateToProps = (state: RootState) => {
    return {
        partners: state.user.users.filter(u => u.isPartner),
    };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface PartnersOverviewProps extends PropsFromRedux { };

const PartnersOverview: React.FC<PartnersOverviewProps> = ({ partners }: PartnersOverviewProps) => {
    const languageStrings = React.useContext(LanguageContext);
    return (
        <div style={{ position: "relative" }}>
            <Label styles={OverviewStyles.headingStyle}>{languageStrings.Overview}</Label>
            <Separator className={OverviewStyles.divider} />
            <PartnersTable />
        </div>
    )
}

export default connector(PartnersOverview);