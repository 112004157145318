import React, { useContext, useState, useEffect, useCallback } from "react"
import { LanguageContext } from '../../utilities/LocalizationModule';
import { Panel, Label, Stack, PrimaryButton, CommandBarButton, PanelType, SearchBox, TextField, } from "@fluentui/react"
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../redux';
import { errorLabelStyle, labelHeadingStyle, panelCommandButtonStyle } from "../../styles/PanelStyle";
import * as ListStyles from "../../styles/ListStyles"
import { IDocumentLibrary } from "../../data-structures/interfaces";
import LibrariesGrid from "../LibrariesGrid"
import AddMode from "../SiteAndLibAdd"
import { getSiteLibraries, setSiteLibrary, addSiteLibrary, deleteSiteLibraries, updateSiteName } from "../../redux/modules/user";
import { useMemo } from "react";
import { updateLibrary } from "../../utilities/helpers/ApiHelper";

const mapStateToProps = (state: RootState) => {
    return {
        isPartner: state.user.isPartner,
        isGlobalAdmin: state.user.isGlobalAdmin,
        tenants: state.user.tenants
    };
};

const mapDispatchToProps = {
    getSiteLibraries,
    setSiteLibrary,
    addSiteLibrary,
    deleteSiteLibraries,
    updateSiteName
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface ManageLibraryPanelProps extends PropsFromRedux {
    isOpen: boolean,
    dismissPanel: () => void,
    siteId: number,
    tenantId: number,
}

const ManageLibraryPanel: React.FunctionComponent<ManageLibraryPanelProps> = ({
    dismissPanel,
    isOpen,
    getSiteLibraries,
    siteId,
    tenantId,
    tenants,
    setSiteLibrary,
    addSiteLibrary,
    deleteSiteLibraries,
    updateSiteName
}) => {
    const languageStrings = useContext(LanguageContext);
    const [filterValue, setFilterValue] = useState<string>("");
    const [selectedLibs, setSelectedLibs] = useState<string[]>([]);
    const [isAddMode, setIsAddMode] = useState<boolean>(false);
    const [addLibName, setAddLibName] = useState<string>("");
    const [localSiteName, setLocalSiteName] = useState<string>("");
    const [editMode, setEditMode] = useState<number>(-1);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const site = useMemo(() => {
        return tenants.find(t => t.id === tenantId).sites.find(s => s.id === siteId)
    }, [tenants])

    useEffect(() => {
        if (site) {
            if (!site.documentLibraries) {
                getSiteLibraries(site.id, site.tenantId);
            }
            setLocalSiteName(site.displayName);
        }
    }, [site]);

    const onChangeFilter = (filterText: string) => {
        setFilterValue(filterText ?? "");
    };

    const onClearFilter = () => {
        setFilterValue("");
    };
    const onEscapeFilter = () => {
        setFilterValue("");
    };

    const onSelect = useCallback((titles: string[]) => {
        setSelectedLibs(titles);
    }, []);

    const isDuplicate = (newField: string, index?: number): boolean => {       
        let duplicate = false;
        let errorMsg = "";
        let titles = site.documentLibraries.map(s => s.title);

        if (index) {
            titles.splice(index, 1);
        }

        if (titles.includes(newField)) {
            duplicate = true;
            errorMsg = languageStrings.DuplicatesFound + ' ' + newField;
        }
        setErrorMessage(errorMsg);
        return duplicate;
    }

    const isEmpty = (newField: string): boolean => {       
        let empty = false;
        let errorMsg = "";

        if (!newField || newField?.length === 0) {
            empty = true;
            errorMsg = languageStrings.TitleRequired;
        }

        setErrorMessage(errorMsg);
        return empty;
    }

    const onAddConfirm = () => {
        if (addLibName && !isDuplicate(addLibName)) {
            const newLib: IDocumentLibrary = {
                title: addLibName,
                autoFolderCreationEnabled: false,
                hidden: false
            };
            addSiteLibrary(tenantId, site.id, newLib)
            //setSiteLibrary(tenantId, site.displayName, site.documentLibraries.concat(newLib));
            setAddLibName("");
        }
    }

    const handleToggle = (index: number) => {
        let toggleLibs = [...site.documentLibraries].map((lib, i) => {
            if (index === i) {
                return {
                    ...lib,
                    autoFolderCreationEnabled: !lib.autoFolderCreationEnabled
                }
            } else {
                return lib;
            }

        });
        setSiteLibrary(tenantId, site.displayName, toggleLibs);
        updateLibrary([toggleLibs[index]]);
    }

    const handleHiddenToggle = (index: number) => {
        let toggleLibs = [...site.documentLibraries].map((lib, i) => {
            if (index === i) {
                return {
                    ...lib,
                    hidden: !lib.hidden
                }
            } else {
                return lib;
            }

        });
        setSiteLibrary(tenantId, site.displayName, toggleLibs);
        updateLibrary([toggleLibs[index]]);
    }

    const deleteLibs = () => {
        const libIds = site.documentLibraries.map(l => {
            if (selectedLibs.includes(l.title)) {
                return l.id;
            } else {
                return -1;
            }
        }).filter(num => num >= 0);
        deleteSiteLibraries(tenantId, site.id, libIds);
    }

    const updateLibs = (index: number, newValue: string) => {
        if (!isEmpty(newValue)) {
            if (!isDuplicate(newValue, index)) {
                let libs: IDocumentLibrary[] = site.documentLibraries.map((l, i) => {
                    return ({
                        ...l,
                        title: i === index ? newValue : l.title
                    })
                });
                setSiteLibrary(tenantId, site.displayName, libs);
                updateLibrary([libs[index]]);
            }
        }
    }

    const updateSite = () => {
        if (!checkSiteDuplicates(localSiteName) && localSiteName !== site.displayName) {
            updateSiteName(site.tenantId, site.id, localSiteName);
        }
    }

    const checkSiteDuplicates = (newSiteName: string): boolean => {
        let duplicate = false;
        let errorMsg = "";
        if (!(newSiteName === site.displayName) &&
            tenants.find(t => t.id === site.tenantId).sites.map(s => s.displayName).includes(newSiteName)) {
            duplicate = true;
            errorMsg = languageStrings.DuplicateSitesFound + ' ' + newSiteName;
        }
        setErrorMessage(errorMsg);
        return duplicate;
    }

    return (
        <Panel
            isOpen={isOpen}
            type={PanelType.custom}
            customWidth={"500px"}
            onDismiss={() => {
                dismissPanel()
            }}
            headerText={languageStrings.AdvancedSiteSettings}
        >
            <Label style={{ marginBottom: 10, borderBottomColor: 'rgb(237, 235, 233)', borderBottomWidth: 0.5, borderBottomStyle: 'solid' }}>{languageStrings.LibraryPanelSubtitle}</Label>
            <Stack.Item style={{ marginTop: 15, marginBottom: 5 }}>
                <Label styles={labelHeadingStyle}>{languageStrings.DisplayName}</Label>
                <TextField value={localSiteName} onChange={(_, nv) => setLocalSiteName(nv)}
                    styles={ListStyles.searchBoxStyles}
                    onBlur={() => updateSite()}
                />
            </Stack.Item>
            <Stack verticalAlign="space-between">
                <Label styles={labelHeadingStyle}>{languageStrings.DocumentLibraries}</Label>
                <Stack style={{ paddingTop: 5, paddingBottom: 5 }} horizontal>
                    <CommandBarButton
                        iconProps={{ iconName: "add", styles: panelCommandButtonStyle }}
                        text={languageStrings.Add}
                        onClick={() => { setIsAddMode(true) }}
                    />
                    <CommandBarButton
                        disabled={selectedLibs.length > 0 ? false : true}
                        iconProps={{ iconName: "delete", styles: panelCommandButtonStyle }}
                        text={languageStrings.Delete + (selectedLibs.length > 0 ? ` (${selectedLibs.length})` : "")}
                        onClick={() => { deleteLibs() }}
                    />
                </Stack>
                <Stack horizontalAlign="start" style={{ width: "100%" }}>
                    {isAddMode
                        ? <AddMode
                            onCancel={() => setIsAddMode(false)}
                            onAccept={() => {
                                onAddConfirm();
                                setIsAddMode(false)
                            }}
                            siteName={addLibName}
                            onChangeSiteName={(newValue) => setAddLibName(newValue)}
                            onEnter={() => onAddConfirm()}
                            placeHolderText={languageStrings.EnterNewLibName}
                        />
                        :
                        <SearchBox
                            styles={ListStyles.searchBoxStyles}
                            placeholder={languageStrings.Search}
                            onChange={(_, nv) => onChangeFilter(nv)}
                            onClear={onClearFilter}
                            onEscape={onEscapeFilter}
                        />
                    }
                    {errorMessage.length > 0 ?
                        <Stack horizontal style={{ width: "100%", marginTop: 0, marginBottom: 10, backgroundColor: "#fde7e9" }}>
                            <Label className={errorLabelStyle} style={{ width: "100%", margin: 0 }}>
                                {errorMessage}
                            </Label>
                            <CommandBarButton
                                iconProps={{
                                    iconName: "cancel", styles: {
                                        root: {
                                            padding: 10,
                                            margin: 0,
                                            color: "black"
                                        }
                                    }
                                }}
                                style={{ margin: 0, padding: 0, backgroundColor: "transparent" }}
                                onClick={() => {
                                    setErrorMessage("");
                                }}
                            />
                        </Stack> : null}
                </Stack>
                <LibrariesGrid
                    items={site?.documentLibraries ?? []}
                    setSelectedLibs={onSelect}
                    filterText={filterValue}
                    handleToggle={handleToggle}
                    handleHiddenToggle={handleHiddenToggle}
                    setEditMode={(index: number) => {
                        setEditMode(index)
                    }}
                    editMode={editMode}
                    onChangeFieldName={(index: number, newValue: string) => {
                        updateLibs(index, newValue);
                    }}
                    hasError={!!errorMessage}
                />
                <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 15 }} style={{ marginTop: 20 }}>
                    <PrimaryButton
                        iconProps={{
                            iconName: "CheckMark"
                        }}
                        onClick={() => {
                            dismissPanel()
                        }}
                    >
                        {languageStrings.Done}
                    </PrimaryButton>
                </Stack>
            </Stack>
        </Panel>
    )
}

export default connector(ManageLibraryPanel);


