import { INavStyles, IIconStyles } from "@fluentui/react"


export const navPanelStyle: Partial<INavStyles> = {
    root: {
        width: 208,
        height: "calc(100vh - 50px)",
        boxSizing: 'border-box',
        borderRight: '1px solid #eee',
        overflowY: 'auto',
    },
    chevronButton: {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
    },
    chevronIcon: {
        color: 'rgb(50, 49, 48)'
    }
};

export const navIconStyle: Partial<IIconStyles> = {
    root: {
        paddingLeft: 25,
        paddingRight: 5,
        fontSize: 18,
        color: 'rgb(50, 49, 48)'
    }
}