import { typedAction } from "../helpers";
import { SET_FILTER_SECTION_TOGGLE_SLIDER, SET_FILTER_SECTIONS, SET_FILTER_SECTIONS_RESET, SET_FILTER_SECTION_ITEM, SET_FILTER_SECTION_ITEMS, SET_FILTER_SECTION_ITEMS_FROM_RANGE, SET_FILTER_SECTION_TOGGLE_SHOW_MORE, SET_FILTER_SECTION_TOGGLE_RELOAD } from "../actionTypes";
import { FilterItem, FilterSection,} from "../../data-structures/interfaces";

export interface IFilterState {
    filterSections: FilterSection[];
    toggle: boolean;
  }

const initialState: IFilterState = {
    filterSections: [],
    toggle: false
}


export const setFilterSections = (filterSections: FilterSection[]) => {
    return typedAction(SET_FILTER_SECTIONS, filterSections);
}

export const setFilterSectionsReset = () => {
    return typedAction(SET_FILTER_SECTIONS_RESET);
}

export const setFilterSectionItem = (filterSection: FilterSection, sectionItem: FilterItem, isChecked: boolean) => {
    return typedAction(SET_FILTER_SECTION_ITEM, { filterSection, sectionItem, isChecked });
}

export const setFilterSectionItems = (filterSection: FilterSection, sectionItems: FilterItem[]) => {
    return typedAction(SET_FILTER_SECTION_ITEMS, { filterSection, sectionItems });
}

export const setFilterSectionItemsFromRange = (filterSection: FilterSection, daysOffset: number) => {
    return typedAction(SET_FILTER_SECTION_ITEMS_FROM_RANGE, { filterSection, daysOffset });
}

export const setFilterSectionToggleShowMOre = (filterSection: FilterSection) => {
    return typedAction(SET_FILTER_SECTION_TOGGLE_SHOW_MORE, { filterSection });
}

export const setFilterSectionToggleSlider = (filterSection: FilterSection) => {
    return typedAction(SET_FILTER_SECTION_TOGGLE_SLIDER, { filterSection });
}

export const toggleReload = () => {
    return typedAction(SET_FILTER_SECTION_TOGGLE_RELOAD);
}

type FilterAction = ReturnType<
    typeof setFilterSections |
    typeof setFilterSectionsReset |
    typeof setFilterSectionItem |
    typeof setFilterSectionItems |
    typeof setFilterSectionItemsFromRange |
    typeof setFilterSectionToggleShowMOre |
    typeof setFilterSectionToggleSlider |
    typeof toggleReload
>;

export function filterReducer(state = initialState, action: FilterAction): IFilterState {
    switch (action.type) {
        case SET_FILTER_SECTIONS:
            return { ...state, filterSections: action.payload };
        case SET_FILTER_SECTIONS_RESET: {
            const newState = {
                ...state,
                filterSections: state.filterSections.map(section => {
                    return {
                        ...section,
                        showMoreExpanded: false,
                        showSlider: section.filterField.type === 'DateTime' ? true : false,
                        hasSlider: section.filterField.type === 'DateTime' ? true : false,
                        daysOffset: null,
                        items: section.items.map(item => {
                            return {
                                ...item,
                                selected: false
                            }
                        })
                    }
                })
            }
            return newState;
        }
        case SET_FILTER_SECTION_ITEM: {
            const newState = {
                ...state,
                filterSections: state.filterSections.map(section => {
                    if (section.filterField.realFieldName === action.payload.filterSection.filterField.realFieldName) {
                        return {
                            ...section,
                            daysOffset: null,
                            items: section.items.map(item => {
                                if (item.title === action.payload.sectionItem.title) {
                                    return {
                                        ...item,
                                        selected: action.payload.isChecked
                                    }
                                }
                                else {
                                    return item
                                }
                            })
                        }
                    }
                    else {
                        return section
                    }
                })
            }
            return newState;
        }
        case SET_FILTER_SECTION_ITEMS: {
            const newState = {
                ...state,
                filterSections: state.filterSections.map(section => {
                    if (section.filterField.realFieldName === action.payload.filterSection.filterField.realFieldName) {
                        return {
                            ...section,
                            items: [...action.payload.sectionItems]
                        }
                    }
                    else {
                        return section
                    }
                })
            }
            return newState;
        }
        case SET_FILTER_SECTION_ITEMS_FROM_RANGE: {
            const newState = {
                ...state,
                filterSections: state.filterSections.map(section => {
                    if (section.filterField.realFieldName === action.payload.filterSection.filterField.realFieldName) {
                        return {
                            ...section,
                            daysOffset: action.payload.daysOffset
                        }
                    }
                    else {
                        return section
                    }
                })
            }
            return newState;
        }
        case SET_FILTER_SECTION_TOGGLE_SHOW_MORE:
            const newState = {
                ...state,
                filterSections: state.filterSections.map(section => {
                    if (section.filterField.realFieldName === action.payload.filterSection.filterField.realFieldName) {
                        return {
                            ...section,
                            showMoreExpanded: !action.payload.filterSection.showMoreExpanded
                        }
                    }
                    else {
                        return section
                    }

                })
            }
            return newState;
        case SET_FILTER_SECTION_TOGGLE_SLIDER: {
            const newState = {
                ...state,
                filterSections: state.filterSections.map(section => {
                    if (section.filterField.realFieldName === action.payload.filterSection.filterField.realFieldName) {
                        return {
                            ...section,
                            showSlider: !action.payload.filterSection.showSlider,
                            items: section.items.map(item => {
                                return {
                                    ...item,
                                    selected: false
                                }
                            })
                        }
                    }
                    else {
                        return section
                    }

                })
            }
            return newState;
        }
        case SET_FILTER_SECTION_TOGGLE_RELOAD: {
            const newState = {
                ...state,
                toggle: !state.toggle
            }
            return newState;
        }
        default:
            return state;
    }
}