import Home from "./screens/Home"
import * as React from "react";
import { LanguageContext, LocalizationModule } from "./utilities/LocalizationModule";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { authRequest } from "./utilities/authConfig";
import Loading from "./components/Loading";
import MessageScreen from "./screens/MessageScreen";
import { DefaultButton } from "@fluentui/react";

import dayjs from 'dayjs';
import 'dayjs/locale/nl'; // import locale
import 'dayjs/locale/de';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import Auth from "./components/displays/Auth";
import Consent from "./components/displays/Consent";
dayjs.extend(localizedFormat);

const AuthLoading: React.FC = () => {
    const languageStrings = React.useContext(LanguageContext);
    return <Loading label={languageStrings.SignInRequest} />;
};

const AuthError: React.FC = () => {
    const languageStrings = React.useContext(LanguageContext);
    const actionButton = <DefaultButton style={{ marginTop: 10 }}
        onClick={() => {
            window.location.reload();
        }}>
        {languageStrings.TryAgain}
    </DefaultButton>
    return <MessageScreen text={languageStrings.AuthFailed} actionButton={actionButton} />;
};

const App: React.FC = () => {
    const languageStrings = LocalizationModule.getLocaleStrings();
    const currentPath = window.location.pathname;
    const isAuthPath = currentPath === "/auth";
    const isConsentPath = currentPath === "/consent";
    return (
        <LanguageContext.Provider value={languageStrings}>
            {isAuthPath
                ? <Auth />
                : (isConsentPath
                    ? <Consent />
                    : <MsalAuthenticationTemplate
                        interactionType={InteractionType.Redirect}
                        authenticationRequest={authRequest}
                        errorComponent={AuthError}
                        loadingComponent={AuthLoading}>
                        <Home />
                    </MsalAuthenticationTemplate>)}
        </LanguageContext.Provider>
    );
};

export default App;