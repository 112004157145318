import * as React from "react";
import {
    Icon,
    ILabelStyles,
    Label,
    Panel,
    PanelType,
    PrimaryButton,
    Stack,
    TextField,
} from "@fluentui/react";
import * as LZString from "lz-string";
import { LanguageContext } from "../../utilities/LocalizationModule";
import emailMapper, { emailMappingTypes } from "../../services/EmailMapper";
import DialogMessage from "../DialogMessage";
import { MessageType } from "../../data-structures/enums";

interface EmailMappingProps {
    isOpen: boolean,
    closePanel: () => void,
    closeOnSave: (compressedData: string, clearUserEmailMappings: boolean) => void,
    emailToMetadataMapping?: string;
}

export const EmailMappingPanel: React.FunctionComponent<EmailMappingProps> = ({
    isOpen,
    closePanel,
    closeOnSave,
    emailToMetadataMapping
}) => {
    const languageStrings = React.useContext(LanguageContext);

    const [columnVals, setColumnVals] = React.useState<string[]>();
    const [showConfirmSaveDialog, setShowConfirmSaveDialog] = React.useState(false);

    React.useEffect(() => {
        if (isOpen) {
            if (emailToMetadataMapping) {
                const userMailMappings: string = LZString.decompressFromBase64(emailToMetadataMapping);
                emailMapper.setMapping(userMailMappings);
            } else {
                emailMapper.resetMap();
            }

            const newVals = Object.keys(emailMappingTypes).map(key => emailMapper.getColumnValue(key)?.split(",").map(v => v.trim()).join(", "));
            setColumnVals(newVals);
        }
    }, [isOpen]);

    const buildEmailCategoryItems = () => {
        const items = Object.keys(emailMappingTypes).map((type, index) => {
            //use typeof emailMappingTypes[type] to determine type of field, of necessary.
            return (
                <Stack key={index} tokens={{ childrenGap: 5 }} verticalAlign="space-between">
                    <Stack horizontal verticalAlign="center">
                        <Icon
                            iconName={emailMapper.emailTypeIconNameResolver(type)}
                            style={{
                                fontSize: 19,
                                marginRight: 7,
                            }}
                            styles={{
                                root: {
                                    marginBottom: 0,
                                    marginTop: 0,
                                }

                            }}

                        />
                        <Label styles={labelStyle}>
                            {emailMapper.emailMappingCategoryNameResolver(languageStrings, type)}
                        </Label>
                    </Stack>
                    <TextField
                        value={columnVals[index]}
                        onChange={(_, nv) => {
                            setColumnVals(prevVals => {
                                const newArr = [...prevVals];
                                newArr[index] = nv;
                                return newArr;
                            })
                        }}
                    />
                </Stack>
            )
        })
        return items;
    }

    const emailMappingItems = React.useMemo(() => {
        if (columnVals) {
            return buildEmailCategoryItems();
        }
        return null;
    }, [columnVals]);

    const onSave = async (clearUserEmailMappings: boolean) => {
        Object.keys(emailMappingTypes).forEach((type, index) => {
            emailMapper.setColumnNameValue(type, columnVals[index]?.split(",").map(v => v.trim()).join(","));
        })
        const emailMappingString = emailMapper.getEmailMappingString();

        const compressedData = LZString.compressToBase64(emailMappingString);

        closeOnSave(compressedData, clearUserEmailMappings);
    }

    return (
        <Panel
            headerText={languageStrings.EmailHeaderMapping}
            isOpen={isOpen}
            onDismiss={closePanel}
            closeButtonAriaLabel="Close"
            type={PanelType.custom}
            customWidth={"500px"}
        >
            <>
                {emailMappingItems &&
                    <>
                        <Stack styles={{ root: { marginTop: 10 } }} tokens={{ childrenGap: 15 }}>
                            {emailMappingItems}
                        </Stack>
                        <PrimaryButton
                            text={languageStrings.Save}
                            styles={{
                                root: {
                                    marginTop: 15,
                                    marginBottom: 10,
                                }
                            }}
                            onClick={async () => setShowConfirmSaveDialog(true)}
                        />
                    </>}
                {showConfirmSaveDialog ?
                    <DialogMessage
                        isHidden={!showConfirmSaveDialog}
                        hideDialog={() => setShowConfirmSaveDialog(false)}
                        title={languageStrings.OverwriteUsers}
                        message={languageStrings.OverwriteUserEmailMappings}
                        confirmAction={() => {
                            onSave(true)
                        }}
                        cancelAction={() => {
                            onSave(false)
                        }}
                        type={MessageType.YesNo}
                    />
                    : null
                }
            </>

        </Panel>
    )
};

const labelStyle: ILabelStyles = {
    root: {
        padding: 0,
        margin: 0
    }
}