import { typedAction } from "../helpers";
import { SET_REQUESTS, SET_LOADING } from "../actionTypes";
import { IPortalTenantRequestFeedback, IRequest } from "../../data-structures/interfaces";
import { RootState } from "..";
import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { loadRequests, updateRequest as updateRequestAPI } from "../../utilities/helpers/ApiHelper"

const initialState: {
    isLoading: boolean,
    requests: IRequest[],
} = {
    isLoading: true,
    requests: []
}


export const setRequests = (requests: IRequest[]) => {
    try {
        return typedAction(SET_REQUESTS, requests);
    }
    catch (e) {
        console.error(e);
        return null;
    }
};

export const setLoading = (isLoading: boolean) => {
    try {
        return typedAction(SET_LOADING, isLoading);
    }
    catch (e) {
        console.error(e);
        return null;
    }
};

export const getRequests = (): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(setLoading(true))
    try {
        const requests = await loadRequests();//api call
        dispatch(setRequests(requests));
    } catch (e) {
        console.error(e);
    }
    dispatch(setLoading(false))
};

export const removeRequest = (requestId: number): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    const updatedRequests: IRequest[] = [...getState().requests.requests].filter(r => r.id !== requestId) ?? [];
    try {
        dispatch(setRequests(updatedRequests));
    } catch (e) {
        console.error(e);
    }
};

export const updateRequest = (feedback: IPortalTenantRequestFeedback): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    const updatedRequests: IRequest[] = [...getState().requests.requests].filter(r => r.id !== feedback.portalTenantRequestId) ?? [];
    // const ind = currentRequests.findIndex(r => feedback.portalTenantRequestId == r.tenant.id);
    dispatch(setLoading(true))
    try {
        await updateRequestAPI(feedback);//api call
        dispatch(setRequests(updatedRequests));

    } catch (e) {
        console.error(e);
    }
    dispatch(setLoading(false))
};

type RequestAction = ReturnType<typeof setRequests | typeof setLoading>;

export function requestReducer(state = initialState, action: RequestAction): {
    isLoading: boolean,
    requests: IRequest[],
} {
    switch (action.type) {
        case SET_REQUESTS:
            return { ...state, requests: action.payload };
        case SET_LOADING:
            return { ...state, isLoading: action.payload };
        default:
            return state;
    }
}