
import React from 'react';
import { LanguageContext } from '../../utilities/LocalizationModule';
import { Panel, Label, Stack, Dropdown, PrimaryButton, Toggle, IDropdownOption, PanelType } from "@fluentui/react"
import { ITenantUser, MollieSubscription } from '../../data-structures/interfaces';
import { labelHeadingStyle, dropdownStyles, labelInfoStyle } from "../../styles/PanelStyle"
import { DayPickerStrings } from "../../utilities/DayPickerStrings"
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../redux';
import { updateUser } from '../../redux/modules/user';
import { LandingPage, LicenseType, SubscriptionStatusEnum, SubscriptionTierEnum } from '../../data-structures/enums';
import { getTenantSubscriptions } from '../../utilities/helpers/ApiHelper';

let calendarButtonElement: HTMLElement;

const mapStateToProps = (state: RootState) => {
    return {
        isPartner: state.user.isPartner,
        isGlobalAdmin: state.user.isGlobalAdmin,
        isTenant: state.user.isTenantAdmin,
        tenants: state.user.tenants
    };
};

const mapDispatchToProps = {
    updateUser
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface EditPanelProps extends PropsFromRedux {
    isOpen: boolean,
    closePanel: () => void,
    tenantUser: ITenantUser,
};

const EditPanel: React.FC<EditPanelProps> = ({
    isOpen,
    closePanel,
    tenantUser,
    isPartner,
    isGlobalAdmin,
    updateUser,
    tenants
}: EditPanelProps) => {
    const [newTenantUser, setNewTenantUser] = React.useState<ITenantUser>({ ...tenantUser })
    const [originalTenantUser, setOriginalTenantUser] = React.useState<ITenantUser>({ ...tenantUser });
    const [showCalendar, setShowCalendar] = React.useState<boolean>(false);
    const [isSaveDisabled, setIsSaveDisabled] = React.useState<boolean>(true);
    const [subscriptions, setSubscriptions] = React.useState<MollieSubscription[]>([]);
    const [subscriptionOptions, setSubscriptionOptions] = React.useState<IDropdownOption[]>([]);

    const dateString = new Date(newTenantUser?.licenseExpiryDate)?.toDateString();
    const languageStrings = React.useContext(LanguageContext);
    const onSelectDate = (date: Date): void => {
        setShowCalendar(!showCalendar);
        let newIsActivated = newTenantUser.isActivated;
        let newLicenseType = newTenantUser.licenseType;

        if (newTenantUser.licenseType === LicenseType.Eval && date < new Date()) {
            newIsActivated = false;
        }
        else if (newTenantUser.licenseType === LicenseType.Eval && date >= new Date()) {
            newIsActivated = true;
        }
        else if (newTenantUser.licenseType === LicenseType.Expired && date >= new Date()) {
            newIsActivated = true;
            newLicenseType = LicenseType.Eval;
        }

        setNewTenantUser({
            ...newTenantUser,
            isActivated: newIsActivated,
            licenseType: newLicenseType,
            licenseExpiryDate: date
        });
    };

    React.useEffect(() => {
        (async () => {
            if (tenantUser && tenants?.length > 0) {
                const tenant = tenants.find(x => x.tenantId === tenantUser.tenantId);
                const subscriptions = await getTenantSubscriptions(tenant.id, languageStrings);
                setSubscriptions(subscriptions);

                const tenantSubs: IDropdownOption[] = subscriptions.filter(x => x.status !== SubscriptionStatusEnum.Canceled).sort((a, b) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime()).map(sub => {
                    let licenseText;

                    if (sub.status === SubscriptionStatusEnum.Canceled) {
                        licenseText = `(${languageStrings.Canceled})`;
                    } else if (sub.method === "invoice" || sub.method === "invoice_dynamic") {
                        licenseText = `(${languageStrings.Invoice})`;
                    } else if (sub.tier === SubscriptionTierEnum.Trial) {
                        licenseText = `(${new Date(sub.expiryDate).toLocaleDateString()})`;
                    } else {
                        licenseText = `(${sub.availableLicenses} ${languageStrings.Available.toLocaleLowerCase()})`;
                    }

                    const option: IDropdownOption = {

                        key: sub.id,
                        text: `${sub.description} ${licenseText}`,
                        data: sub,
                        disabled: (sub.availableLicenses === 0 || sub.status === SubscriptionStatusEnum.Canceled)
                    }

                    return option;
                });

                tenantSubs.unshift({
                    key: "revokeUser",
                    text: "",
                })

                setSubscriptionOptions(tenantSubs);
            }
        })()
    }, [tenantUser, tenants])

    React.useEffect(() => {
        setOriginalTenantUser({ ...tenantUser })
        setNewTenantUser({ ...tenantUser })
    }, [isOpen, tenantUser])

    React.useEffect(() => {
        isDirty()
    }, [newTenantUser])

    const landingPageOptions = [
        { key: LandingPage.Default, text: languageStrings.Default },
        { key: LandingPage.SharePoint, text: "SharePoint" },
        { key: LandingPage.Teams, text: "Teams" },
        { key: LandingPage.OneDrive, text: "OneDrive" },
        { key: LandingPage.Exchange, text: "Exchange" }
    ]

    const isDirty = () => {
        let enableSave: boolean = false;
        const userKeys = Object.keys(newTenantUser);
        userKeys.every(key => {
            if (key === "key") return true //skip key check, bc we get a new key on rerender 
            else {
                if (typeof newTenantUser[key] != "object") { //if it is not an array or object, do this code
                    if (newTenantUser[key] != originalTenantUser[key]) {
                        enableSave = true;
                        return false;
                    } else {
                        return true
                    }
                } else {
                    if (key === "licenseExpiryDate" && new Date(newTenantUser[key]).toDateString() != new Date(originalTenantUser[key]).toDateString()) {
                        enableSave = true;
                        return false
                    } else {
                        return true
                    }

                }
            }
        })
        setIsSaveDisabled(!enableSave);
    }

    const isActivatedEnabled = () => {
        if (!isPartner && !isGlobalAdmin) {
            return false;
        }

        if (newTenantUser.licenseType === LicenseType.Eval && new Date(newTenantUser.licenseExpiryDate) < new Date()) {
            return false;
        }

        if (newTenantUser.licenseType === LicenseType.Expired) {
            return false;
        }

        return true;
    }

    return (
        <Panel
            headerText="Edit user"
            isOpen={isOpen}
            isLightDismiss
            onDismiss={closePanel}
            closeButtonAriaLabel="Close"
            type={PanelType.custom}
            customWidth={"500px"}
        >
            <Stack tokens={{ childrenGap: 15 }}>
                <Stack.Item style={{ marginTop: 15 }}>
                    <Label styles={labelHeadingStyle}>{languageStrings.Username}</Label>
                    <Label styles={labelInfoStyle} disabled>{tenantUser?.username}</Label>
                </Stack.Item>
                <Stack.Item>
                    <Label styles={labelHeadingStyle}>{languageStrings.DisplayName}</Label>
                    <Label styles={labelInfoStyle} disabled>{tenantUser?.displayName}</Label>
                </Stack.Item>
                <Stack.Item>
                    <Label styles={labelHeadingStyle}>{languageStrings.Subscription}</Label>
                    {subscriptionOptions?.length > 1 && <Dropdown
                        placeholder={languageStrings.SelectSubscription}
                        options={subscriptionOptions}
                        onChange={(_e, o) => setNewTenantUser({ ...newTenantUser, subscriptionId: Number(o.key) })}
                        selectedKey={newTenantUser?.subscriptionId ? newTenantUser?.subscriptionId?.valueOf() : subscriptionOptions[0].key}
                    />}
                    {subscriptionOptions?.length === 1 &&
                        <Stack>
                            <Label>{languageStrings.NoSubscriptionsFound}</Label>
                        </Stack>}
                </Stack.Item>
                <Stack.Item>
                    <Toggle
                        label={<Label styles={labelHeadingStyle}>{languageStrings.Active}</Label>}
                        checked={newTenantUser.isActivated}
                        onChange={(_event: React.MouseEvent<HTMLElement, MouseEvent>, checked?: boolean) => setNewTenantUser({ ...newTenantUser, isActivated: checked ?? false })}
                        disabled={!isActivatedEnabled()}
                        onText={languageStrings.Yes}
                        offText={languageStrings.No}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Toggle
                        label={<Label styles={labelHeadingStyle}>{languageStrings.TenantAdmin}</Label>}
                        checked={newTenantUser.isTenantAdmin}
                        onChange={(_event: React.MouseEvent<HTMLElement, MouseEvent>, checked?: boolean) => setNewTenantUser({ ...newTenantUser, isTenantAdmin: checked ?? false })}
                        disabled={!(isPartner || isGlobalAdmin)}
                        onText={languageStrings.Yes}
                        offText={languageStrings.No}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Toggle
                        label={<Label styles={labelHeadingStyle}>{languageStrings.Partner}</Label>}
                        checked={newTenantUser.isPartner}
                        onChange={(_event: React.MouseEvent<HTMLElement, MouseEvent>, checked?: boolean) => setNewTenantUser({ ...newTenantUser, isPartner: checked ?? false })}
                        disabled={true}
                        onText={languageStrings.Yes}
                        offText={languageStrings.No}
                    />
                </Stack.Item>
                <Stack.Item >
                    <Label styles={labelHeadingStyle}>{languageStrings.Locations}</Label>
                    <Toggle
                        label={languageStrings.Show + " SharePoint"}
                        checked={newTenantUser.enableSharePoint}
                        onChange={(_event: React.MouseEvent<HTMLElement, MouseEvent>, checked?: boolean) => setNewTenantUser({ ...newTenantUser, enableSharePoint: checked ?? false })}
                        onText={languageStrings.Yes}
                        offText={languageStrings.No}
                    />
                    <Toggle
                        label={languageStrings.Show + " Teams"}
                        checked={newTenantUser.enableTeams}
                        onChange={(_event: React.MouseEvent<HTMLElement, MouseEvent>, checked?: boolean) => setNewTenantUser({ ...newTenantUser, enableTeams: checked ?? false })}
                        onText={languageStrings.Yes}
                        offText={languageStrings.No}
                    />
                    <Toggle
                        label={languageStrings.Show + " OneDrive"}
                        checked={newTenantUser.enableOneDrive}
                        onChange={(_event: React.MouseEvent<HTMLElement, MouseEvent>, checked?: boolean) => setNewTenantUser({ ...newTenantUser, enableOneDrive: checked ?? false })}
                        onText={languageStrings.Yes}
                        offText={languageStrings.No}
                    />
                    <Toggle
                        label={languageStrings.EnableApprovals}
                        checked={newTenantUser.enableApprovals}
                        onChange={(_event: React.MouseEvent<HTMLElement, MouseEvent>, checked?: boolean) => setNewTenantUser({ ...newTenantUser, enableApprovals: checked ?? false })}
                        onText={languageStrings.Yes}
                        offText={languageStrings.No}
                    />
                    <Stack.Item>
                        <Label styles={labelHeadingStyle}>{languageStrings.LandingPage}</Label>
                        <Dropdown
                            style={{ marginTop: 7 }}
                            styles={dropdownStyles}
                            selectedKey={newTenantUser?.landingPage ? newTenantUser?.landingPage?.valueOf() : landingPageOptions[0].key}
                            onChange={(_e, o) => setNewTenantUser({ ...newTenantUser, landingPage: Number(o.key) })}
                            options={landingPageOptions}
                        />
                    </Stack.Item>
                </Stack.Item>

                <Stack.Item>
                    <Label styles={labelHeadingStyle}>
                        {languageStrings.OutlookSettings}
                    </Label>
                    <Toggle
                        disabled={true}
                        label={languageStrings.SaveOnSend}
                        checked={newTenantUser.saveOnSend ? true : false}
                        onChange={(_event: React.MouseEvent<HTMLElement, MouseEvent>, checked?: boolean) => setNewTenantUser({ ...newTenantUser, saveOnSend: checked ?? false })}
                        onText={languageStrings.Yes}
                        offText={languageStrings.No}
                    />
                    <Toggle
                        label={languageStrings.AutoSelectAttachments}
                        checked={newTenantUser.autoSelectAttachments ? true : false}
                        onChange={(_event: React.MouseEvent<HTMLElement, MouseEvent>, checked?: boolean) => setNewTenantUser({ ...newTenantUser, autoSelectAttachments: checked ?? false })}
                        onText={languageStrings.Yes}
                        offText={languageStrings.No}
                    />
                    <Toggle
                        label={languageStrings.AutoRemoveMailItemAttachments}
                        checked={newTenantUser.autoRemoveMailItemAttachments ? true : false}
                        onChange={(_event: React.MouseEvent<HTMLElement, MouseEvent>, checked?: boolean) => setNewTenantUser({ ...newTenantUser, autoRemoveMailItemAttachments: checked ?? false })}
                        onText={languageStrings.Yes}
                        offText={languageStrings.No}
                    />
                    <Toggle
                        label={languageStrings.ConvertEmailToPdf}
                        checked={newTenantUser.convertEmailToPDF ? true : false}
                        onChange={(_event: React.MouseEvent<HTMLElement, MouseEvent>, checked?: boolean) => setNewTenantUser({ ...newTenantUser, convertEmailToPDF: checked ?? false })}
                        onText={languageStrings.Yes}
                        offText={languageStrings.No}
                    />
                </Stack.Item>
                <Stack horizontal horizontalAlign="end">
                    <PrimaryButton
                        disabled={isSaveDisabled}
                        iconProps={{ iconName: 'save' }}
                        onClick={async () => {
                            updateUser(newTenantUser); //Thunk action 
                            closePanel(); //close panel
                        }}>
                        {languageStrings.Save}
                    </PrimaryButton>
                </Stack>

            </Stack>

        </Panel>
    )
}

export default connector(EditPanel);