
import { Stack, TextField, Text, mergeStyleSets, getTheme, Checkbox, IconButton, IColor, ColorPicker, IColorPickerStyles, DefaultButton } from '@fluentui/react';
import React from 'react';
import { IMetaTextColor } from '../data-structures/interfaces';
const theme = getTheme();
const selectedColor = theme.palette.neutralLighter;

const baseStyle = {
    // borderBottomColor: theme.palette.neutralLight,
    // borderBottomWidth: 0.5,
    // borderBottomStyle: 'solid',
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 15,
    paddingRight: 15,
    width: "100%",
}
const orderedListStyles = mergeStyleSets({
    selected: {
        ...baseStyle,
        backgroundColor: selectedColor,
    },
    standard: {
        ...baseStyle,
    }
})

const pillStyle: React.CSSProperties = {
    boxSizing: "border-box",
    padding: "4px 8px 5px 8px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "flex",
    borderRadius: 16,
    height: 24,
    alignItems: "center",
    whiteSpace: "nowrap",
    margin: 4,
    marginLeft: 14
};

const colorPickerStyles: Partial<IColorPickerStyles> = {
    panel: { padding: 12 },
    root: {
        maxWidth: 352,
        minWidth: 352,
    },
    colorRectangle: { height: 268 },
};


interface OrderedListItemProps {
    value: string | IMetaTextColor,
    index: number,
    isSelected: boolean,
    setIsSelected: (index: number, value?: boolean) => void,
    setFieldValue: (index: number, value: string, newHexColor?: string) => void,
    onEnter: () => void,
    isColorPicker: boolean,
    metaDataTextColorValue?: IMetaTextColor
};

const OrderedListItem: React.FC<OrderedListItemProps> = ({
    value,
    index,
    isSelected,
    setIsSelected,
    setFieldValue,
    onEnter,
    isColorPicker,
    metaDataTextColorValue
}: OrderedListItemProps) => {
    const [textValue, setTextValue] = React.useState<string>(
        typeof value === "string" ? value : value.value
    );
    const [allowEnter, setAllowEnter] = React.useState(false);
    const [showColorPicker, setShowColorPicker] = React.useState(false);
    const [color, setColor] = React.useState<string>(metaDataTextColorValue !== undefined ? metaDataTextColorValue?.hexColor.replace("#", "") : "00A4EF");

    React.useEffect(() => {
        if (allowEnter) {
            onEnter();
            setAllowEnter(false);
        }
    }, [allowEnter]);

    const showColorPickerHandler = () => {
        setShowColorPicker(!showColorPicker);
    }

    const saveColor = (colorHex) => {
        const timer = setTimeout(() => {
            setFieldValue(index, colorHex, textValue);
        }, 500);

        return () => clearTimeout(timer);
    }

    const updateColor = React.useCallback((ev: any, colorObj: IColor) => {
        setColor(colorObj.hex);
        saveColor(colorObj.hex);
    }, []);

    const getTextColor = (hexcolor?: string) => {
        if (!hexcolor) return "white";

        if (hexcolor.slice(0, 1) === '#') {
            hexcolor = hexcolor.slice(1);
        }

        const r = parseInt(hexcolor.substr(0, 2), 16);
        const g = parseInt(hexcolor.substr(2, 2), 16);
        const b = parseInt(hexcolor.substr(4, 2), 16);
        const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
        return (yiq >= 128) ? "black" : "white";
    };

    return (
        <Stack>
            <Stack
                horizontal
                verticalAlign="center"
                className={isSelected ? orderedListStyles.selected : orderedListStyles.standard}
                tokens={{ childrenGap: 10 }}
            >
                <Checkbox checked={isSelected} onChange={(e, isChecked) => {
                    setIsSelected(index, isChecked)
                }}></Checkbox>
                {!isColorPicker ? <>
                    <TextField
                        value={textValue}
                        styles={{
                            root: {
                                width: "100%"
                            }
                        }}
                        onChange={(_, nv) => {
                            setTextValue(nv);
                        }}
                        onBlur={() => {
                            setFieldValue(index, textValue.trim());
                        }}
                        onKeyDown={(event) => {
                            if (event.code === "Enter") {
                                setTextValue(event?.currentTarget?.value.trim() ?? textValue.trim());
                                setFieldValue(index, event?.currentTarget?.value.trim() ?? textValue.trim());
                                setAllowEnter(true);
                            }
                        }}
                    />
                </> :
                    <>
                        <div style={{ ...pillStyle, backgroundColor: `#${color}` }}>
                            <span style={{ color: getTextColor(color) }}>{textValue}</span>
                        </div>
                        <IconButton iconProps={{ iconName: showColorPicker ? 'ColorSolid' : 'Color' }} onClick={showColorPickerHandler} />
                    </>
                }
            </Stack>
            {showColorPicker &&
                <Stack tokens={{ childrenGap: 10 }} horizontalAlign="center">
                    <ColorPicker
                        color={color}
                        onChange={updateColor}
                        alphaType={"none"}
                        showPreview
                        styles={colorPickerStyles}
                        strings={{
                            hueAriaLabel: 'Hue',
                        }}
                    />
                    <DefaultButton text="Close" onClick={showColorPickerHandler} />̣
                </Stack>}
        </Stack>
    )
}

export default OrderedListItem;