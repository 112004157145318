import { FontSizes, FontWeights, ILabelStyles } from "@fluentui/react";

export const CardTypeLabelStyle: Partial<ILabelStyles> = {
    root: {
        fontWeight: FontWeights.semibold,
        fontSize: FontSizes.xLarge,
    }
}

export const NoCardsLabel: Partial<ILabelStyles> = {
    root: {
        fontWeight: FontWeights.regular,
        fontSize: FontSizes.mediumPlus,
    }
}