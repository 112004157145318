import { FontSizes, FontWeights, IColumn, ILabelStyles, Label, ShimmeredDetailsList, Stack } from '@fluentui/react'
import React from 'react'
import { LanguageContext } from '../utilities/LocalizationModule';

export const subheadingStyle: Partial<ILabelStyles> = {
    root: {
        fontWeight: FontWeights.regular,
        fontSize: FontSizes.xLarge,
    }
}

type Props = {
    items: any[];
    isLoading: boolean;
    paymentMethods: any[];
}

const SubscriptionsPaymentsList = ({ items, isLoading, paymentMethods }: Props) => {
    const languageStrings = React.useContext(LanguageContext);
    
    const [cols, setCols] = React.useState<IColumn[]>([])

    React.useEffect(() => {
        setCols([
            {
                key: 'method',
                name: languageStrings.Method,
                fieldName: 'method',
                data: "string",
                minWidth: 50,
                maxWidth: 70,
                isResizable: true,
                onRender(item, index, column) {
                    const method = paymentMethods.find(x => x.id === item.method);
                    return method ? <img src={method.image.size1x}/> : item.method
                },
            },
            {
                key: 'amount',
                name: languageStrings.Amount,
                fieldName: 'amountString',
                data: "string",
                minWidth: 50,
                maxWidth: 200,
                isResizable: true,
            },
            {
                key: 'status',
                name: languageStrings.Status,
                fieldName: 'status',
                data: "string",
                minWidth: 50,
                maxWidth: 200,
                isResizable: true,
            },
            {
                key: 'description',
                name: languageStrings.Description,
                fieldName: 'description',
                data: "string",
                minWidth: 50,
                maxWidth: 450,
                isResizable: true,
            },
            {
                key: 'createdDate',
                name: languageStrings.Date,
                fieldName: 'createdDate',
                data: "string",
                minWidth: 50,
                isResizable: true,
                onRender(item, index, column) {
                    return `${new Date(item.createdDate)?.toLocaleDateString()} ${new Date(item.createdDate)?.toLocaleTimeString()}`;
                },
            },
        ])
    }, [paymentMethods])
    return (
        <Stack>
            <Label styles={subheadingStyle}>{languageStrings.Payments}</Label>
            <ShimmeredDetailsList
                setKey="items"
                items={items || []}
                columns={cols}
                enableShimmer={isLoading}
            />
        </Stack>
    )
}

export default SubscriptionsPaymentsList