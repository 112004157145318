import { ActionButton, Checkbox, FontIcon, IButtonStyles, Icon, IconButton, IContextualMenuItem, IIconProps, ILabelStyles, ILinkStyles, ISearchBoxStyles, IStackItemStyles, IStackTokens, Label, Link, mergeStyles, SearchBox, Slider, Stack, StackItem, TooltipHost } from "@fluentui/react";
import { getFileTypeIconProps } from "@fluentui/react-file-type-icons";
import dayjs from "dayjs";
import React from "react";
import { LanguageContext } from "../utilities/LocalizationModule";
import { FilterItem, FilterSection } from "../data-structures/interfaces";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux";
import { setFilterSectionItem, setFilterSectionItems, setFilterSectionItemsFromRange, setFilterSectionToggleShowMOre, setFilterSectionToggleSlider, toggleReload } from "../redux/modules/filters";

export interface DateRangeStep {
  index: number;
  label: string;
  daysOffset?: number;
}

const checkboxLabelStyles: IStackItemStyles = {
  root: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap"
  }
}

const labelStyles: ILabelStyles = {
  root: { marginBottom: 10 }
}

const linkStyles: ILinkStyles = {
  root: { marginTop: 10 }
}

const filterButtonStackItemStyles: IStackItemStyles = {
  root: {
    marginTop: -10
  }
}

const showMoreButtonStyles: IButtonStyles = {
  rootHovered: {
    backgroundColor: 'transparent',
    selectors: {
      '.ms-Button-icon': {
        color: 'inherit' //Removing button hover bg color
      }
    }
  },
  icon: {
    marginTop: -5
  },
  menuIcon: {
    display: "none"
  }
}

const searchIcon: IIconProps = { iconName: "Search" };
const searchBoxStyles: Partial<ISearchBoxStyles> = {
  root: { height: "100%", marginBottom: 10, paddingBottom: 5 }
};

const horizontalGapStackTokens: IStackTokens = {
  childrenGap: 10
};

export interface UserFilterPanelSectionProps {
  filterSection: FilterSection;
}

export interface FilterSectionItemsExpandSearchParams {
  field: string;
  value: string;
}

export const UserFilterPanelSection = ({ filterSection }: UserFilterPanelSectionProps) => {
  const languageStrings = React.useContext(LanguageContext);

  const dispatch = useDispatch();

  const filterSections = useSelector((state: RootState) => state.filter?.filterSections);

  const [sectionElement, setSectionElement] = React.useState<JSX.Element>();
  const [minItems, setMinItems] = React.useState<number>(5);
  const [loadSection, setLoadSection] = React.useState(false);
  const [showFilterIcon, setShowFilterIcon] = React.useState(false);
  const [showFilterSearchBox, setFilterSearchBox] = React.useState(false);
  const [sectionFilterValue, setSectionFilterValue] = React.useState("");
  const [uniqueSearches, setUniqueSearches] = React.useState<string[]>([]);

  const [showSelected, setShowSelected] = React.useState(false);
  const [selectedMenuOptions, setSelectedMenuOptions] = React.useState<IContextualMenuItem[]>([]);

  const [dateRangeSteps, setDateRangeSteps] = React.useState<DateRangeStep[]>([
    { index: 0, label: languageStrings.FilterDateStepSince },
    { index: 1, label: languageStrings.FilterDateStepLast3Months, daysOffset: -92 },
    { index: 2, label: languageStrings.FilterDateStepLast30Days, daysOffset: -30 },
    { index: 3, label: languageStrings.FilterDateStepLast7Days, daysOffset: -7 },
    { index: 4, label: languageStrings.FilterDateStepSinceYesterday, daysOffset: -1 },
    { index: 5, label: languageStrings.FilterDateStepToday, daysOffset: 0 },
    { index: 6, label: languageStrings.FilterDateStepTomorrow, daysOffset: 1 },
    { index: 7, label: languageStrings.FilterDateStepIn7Days, daysOffset: 7 },
    { index: 8, label: languageStrings.FilterDateStepIn30Days, daysOffset: 30 },
    { index: 9, label: languageStrings.FilterDateStepIn3Months, daysOffset: 92 },
    { index: 10, label: languageStrings.FilterDateStepInAYearOrMore, daysOffset: 365 }
  ]);

  const [selectedDateRangeStep, setSelectedDateRangeStep] = React.useState<DateRangeStep>(dateRangeSteps[0]);
  const [sliderValue, setSliderValue] = React.useState<number>(selectedDateRangeStep.index);

  const onChange = (value: number) => {
    if (value !== selectedDateRangeStep.index) {
      setSelectedDateRangeStep(dateRangeSteps[value]);
      setSliderValue(value);
    }
  };

  const onChanged = (event: any, value: number, range?: [number, number]) => {
    if (selectedDateRangeStep.index !== value) {
      dispatch(setFilterSectionItemsFromRange(filterSection, dateRangeSteps[value].daysOffset));
      dispatch(toggleReload());
    }
  };

  React.useEffect(() => {
    const currentFilterSection = filterSections.find(x => x.filterField.realFieldName === filterSection.filterField.realFieldName);

    if (showSelected && currentFilterSection.items.every(x => !x.selected)) {
      setShowSelected(false);
      return;
    }

    if (currentFilterSection) {
      if (currentFilterSection.filterField.type === 'DateTime') {
        const selectedDateRange = (!currentFilterSection.daysOffset && currentFilterSection.daysOffset !== 0) ? dateRangeSteps[0] : dateRangeSteps.find(x => x.daysOffset === currentFilterSection.daysOffset)
        setSelectedDateRangeStep(selectedDateRange);
        setSliderValue(selectedDateRange.index);
      }

      if (currentFilterSection.filterField.realFieldName === 'dateRegistered' || currentFilterSection.filterField.realFieldName === 'lastActivityDate') {
        setDateRangeSteps(dateRangeSteps.slice(0, 6));
      }
      const itemHeight: number = 30;
      let containerHeight: number;

      if (currentFilterSection.items.length > minItems && currentFilterSection.showMoreExpanded === false) {
        containerHeight = itemHeight * minItems;
      }
      else {
        containerHeight = itemHeight * currentFilterSection.items.length;
      }

      if (!currentFilterSection.hasSlider && currentFilterSection.items.length > minItems) {
        setShowFilterIcon(true);
      }

      const sectionStyle = mergeStyles({
        maxHeight: containerHeight - 10,
        overflow: "hidden",
        transition: "max-height 0.3s ease-in-out",
      });

      if (currentFilterSection?.items?.length > 0) {
        setSectionElement(
          <Stack tokens={{ childrenGap: 10 }} className={sectionStyle}>
            {currentFilterSection.items
              .filter(x => !showSelected || x.selected)
              .filter(x => x.title?.toLowerCase().includes(sectionFilterValue?.toLowerCase()))
              .slice(0, currentFilterSection.showMoreExpanded ? currentFilterSection.items.length : currentFilterSection.items.length > minItems ? minItems : currentFilterSection.items.length)
              .map((item, index) => {
                return (
                  <Checkbox key={`${item.title}-${index}`} styles={{ root: { lineHeight: 40 } }} onRenderLabel={() => _onRenderLabel(item)} checked={item.selected} onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean) => _onCheckboxChange(ev, isChecked, item)} />
                )
              })}
          </Stack>
        );
      } else {
        setSectionElement(undefined);
      }

      setLoadSection(true);
    }
  }, [filterSection, sectionFilterValue, showSelected])

  React.useEffect(() => {
    if (uniqueSearches?.length > 0) {
      (async () => {
        const lastSearchValue = uniqueSearches[uniqueSearches?.length - 1];

        // const gridProps: GridProps = {
        //   sort: []
        // }

        if (lastSearchValue) {
          //   try {
          //     const listStream = await getListAsStream({
          //       siteUrl: isInOneDrive ? oneDriveRootDetails?.sharePointSiteUrl : currentDirectory?.webUrl,
          //       sharePointListId: currentDirectory?.sharePointListId,
          //       gridProps: { ...gridProps },
          //       accessToken: isInOneDrive ? await authModule.getOneDriveAccessToken() : await authModule.getSharePointAccessToken(),
          //       serverRelativePath: serverRelPath,
          //       filterSections: [],
          //       filterSectionItemsExpandParams: {
          //         field: filterSection.filterField.RealFieldName,
          //         value: lastSearchValue
          //       }
          //     }).unwrap();

          //     const items: string[] = listStream.ListData.Row
          //       .map(item => item[filterSection.filterField.RealFieldName])
          //       .filter((v, i, a) => a.indexOf(v) === i);  //Remove duplicates

          //     const oldSectionItems = [...filterSection.items];
          //     const newSectionItems: FilterItem[] = [...filterSection.items];

          //     items.forEach(item => {
          //       if (item) {
          //         const filterItem = newSectionItems.find(x => x.value?.toUpperCase() === item.toUpperCase());

          //         if (!filterItem) {
          //           let newFilterItem: FilterItem = {
          //             title: item,
          //             value: item,
          //             selected: false
          //           }

          //           newSectionItems.push(newFilterItem);
          //         }
          //       }
          //     });

          //     if (newSectionItems?.length > oldSectionItems?.length) {
          //       const sectionItemsSorted = newSectionItems.sort((a, b) => (a.title?.toUpperCase() > b.title?.toUpperCase()) ? 1 : -1).map((item, index) => {
          //         return {
          //           ...item,
          //           index
          //         }
          //       });
          //       dispatch(setContentManagerFilterSectionItems(filterSection, sectionItemsSorted));
          //     }
          //   } catch (error) {
          //     logger(error, "UserFilterPanelSection.tsx (Search filters)");
          //   }
        }
      })();
    }
  }, [uniqueSearches]);

  React.useEffect(() => {
    const menu: IContextualMenuItem[] = [
      {
        key: 'clear',
        text: languageStrings.ClearSelection,
        iconProps: { iconName: "Clear", },
        onClick: () => {
          const deselectedItems = filterSection.items.map(item => ({
            ...item,
            selected: false
          }));

          dispatch(setFilterSectionItems(filterSection, deselectedItems));
        }
      },
    ];

    if (showSelected) {
      menu.unshift(
        {
          key: 'all',
          text: languageStrings.ShowAll,
          iconProps: { iconName: "AllApps" },
          onClick: () => {
            setShowSelected(false);
          }
        },
      )
    } else {
      menu.unshift(
        {
          key: 'selected',
          text: languageStrings.ShowSelected,
          iconProps: { iconName: "WaitlistConfirm" },
          onClick: () => {
            setShowSelected(true);
          }
        },
      )
    }

    setSelectedMenuOptions(menu);
  }, [showSelected]);

  const _onRenderLabel = (item: FilterItem) => {
    return (
      <div style={{ paddingLeft: '5px', lineHeight: '20px' }} >
        <Stack horizontal tokens={{ childrenGap: 5 }} verticalAlign="center">
          {filterSection.filterField.realFieldName === 'DocIcon' && <Icon {...getFileTypeIconProps({ extension: item.fileType, size: 16, imageFileType: "png" })} />}
          <Stack.Item styles={checkboxLabelStyles}>{item.title}</Stack.Item>
        </Stack>
      </div>
    )
  }

  function _onCheckboxChange(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean, item?: FilterItem) {
    dispatch(setFilterSectionItem(filterSection, item, isChecked));
    dispatch(toggleReload());
  }

  const showMoreClicked = () => {
    dispatch(setFilterSectionToggleShowMOre(filterSection))
  }

  const showDatesClicked = () => {
    const reloadGrid: boolean = selectedDateRangeStep.index !== dateRangeSteps[0].index;
    setSelectedDateRangeStep(dateRangeSteps[0]);
    setSliderValue(0);
    dispatch(setFilterSectionItemsFromRange(filterSection, dateRangeSteps[0].daysOffset));
    dispatch(setFilterSectionToggleSlider(filterSection));
    if (reloadGrid) {
      dispatch(toggleReload());
    }
  }

  const showSliderClicked = () => {
    let reloadGrid = filterSection.items.some(x => x.selected);
    dispatch(setFilterSectionToggleSlider(filterSection));
    if (reloadGrid) {
      dispatch(toggleReload());
    }
  }

  const getSectionSubtitle = () => {
    if (filterSection.showSlider) {
      if (selectedDateRangeStep.index === 0) {
        let hasEmptyItem = (filterSection.items.some(x => x.title === `(Empty)`) || filterSection.items.some(x => x.title === `(Leeg)`)) ? true : false;
        let firstItem = hasEmptyItem ? filterSection?.items[1] : filterSection?.items[0]
        let dateString = dayjs(firstItem?.value).isValid() ? dayjs(firstItem?.value).format("ll") : firstItem?.title
        return `(${selectedDateRangeStep.label} ${dateString})`;
      } else {
        return `(${selectedDateRangeStep.label})`;
      }
    }

    if (filterSection.items?.some(x => x.selected)) {
      return `(${filterSection.items?.filter(x => x.selected)?.length})`;
    }

    return '';
  }

  const onFilterClick = () => {
    setFilterSearchBox(prev => !prev);
    setSectionFilterValue("");
  }

  const onChangeFilter = React.useCallback(async (event?: React.ChangeEvent<HTMLInputElement>, newValue?: string) => {
    setSectionFilterValue(newValue);
    const searchValue = newValue.toLowerCase();
    const minItemsBeforeAdditionalSearch = 6;
    if (filterSection.items?.length > minItemsBeforeAdditionalSearch && newValue?.length > 0 && !uniqueSearches.includes(searchValue)) {
      const oldValues = [...uniqueSearches];
      oldValues.push(newValue);
      setUniqueSearches(oldValues);
    }
  }, [uniqueSearches]);

  const filteredItems = filterSection?.items?.filter(x =>
    (!showSelected || x.selected) &&
    x.title?.toLowerCase().includes(sectionFilterValue?.toLowerCase())
  );

  return (
    <>
      {loadSection && <>
        {filterSection?.items?.length > 0 &&
          <Stack horizontal tokens={horizontalGapStackTokens} verticalAlign="center" horizontalAlign="space-between">
            <StackItem>
              <Stack horizontal verticalAlign="center">
                <Label styles={labelStyles}>{filterSection.filterField.displayName} {getSectionSubtitle()}</Label>
                {filterSection?.items.length > minItems && filterSection?.items.some(x => x.selected) && <IconButton
                  styles={showMoreButtonStyles}
                  iconProps={{ iconName: 'More' }}
                  menuProps={{
                    items: selectedMenuOptions,
                    directionalHintFixed: true
                  }}
                />}
              </Stack>

            </StackItem>
            {showFilterIcon &&
              <TooltipHost content={!showFilterSearchBox ? languageStrings.SearchSectionFilter.replace("{section}", filterSection.filterField.displayName) : languageStrings.ClearSearch}>
                <StackItem styles={filterButtonStackItemStyles}>
                  <ActionButton iconProps={{ iconName: !showFilterSearchBox ? "Filter" : "ClearFilter" }} allowDisabledFocus onClick={onFilterClick} />
                </StackItem>
              </TooltipHost>}
          </Stack>
        }
        {showFilterSearchBox && <SearchBox
          styles={searchBoxStyles}
          placeholder={languageStrings.SearchSectionFilter.replace("{section}", filterSection.filterField.displayName)}
          iconProps={searchIcon}
          underlined={true}
          onChange={onChangeFilter}
          showIcon={true}
        />}
        {!filterSection.showSlider && sectionElement &&
          <>
            {sectionElement}
            {filteredItems && filteredItems.length > minItems && <div className="xb-show-more-action" style={{ marginTop: 10, marginLeft: 4 }} onClick={() => { showMoreClicked() }}>
              <FontIcon iconName={!filterSection.showMoreExpanded ? "ChevronDown" : "ChevronUp"} />
              <span>{!filterSection.showMoreExpanded ? languageStrings.ShowMore : languageStrings.ShowLess}</span>
            </div>}
          </>}
        {filterSection.hasSlider && filterSection.showSlider && <Slider
          min={0}
          max={dateRangeSteps?.length - 1}
          value={sliderValue}
          showValue={false}
          snapToStep={false}
          onChange={onChange}
          onChanged={onChanged}
        />}
        {filterSection.hasSlider && filterSection.showSlider && <Link styles={linkStyles} onClick={() => showDatesClicked()}>{languageStrings.SelectSpecificDates}</Link>}
        {filterSection.hasSlider && !filterSection.showSlider && <Link styles={linkStyles} onClick={() => showSliderClicked()}>{languageStrings.ShowDateRangeSlider}</Link>}
      </>}
    </>
  )
};
