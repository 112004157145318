import { CommandBarButton, DefaultButton, Dialog, DialogFooter, DialogType, IButtonStyles, IconButton, IModalProps, Label, mergeStyles, PrimaryButton, Stack, TextField } from "@fluentui/react";
import React from 'react';
import { labelHeadingStyle } from "../../../styles/PanelStyle";
import { LanguageContext } from "../../../utilities/LocalizationModule";
import generateApiKey from 'generate-api-key';
import { ITenant, TenantApiKeyUpdateRequest } from "../../../data-structures/interfaces";
import { updateTenantApiKey } from "../../../utilities/helpers/ApiHelper";

const dialogStyles = { main: { maxWidth: 450 } };

const revealButtonStyles: IButtonStyles = {
	root: {
		backgroundColor: 'transparent',
		color: '#323130',
	},
	rootHovered: {
		backgroundColor: 'transparent',
		color: '#323130',
	},
	rootPressed: {
		backgroundColor: 'transparent',
	},
}

interface ISuccessErrorMessageProps {
	tenant: ITenant
	updateTenant: (newTenant: ITenant, oldTenant?: ITenant) => void;
}

const ApiKeyItem = (props: ISuccessErrorMessageProps) => {
	const languageStrings = React.useContext(LanguageContext);
	const [showDialog, setShowDialog] = React.useState(false);
	const [isGeneratingApiKey, setIsGeneratingApiKey] = React.useState(false);

	const [isPasswordVisible, setIsPasswordVisible] = React.useState(false);

	const onRevealPasswordClick = () => {
		setIsPasswordVisible(!isPasswordVisible);
	};

	React.useEffect(() => {
		if (props.tenant.id > 0) {
			(async () => {
				if (!props.tenant.apiKey) {
					await updateApiKey();
				}
			})()
		}
	}, []);

	const dialogContentProps = {
		type: DialogType.normal,
		title: `${languageStrings.Regenerate} ${languageStrings.ApiKey}`,
		closeButtonAriaLabel: 'Close',
		subText: languageStrings.RegenerateApiKeyWarning,
	};
	const modalProps: IModalProps = {
		isBlocking: false,
		styles: dialogStyles,
		dragOptions: undefined,
		overlay: {
			className: mergeStyles({
				transition: 'all ease 1s',
			}),
		},
	};

	const onGenerateClicked = () => {
		setShowDialog(true);
	};

	const updateApiKey = async () => {
		const newApiKey = generateApiKey() as string;

		const newTenant = {
			...props.tenant,
			apiKey: newApiKey
		}

		const request: TenantApiKeyUpdateRequest = {
			id: props.tenant.id,
			apiKey: newApiKey
		}

		await updateTenantApiKey(request);

		props.updateTenant(newTenant, props.tenant)
	}

	const onConfirmGenerate = async () => {
		setIsGeneratingApiKey(true);

		await updateApiKey();

		setShowDialog(false)
		setIsGeneratingApiKey(false);
	};


	const DialogMessage: React.FC = () => {
		return (
			<div>
				<Dialog hidden={!showDialog} onDismiss={() => setShowDialog(false)} dialogContentProps={dialogContentProps} modalProps={modalProps}>
					<DialogFooter>
						<PrimaryButton
							disabled={isGeneratingApiKey}
							onClick={() => {
								onConfirmGenerate();
							}}
							text={languageStrings.Confirm}
						/>
						<DefaultButton
							disabled={isGeneratingApiKey}
							onClick={() => {
								setShowDialog(false);
							}}
							text={languageStrings.Cancel}
						/>
					</DialogFooter>
				</Dialog>
			</div>
		);
	};

	const renderSuffix = () => {
		return (
			<IconButton
				iconProps={{ iconName: isPasswordVisible ? 'Hide' : 'RedEye' }}
				ariaLabel={isPasswordVisible ? 'Hide password' : 'Show password'}
				onClick={onRevealPasswordClick}
				styles={revealButtonStyles}
			/>
		);
	};

	return (
		<>
			{props.tenant.id > 0 && <Stack horizontal horizontalAlign="space-between">
				<Label styles={labelHeadingStyle}>{languageStrings.ApiKey}</Label>
				<CommandBarButton
					iconProps={{
						iconName: 'refresh',
						styles: {
							root: {
								color: 'rgb(50, 49, 48)',
							},
						},
					}}
					text={languageStrings.Regenerate}
					styles={{
						root: {
							backgroundColor: 'transparent',
						},
					}}
					onClick={() => {
						onGenerateClicked();
					}}
				/>
			</Stack>}
			{props.tenant.id > 0 && 
				<TextField
					readOnly
					type={isPasswordVisible ? 'text' : 'password'}
					value={props.tenant.apiKey}
					onRenderSuffix={renderSuffix}
					styles={{
						suffix: {
							backgroundColor: "white"
						}
					}}
				/>}

			<DialogMessage />
		</>
	);
};

export default ApiKeyItem;
