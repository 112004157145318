import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc' // import plugin
import { refreshJwt } from "./ApiHelper";

dayjs.extend(utc);

export interface IJwtAuthTokenResult {
    token?: string;
    expirationDate?: Date;
}

export class AuthHelper {
    private static signOutCallback: () => void;

    private static docubirdToken: IJwtAuthTokenResult;

    static setSignOutCallback(callback: () => void) {
        AuthHelper.signOutCallback = callback;
    }

    static signOut() {
        if (AuthHelper.signOutCallback) {
            AuthHelper.signOutCallback();
        }
    }
    static calculateMinutesToExpiration(tokenExpirationDate: Date): number {
        if (tokenExpirationDate) {
            try {
                const utcNow = dayjs.utc();
                const tokenExpirationUtc = dayjs.utc(tokenExpirationDate);
                const minutesToExpiration = tokenExpirationUtc.diff(utcNow, "minutes");
                return minutesToExpiration;
            } catch (error) {
                
            }
        }
        return 0;
    }

    static tokenRefreshRequired(tokenExpirationDate: Date): boolean {
        const minutesToExpiration = this.calculateMinutesToExpiration(tokenExpirationDate);
        if (minutesToExpiration > 2) {
            return false;
        }
        return true;
    }

    static async getDocubirdToken() {
        if (this.docubirdToken && this.tokenRefreshRequired(this.docubirdToken.expirationDate)) {
            const token: IJwtAuthTokenResult = await refreshJwt(this.docubirdToken.token);
            this.docubirdToken = token;
        }

        return this.docubirdToken?.token;
    }

    static setDocubirdToken(token: IJwtAuthTokenResult) {
        return this.docubirdToken = token;
    }


}