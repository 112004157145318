
import React from 'react';
import { DefaultButton, Dialog, DialogFooter, DialogType, IModalProps, mergeStyles, PrimaryButton, Spinner, SpinnerSize } from "@fluentui/react"
import { RootState } from '../redux';
import { connect, ConnectedProps } from 'react-redux';
import { MessageType } from "../data-structures/enums"
import { LanguageContext } from '../utilities/LocalizationModule';


const mapStateToProps = (state: RootState) => {
    return {
        isLoading: state.user.isUserLoading
    };
};

const mapDispatchToProps = {

}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface DialogMessageProps extends PropsFromRedux {
    isHidden: boolean,
    hideDialog: () => void,
    title: string,
    message: string
    confirmAction?: () => Promise<void> | void,
    cancelAction?: () => Promise<void> | void,
    type: MessageType
};

const dialogStyles = { main: { maxWidth: 450 } };

const DialogMessage: React.FC<DialogMessageProps> = ({
    isHidden,
    hideDialog,
    title,
    message,
    confirmAction,
    cancelAction,
    isLoading,
    type
}: DialogMessageProps) => {
    const languageStrings = React.useContext(LanguageContext);
    
    const [init, setInit] = React.useState(false);
    const dialogContentProps = {
        type: DialogType.normal,
        title: title,
        closeButtonAriaLabel: 'Close',
        subText: message,
    };
    const modalProps: IModalProps = React.useMemo(
        () => ({
            isBlocking: false,
            styles: dialogStyles,
            dragOptions: undefined,
            overlay: {
                className: mergeStyles({
                    "transition": "all ease 1s"
                }),
            }
        }),
        [],
    );
    React.useEffect(() => {
        if (!isLoading && init) hideDialog()
        setInit(true);
    }, [isLoading])
    return (
        <div>
            <Dialog
                hidden={isHidden}
                onDismiss={() => hideDialog()}
                dialogContentProps={dialogContentProps}
                modalProps={modalProps}
            >
                {type === MessageType.Confirmation &&
                    <DialogFooter>
                        <PrimaryButton onClick={async () => {
                            await confirmAction();
                        }}>
                            Confirm
                            {
                                isLoading && <Spinner size={SpinnerSize.small} style={{ marginLeft: 15 }} />
                            }
                        </PrimaryButton>
                        <DefaultButton onClick={() => hideDialog()} text="Cancel" />
                    </DialogFooter>
                }
                {type === MessageType.Success &&
                    <DialogFooter>
                        <PrimaryButton onClick={async () => {
                            if (confirmAction) confirmAction()
                            hideDialog()
                        }}>
                            Ok
                        </PrimaryButton>
                    </DialogFooter>
                }
                {type === MessageType.Fail &&
                    <DialogFooter>
                        <PrimaryButton onClick={() => {
                            if (confirmAction) confirmAction()
                            hideDialog()
                        }}>
                            Ok
                        </PrimaryButton>
                    </DialogFooter>
                }
                {type === MessageType.YesNo &&
                    <DialogFooter>
                        <PrimaryButton onClick={() => {
                            if (confirmAction) confirmAction()
                            hideDialog()
                        }}>
                            {languageStrings.Yes}
                        </PrimaryButton>
                        <DefaultButton onClick={() => {
                            if (cancelAction) cancelAction()
                            cancelAction()
                        }} text={languageStrings.No} />
                    </DialogFooter>
                }
            </Dialog>
        </div>
    )
}

export default connector(DialogMessage);