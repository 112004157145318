//shuffled via  https://json-shuffle.online/
// export const shuffledColorList: ICardColor[] = [{ "name": "blueDark", "color": "#002050" }, { "name": "orange", "color": "#d83b01" }, { "name": "orangeLighter", "color": "#ff8c00" }, { "name": "blueMid", "color": "#00188f" }, { "name": "tealLight", "color": "#00B294" }, { "name": "red", "color": "#d13438" }, { "name": "yellowDark", "color": "#d29200" }, { "name": "greenDark", "color": "#004b1c" }, { "name": "redDark", "color": "#a4262c" }, { "name": "purpleLight", "color": "#b4a0ff" }, { "name": "orangeLight", "color": "#ea4300" }, { "name": "greenLight", "color": "#bad80a" }, { "name": "yellow", "color": "#ffb900" }, { "name": "purple", "color": "#5c2d91" }, { "name": "purpleDark", "color": "#32145a" }, { "name": "blue", "color": "#0078d4" }, { "name": "magentaLight", "color": "#a4262c" }, { "name": "magentaDark", "color": "#5c005c" }, { "name": "yellowLight", "color": "#fff100" }, { "name": "green", "color": "#107c10" }, { "name": "blueLight", "color": "#00bcf2" }, { "name": "teal", "color": "#008272" }, { "name": "magenta", "color": "#b4009e" }, { "name": "tealDark", "color": "#004b50" }];
const shuffledColorList: ICardColor[] = [
    { "name": "green", "color": "#107c10" },
    { "name": "purpleLight", "color": "#b4a0ff" },
    { "name": "teal", "color": "#008272" },
    { "name": "purple", "color": "#5c2d91" },
    { "name": "blueLight", "color": "#00bcf2" },
    { "name": "magentaLight", "color": "#a4262c" },
    { "name": "tealLight", "color": "#00B294" },
    { "name": "blue", "color": "#0078d4" },
    { "name": "magenta", "color": "#b4009e" }
];

export interface ICardColor {
    name: string;
    color: string;
}

export const getShuffledColors = (colorCount: number): ICardColor[] => {
    let shuffledColors: ICardColor[] = [...shuffledColorList];
    while (shuffledColors.length < colorCount) {
        shuffledColors = shuffledColors.concat(shuffledColorList);
    }
    return shuffledColors;
}